<template>
  <div class="user-notifications dark-theme"> 
    <div v-if="notifications.length === 0" class="no-notification">
      <p>Bir bildirim bulunmuyor</p>
    </div>

    <div
      v-for="(notification) in notifications"
      :key="notification.id"
      class="notification-card"
      :class="{ 'is-read': notification.is_read }"
    >
      <div class="card-header">
        <h3 class="notification-topic">{{ notification.platform }}</h3>
        <span class="notification-time">{{ formatDateTime(notification.created_at) }}</span>
      </div>
      <div class="card-body">
        <p class="notification-details">{{ notification.details }}</p>
      </div>
      <div class="card-actions">
        <span class="read-status" v-if="notification.is_read">
          Okundu
        </span>
        <button
          class="mark-read-btn"
          v-else
          @click="toggleReadStatus(notification)"
        >
          Okundu işaretle
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserNotifications',
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    async fetchNotifications() {
      try {
        const response = await axios.get('/promo/user/notifications');
        this.notifications = response.data.data || [];
      } catch (error) {
        console.error('Bildirimler alınırken hata oluştu:', error);
      }
    },
    async toggleReadStatus(notification) {
      try {
        const updatedStatus = !notification.is_read;
        await axios.put(`/promo/user/notifications/${notification.id}`, {
          is_read: updatedStatus,
        });
        notification.is_read = updatedStatus;
      } catch (error) {
        console.error('Okundu durumu güncellenirken hata oluştu:', error);
      }
    },
    formatDateTime(dateString) {
      // Tarihi formatlamak için örnek fonksiyon (ihtiyaca göre düzenleyebilirsiniz)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleString('tr-TR', options);
    },
  },
  created() {
    this.fetchNotifications();
  },
};
</script>

<style scoped>
.dark-theme {
  min-height: 75vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

/* Hiç bildirim yoksa */
.no-notification {
  padding: 1.5em;
  border-radius: 8px;
  font-style: italic;
  margin: 2rem auto;
  max-width: 1600px;
}
 
.notification-card {
  background: #050505;
  border-radius: 10px;
  margin-bottom: 1em;
  padding: 1rem;
  max-width: 1600px;
  width: 100%;
  border: 1px solid rgba(15, 129, 11, 0.4);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.notification-card:hover {
  transform: translateY(-3px);
}

/* Okunmuş kartlara özel görünüm */
.notification-card.is-read {
  opacity: 0.5;  
  border: none;
}
 
.notification-card.is-read:hover {
  opacity: 1;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.notification-topic {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

.notification-time {
  font-size: 0.85em;
}
 
.card-body {
  margin-bottom: 0.8em;
}

.notification-details {
  margin: 0;
  font-size: 0.9em;
}
 
.card-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}

.read-status {
  font-size: 0.85em; 
  opacity: 0.8;
}

.mark-read-btn {
  background-color: #0a0a0a; 
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding: 0.4em 0.8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.85em;
}

.mark-read-btn:hover {
  background-color: #000;
}
</style>
