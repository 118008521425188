<template>
  <div class="top-menu">
    <div
      ref="filterButton"
      class="topmenu-button filter-button"
      @click="toggleTooltip"
      title="Görünümü Özelleştir"
      role="button"
      tabindex="0"
    >
      <img src="/images/ui-icon.svg" alt="UI Icon" />
    </div>

    <div
      v-if="!isLoggedIn"
      class="topmenu-button login-button"
      @click="goToLogin"
      title="Giriş Yap"
      role="button"
      tabindex="0"
    >
      <img src="/images/login-icon.svg" alt="Login Icon" />
    </div>

    <div
      v-if="isLoggedIn"
      class="topmenu-button profile-button"
      @click="goToProfile"
      title="Profil"
      role="button"
      tabindex="0"
    >
      <img src="/images/profile-icon.svg" alt="Profile Icon" />
    </div>

    <div
      v-if="isLoggedIn"
      class="topmenu-button logout-button"
      @click="logout"
      title="Çıkış Yap"
      role="button"
      tabindex="0"
    >
      <img src="/images/logout-icon.svg" class="max-icon" alt="Logout Icon" />
    </div>

    <div v-if="tooltipVisible" class="filter-tooltip" ref="tooltip">
      <div class="slider-container">
        <label for="brightness">Işık: {{ brightness }}%</label>
        <input
          type="range"
          id="brightness"
          min="30"
          max="150"
          v-model="brightness"
          @input="applyFilters"
        />

        <label for="saturation">Renk: {{ saturation }}%</label>
        <input
          type="range"
          id="saturation"
          min="0"
          max="200"
          v-model="saturation"
          @input="applyFilters"
        />
      </div>
      <div class="buttons">
        <div @click="saveSettings" class="save-btn">Kaydet</div>
        <div @click="resetSettings" class="reset-btn">Sıfırla</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
      tokenCheckInterval: null,
      brightness: 100,
      saturation: 100,
      tooltipVisible: false,
    };
  },
  mounted() {
    this.checkLoginStatus();
    this.startTokenCheck();
    this.loadSettings();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    this.clearTokenCheck();
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    checkLoginStatus() {
      this.isLoggedIn = !!localStorage.getItem('token');
    },
    startTokenCheck() {
      this.tokenCheckInterval = setInterval(this.checkLoginStatus, 1000);
    },
    clearTokenCheck() {
      clearInterval(this.tokenCheckInterval);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToProfile() {
      this.$router.push('/hesabim');
    },
    logout() {
      localStorage.removeItem('token');
      this.checkLoginStatus();
      this.$router.push('/');
    },
    applyFilters() {
      const wrapper = document.getElementById('app');
      if (wrapper) { 
        wrapper.style.setProperty('--app-brightness', this.brightness + '%');
        wrapper.style.setProperty('--app-saturation', this.saturation + '%');
      }
    },
    saveSettings() {
      const settings = {
        brightness: this.brightness,
        saturation: this.saturation,
      };
      localStorage.setItem('appFilters', JSON.stringify(settings));
      this.tooltipVisible = false;
    },
    resetSettings() {
      this.brightness = 100;
      this.saturation = 100;
      localStorage.removeItem('appFilters');
      this.applyFilters();
    },
    loadSettings() {
      const saved = localStorage.getItem('appFilters');
      if (saved) {
        const { brightness, saturation } = JSON.parse(saved);
        this.brightness = brightness;
        this.saturation = saturation;
        this.applyFilters();
      }
    },
    toggleTooltip() {
      this.tooltipVisible = !this.tooltipVisible;
    },
    handleClickOutside(event) { 
      if (!this.tooltipVisible) return;
      
      const tooltipEl = this.$refs.tooltip;
      const filterButtonEl = this.$refs.filterButton;
       
      if (
        (tooltipEl && tooltipEl.contains(event.target)) ||
        (filterButtonEl && filterButtonEl.contains(event.target))
      ) {
        return;
      }
       
      this.tooltipVisible = false;
    },
  },
};
</script>


<style scoped>
.top-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: -5px;
  right: 1rem;
  z-index: 1000;
}

.topmenu-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 10px;
  padding-bottom: 10px;
  width: 2rem;
  height: 45px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s, transform 0.3s;
}

.topmenu-button:hover {
  transform: translateY(3px);
  opacity: 1;
}
 
.login-button {
  background: linear-gradient(45deg, #031608, #006400, #031608);
}

.profile-button {
  background: linear-gradient(45deg, #011111, #023032, #011111);
}

.logout-button { 
  background: linear-gradient(45deg, #260000, #500000, #260000);
}

.filter-button { 
  background: transparent;
}

.filter-tooltip {
  position: absolute;
  top: 60px;
  right: 20px;
  background: #050505;
  color: #fff;
  padding: 15px;
  border-radius: 10px; 
  width: 180px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px 5px rgba(6, 89, 89, 0.26);
  gap: 5px;
  border: 1px solid #011111;
} 
.slider-container label {
  font-size: 1em; 
}

input[type="range"] {
  accent-color: #086b68;
  cursor: pointer;
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.save-btn, .reset-btn {
  flex: 1;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  max-width: 5em;
  text-align: center;
  transition: background 0.3s;
}

.save-btn {
  background-color: #006400;
  color: #fff;
}

.reset-btn {
  background-color: #444;
  color: #fff;
}

.save-btn:hover {
  background-color: #009900;
}

.reset-btn:hover {
  background-color: #555;
}

.topmenu-button img {
  max-width: 1.4rem;
  max-height: 1.4rem;
  object-fit: contain;
}
</style>
