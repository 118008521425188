<template>
<div class="destek-container">

  <div class="middle">

    <div class="header">
      Bir destek talebi göndermeden önce lütfen **Sıkça Sorulan Sorular (SSS)** bölümümüzü inceleyin.  
      Sorunuza anında bir yanıt bulabilirsiniz. <br>  
      Aradığınızı bulamazsanız, bir destek talebi oluşturabilirsiniz. Ekibimiz size yardımcı olmaktan memnuniyet duyacaktır.
    </div>
    <div class="user-support-container">
      <div class="left-section">
        <CreateTicket />
      </div>
      <div class="right-section">
        <SupportHistory />
      </div>
    </div>
    
  </div>

</div>
</template>


<script>
import SupportHistory from './support/SupportHistory.vue';
import CreateTicket from './support/CreateTicket.vue';

export default {
  components: {
    SupportHistory,
    CreateTicket
  }
};
</script>

<style scoped>
.destek-container {
  display: flex;
  flex-direction: row; 
  justify-content: center;
  width: 100%;
  
}
.middle { 
  width: 100%; max-width: 1600px;
}
.header {
  padding: 1em;  
  margin-bottom: 1em;
  font-weight: 500;
}
 .left-section, .right-section {
  margin-top: 1em; 
} 


@media (max-width: 920px) {
  .left-section, .right-section {
    width: 100%;
    flex-basis: 100%;   
  }
}

</style>
