<template>
    <div class="profile-container"> 
      <form @submit.prevent="updateProfile">
        <div v-if="loading">Yükleniyor...</div>
        <div v-else> 
        <div class="profile-info">
          <div>
            <p>User ID:</p>
            <p>{{ profile.user_id }}</p>
          </div>
          <div>
            <p>Kullanıcı Adı:</p>
            <p>{{ profile.user_name }}</p>
          </div>
          <div>
            <p>Email:</p>
            <p>{{ profile.e_mail }}</p>
          </div>
          <div>
            <p>Kayıt Tarihi:</p>
            <p>{{ formatDateTime(profile.registration_date) }}</p>
          </div>
          <div>
            <p>Son Giriş:</p>
            <p>{{ formatDateTime(profile.last_login) }}</p>
          </div>
          <div>
            <p>Doğrulama Seviyesi:</p>  <img v-if="verifiedIcon" :src="verifiedIcon" @click="goToLevel" alt="Hesap Doğrulama Seviyesi" class="verified-icon" />
          </div>
          <div>
            <p>Karma:</p>
            <p>{{ profile.karma }}</p>
          </div>
        </div>
  
          <div class="editable-fields">
            <label>Ad:</label>
            <input type="text" v-model="profile.first_name" />
  
            <label>Soyad:</label>
            <input type="text" v-model="profile.last_name" />
  
            <label>Doğum Tarihi:</label>
            <input type="date" v-model="profile.birth_date" />
  
            <label>Cinsiyet:</label>
            <select v-model="profile.gender">
              <option value="Male">Erkek</option>
              <option value="Female">Kadın</option>
              <option value="Other">Diğer</option>
            </select>
  
            <label>Telefon Numarası:</label>
            <input type="text" v-model="profile.phone_number" />
  
            <label>Ülke:</label>
            <input type="text" v-model="profile.country" />
  
            <label>Adres:</label>
            <textarea v-model="profile.address"></textarea>
          </div>
          <div v-if="message" class="message">{{ message }}</div>
          <button class="confirm" type="submit" :disabled="updating">Güncelle</button>
        </div>
      </form> 
      
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        profile: {},
        loading: true,
        updating: false,
        message: "",
      };
    },
    computed: {
      verifiedIcon() {
        return this.getVerifiedIcon(this.verifiedLevel);
      }
    },
    methods: {
      async fetchProfile() {
        try {
          const response = await axios.get("/promo/my-profile");
          if (response.data.success) {
            this.profile = response.data.data;
            this.verifiedLevel = response.data.data.verified_level;
          }
        } catch (error) {
          console.error("Profil alınırken hata oluştu:", error);
        } finally {
          this.loading = false;
        }
      },
      async updateProfile() {
        this.updating = true;
        try {
          const updatedData = {
            first_name: this.profile.first_name,
            last_name: this.profile.last_name,
            birth_date: this.profile.birth_date,
            gender: this.profile.gender,
            phone_number: this.profile.phone_number,
            country: this.profile.country,
            address: this.profile.address,
          };
  
          const response = await axios.post("/promo/update-my-profile", updatedData);
          if (response.data.success) {
            this.message = "Profil başarıyla güncellendi!";
          } else {
            this.message = "Güncelleme sırasında bir hata oluştu!";
          }
        } catch (error) {
          console.error("Profil güncellenirken hata oluştu:", error);
          this.message = "Bir hata meydana geldi!";
        } finally {
          this.updating = false;
        }
      }, 
      getVerifiedIcon(level) { 
        if (!level || level === 0) {
          return ""; 
        }
      
        let iconPath = "";
        try {
          iconPath = require(`@/assets/icon/profile/level/${level}.svg`);
        } catch (e) {
          try {
            iconPath = require(`@/assets/icon/profile/level/${level}.png`);
          } catch (err) {
            console.warn(`Verified icon for level "${level}" not found.`); 
          }
        }
        return iconPath;
      },
      goToLevel() {
        this.$router.push("/hesap-seviyeleri");
      },
    },
    mounted() {
      this.fetchProfile();
    }
  };
  </script>
  
  <style scoped>
  .profile-container {
    min-width: 50%;
    max-width: 1600px;
    margin: auto; 
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .editable-fields {
    padding: 5px;
  }

  .profile-info input,
  .editable-fields input,
  .editable-fields select,
  .editable-fields textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  } 
  
  button:disabled {
    background-color: #000000;
  }
  
  .message {
    margin-top: 10px;
    color: green;
  } 

  .profile-info {
    display: flex;
    flex-wrap: wrap;
    gap: 1em; 
    margin-bottom: 1rem;
  }

  .profile-info > div {
    display: flex; 
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;
    background-color: #050505;
    border-radius: 4px;
    flex: 1 1 calc(33.333% - 32px);
    box-sizing: border-box;
  }

  .profile-info p {
    margin: 0;
  }

  .profile-info p:first-child {
    font-weight: 500;
    color: #187774;
    margin-right: 5px;
  }

  .profile-info p:last-child {
    color: #e0e0e0;
  }
  .verified-icon {
    max-width: 20px;
    margin-left: 5px;
    cursor: pointer ;
  }
  </style>
  