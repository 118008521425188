<template>
   <div style="display: flex; justify-content: center;">
  <div class="create-question">
 
    <div class="please-login" v-if="!isLoggedIn">
      <p>Yeni soru sorabilmek için lütfen giriş yapın</p>
      <button class="confirm" @click="redirectToLogin">Giriş Yap</button>
    </div>
 
    <div class="soru-basarili" v-else-if="successMessage">
      <p class="success-message">{{ successMessage }}</p>
      <button @click="resetForm">Yeni Soru Sor</button>
    </div>
 
    <div v-else>
      <h2 class="question-title">Yeni soru sor</h2> 
      <form @submit.prevent="submitQuestion">
        <div class="q-title"> <img class="btn-icon" src="./icons/head.svg">Sorunuzun Başlığı
          <span class="q-info">*En fazla 250 karakter</span>
        </div>
        
        <input v-model="title" type="text" placeholder="Başlık" maxlength="250" required />
        <div class="q-title"> Sorunuzun Detayları 
        <span class="q-info">*En fazla 5.000 karakter</span>
        </div>
        <textarea v-model="details" placeholder="Detaylar" maxlength="5000" required></textarea>
        <div style="display: flex; align-items: baseline; gap: 5px; margin-bottom: 0;">
        <p class="q-title">Konu Etiketleri</p>
        <p class="q-info">*En az 1 etiket</p> </div>
        <div class="tags-container">
          <label v-for="tag in tags" :key="tag.tag_id" class="tag-option">
            <input type="checkbox" :value="tag.tag_id" v-model="selectedTags" />
            #{{ tag.tag_name }}
          </label>
        </div>

        <div class="info-box">
          <span><b>Lütfen Dikkat</b></span>
          <p>Gönderiniz, moderasyon onayından sonra "herkese açık olarak" yayınlanacaktır.</p>
          <p>İmla kurallarına ve nezakete özen gösterdiğiniz için teşekkür ederiz. 🙏✨</p>
        </div>

        <div style="display: flex; justify-content: flex-end;">
          <button type="submit">Soruyu Gönder</button>
        </div>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateQuestion",
  data() {
    return {
      title: "",
      details: "",
      tags: [],
      selectedTags: [],
      successMessage: "",
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },
  },
  methods: {
    async fetchTags() {
      try {
        const response = await axios.get("/auth/user/forumtags");
        this.tags = response.data.data || [];
      } catch (error) {
        console.error("Tag bilgileri alınırken hata oluştu:", error);
      }
    },
    async submitQuestion() {
      // En az 1 etiket seçili mi kontrol et
      if (this.selectedTags.length === 0) {
        alert("En az 1 etiket seçin");
        return;
      }
      try {
        const response = await axios.post(
          "/promo/user/forum/createquestionforuser",
          {
            title: this.title,
            details: this.details,
            tags: this.selectedTags,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          this.successMessage =
            "Sorunuz başarı ile oluşturuldu. Moderasyon sonrası yayınlanacak.";
        }
      } catch (error) {
        console.error("Soru oluşturulurken hata oluştu:", error);
      }
    },
    resetForm() {
      this.title = "";
      this.details = "";
      this.selectedTags = [];
      this.successMessage = "";
    },
    redirectToLogin() {
      this.$router.push("/login");
    },
  },
  mounted() {
    this.fetchTags();
  },
};
</script>

<style scoped>
.create-question {
  display: flex;
  flex-direction: column; 
  max-width: 1620px;
  width: 100%; 
}
.question-title {
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}
button {
  background-color: #106e35;
  color: white;
  font-weight: 400;
}

button:hover {
  background-color: #0a4f26;
}

.success-message {
  color: #106e35;
  font-weight: 500;
  font-size: 1rem;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: -1em;
  padding: 0;
}

.tag-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #022121;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box {
  margin: 0.8rem 0;
  padding: 10px;
  background-color: black;
}

.info-box p {
  margin: 0.2rem 0;
}

.q-title {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
  margin-top: 1em; 
  display: flex;
  align-items: center;
}

.q-info {
  font-size: 0.7rem;
  font-weight: 400;
  color: #917e5c; 
}
textarea {
    min-height: 6rem;
  }
  .btn-icon{
  max-width: 1.2rem;
  max-height: 1.2rem;
  margin-right: 4px;
} 
@media (max-width: 600px) {
  input,
  textarea {
    width: 100%;
  }
}

form {
  margin-bottom: 5rem;
}

.soru-basarili {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
}
.please-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
