<template>
    <div class="security-modal-component">
      <div class="security-icon" @click="openModal">
        <img src="@/assets/security.svg" alt="Security Icon" />
      </div> 
      <div v-if="showModal" class="modal-overlay" @click="handleOverlayClick">
        <div ref="modalContent" class="modal-content" :style="modalStyle" @click.stop>
          <div class="close-div">
            <button class="close-button" @click="closeModal">X</button>
          </div>
          <p class="title">GİZLİLİK & GÜVENLİK</p>

          <p>ÜST DÜZEY GİZLİLİK / YASALARA TAM UYUMLULUK</p>
          <p style="color: #cca971; font-weight: 500;">Portal üzerindeki verileriniz, yalnızca sizin şifrenizden türetilen özel bir anahtar (key derivation) ile AES 256-bit algoritması kullanılarak uygulama seviyesinde şifrelenir. 
            <br>Şifrenizden bağımsız kimse verilerinize erişemez ve veriler yalnızca doğru şifre ile çözülebilir. <br>
        <br> </p>
        <div class="encryption-flow">
             <div class="flow-item">
               <div class="box">Kullanıcı Şifresi</div>
               <div class="arrow">→</div>
             </div>
             <div class="flow-item">
               <div class="box">Key Derivation<br />(PBKDF2, bcrypt)</div>
               <div class="arrow">→</div>
             </div>
             <div class="flow-item">
               <div class="box">AES 256 Anahtar</div>
               <div class="arrow">→</div>
             </div>
             <div class="flow-item">
               <div class="box">AES 256 Şifreleme</div>
               <div class="arrow">→</div>
             </div>
             <div class="flow-item">
               <div class="box">Şifrelenmiş Veritabanı</div>
             </div>
           </div>
           <p style="color: #cca971; font-weight: 500;"><b>LÜTFEN DİKKAT:</b> Portal, Zero-knowledge encryption (bilgi sahibi olmayan şifreleme) mantığına dayalı çalışır. Yani, platform ve sunucu sağlayıcıları da verilerin içeriğine erişemez.
            Şifre unutulursa, veriler tamamen kaybolur çünkü çözmek için gerekli anahtar, yalnızca kullanıcının şifresinden üretilir. Ayrıca verileri silmeniz halinde kurtarılabilmesi mümkün değildir. Lütfen, eğer seçenek sunulmuş ise arşivleme seçeneğini değerlendirin. 
            <br><br>Hangi verilerin bu şifreleme ile saklandığını görmek ve detaylı bilgi için, <router-link to="/sozlesmeler" style="color: #cca971; font-weight: 500;">Sözleşme Merkezimizi</router-link> ziyaret edebilirsiniz.
                     
                     
          </p>
            <p>Lütfen belirli periyotlarla şifrelerinizi güncelleyin. <br>Lütfen daha güvenli kullanım deneyimi için profilinizden 2FA'yı aktifleştirin. <br>2FA, hesabınıza giriş esnasında E-posta ile 6 haneli kod almanızı ve bu kod ile hesabınıza giriş yapmanızı sağlar. 
            </p>
   
           
          <div class="security-item">
            <h4 class="title">KVKK Uyumlu<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Kişisel Verilerin Korunması Kanunu'na tam uyum sağlıyoruz. Veriler sadece açık rızanız ile işlenir ve üçüncü taraflarla paylaşılmaz. Yalnızca geliştirme ve iyileştirme amaçlı kullanım istatistikleri anonim olarak toplanır. 
             </p>
          </div>
          <div class="security-item">
            <h4 class="title">GDPR Uyumlu<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Avrupa Birliği Genel Veri Koruma Tüzüğü'ne (GDPR) uygun olarak veri gizliliği haklarınıza saygı gösteriyoruz ve verilerinizi şeffaf şekilde yönetiyoruz.</p>
          </div>
          <div class="security-item">
            <h4 class="title">SSL & TLS<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Tüm veri iletimlerinde SSL/TLS kullanılarak bağlantılar şifrelenir ve dış müdahalelere karşı koruma sağlanır.</p>
          </div>
          <div class="security-item">
            <h4 class="title">AES 256 / Veri Maskeleme & Tokenization<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Veritabanı ve depolama alanlarında AES 256-bit şifreleme uygulanarak veriler kriptolanır ve izinsiz erişimlere karşı korunur.</p>
          </div>
          <div class="security-item">
            <h4 class="title">WAF Koruması<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Web Application Firewall ile zararlı trafik ve saldırılar (SQL Injection, XSS gibi) gerçek zamanlı olarak engellenir.</p>
          </div>
          <div class="security-item">
            <h4 class="title">2FA Desteği<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Kullanıcı girişi sırasında ek doğrulama adımı (E-posta veya SMS uygulaması) ile kimlik doğrulama katmanı artırılır.</p>
          </div>
          <div class="security-item">
            <h4 class="title">Penetrasyon Testleri<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Bağımsız güvenlik ekipleri tarafından düzenli olarak sızma testleri yapılarak sistem açıkları tespit edilir ve kapatılır.</p>
          </div> 
          <div class="security-item">
            <h4 class="title">DLP Önlemleri<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Verilerin kurum dışına izinsiz çıkmasını önlemek için veri kaybı önleme (DLP) çözümleri aktif olarak çalışır.</p>
          </div>
          <div class="security-item">
            <h4 class="title">OWASP Top 10 Uyumu<img src="@/assets/icon/application/ok.svg" alt="Aktif" /></h4>
            <p>Web uygulaması geliştirmelerinde OWASP Top 10 standartları dikkate alınır ve yaygın saldırılara karşı koruma sağlanır.</p>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, nextTick } from "vue";
  
  export default {
    setup() {
      const showModal = ref(false);
      const modalStyle = ref({
        transform: "translateY(100%)",
        transition: "transform 0.3s ease-in-out",
      });
  
      const openModal = () => {
        showModal.value = true;
        nextTick(() => {
          setTimeout(() => {
            modalStyle.value.transform = "translateY(0)";
          }, 10);
        });
      };
  
      const closeModal = () => {
        modalStyle.value.transform = "translateY(100%)";
        setTimeout(() => {
          showModal.value = false;
        }, 400);
      };
  
      const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      };
  
      return {
        showModal,
        modalStyle,
        openModal,
        closeModal,
        handleOverlayClick,
      };
    }
  };
  </script>

  <style scoped>
  .security-modal-component {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .security-icon {
    cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  .security-icon:hover {
    transform: scale(1.1);
  }
  .security-icon img {
    width: 5rem;
    height: 5rem;
    opacity: 0.5;
  }
  .security-icon img:hover {
    width: 5rem;
    height: 5rem;
    opacity: 1;
  }
  
  /* Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999;
  }
  
  .modal-content { 
    width: 100%;
    max-width: 680px;
    background: linear-gradient(45deg, #020202, #030b0b, #050505);
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #022121;
    border-left: 1px solid #022121;
    border-right: 1px solid #022121;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    overflow-y: auto;
    max-height: 70vh;
    min-height: 60vh;
  }
  
  .close-div {
    position: sticky;
    border-radius: 15px 15px 0 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    padding: 0.5rem;
  }
  
  .close-button {
    background-color: #000000;
    color: #ffffff;
    padding: 0.8rem;
    min-width: 3rem;
    max-width: 3rem;
    border-radius: 2rem;
    opacity: 0.8;
    border: 1px solid #444444;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
   
  .close-button:hover {
    opacity: 1;
    transform: scale(1.1);
  }
   
  @media (max-width: 768px) {
    .modal-content {
      max-width: 90%;
    }
  }
  

.security-item h4 {
  color: #106e35;
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.security-item h4 img{
  height: 0.7em; margin-left: 5px; opacity: 0.7;
}

.security-item p { 
  line-height: 1.4;
}



.encryption-flow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 2rem 0;
}

.flow-item {
  display: flex;
  align-items: center;
}

.box { 
  border: 1px solid #6e5d41;
  padding: 1em;
  min-width: 6rem;
  text-align: center;
  border-radius: 10px;
  color: #cec8bf; 
  transition: transform 0.3s ease;
}

.box:hover {
  transform: translateY(-3px);
}

.arrow {
  color: #cca971;
  font-size: 1.5rem;
  margin: 0 0.3rem;
}


  </style>