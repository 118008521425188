<template>
  <div :class="['language-menu', { open: isOpen }]">
    <img
      class="triangle-indicator"
      :src="require('@/assets/icon/languages/ucgen.svg')"
      :class="{ rotated: isOpen }"
      @click.stop="toggleMenu"
    />
    <div class="icon-container">
      <div class="icon-wrapper" v-for="(icon, index) in visibleIcons" :key="index">
        <img
          :src="icon.src"
          :alt="icon.alt"
          :class="['icon', { selected: selectedIcon === index }]"
          @click.stop="selectIcon(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../utils/eventBus'
export default {
  name: "LanguageSelectorMenu",
  data() {
    return {
      isOpen: false,
      selectedIcon: 4,
      icons: [
        { code: 'de', src: require("@/assets/icon/languages/de.svg"), alt: "German" },
        { code: 'en', src: require("@/assets/icon/languages/en.svg"), alt: "English" },
        { code: 'es', src: require("@/assets/icon/languages/es.svg"), alt: "Spanish" },
        { code: 'fr', src: require("@/assets/icon/languages/fr.svg"), alt: "French" },
        { code: 'tr', src: require("@/assets/icon/languages/tr.svg"), alt: "Turkish" }
      ]
    };
  },
  computed: {
    visibleIcons() {
      return this.isOpen ? this.icons : [this.icons[this.selectedIcon]];
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectIcon(index) {
      if (!this.isOpen) {
        this.isOpen = true;
        return;
      }
      const selected = this.icons.splice(index, 1)[0];
      this.icons.push(selected);
      this.selectedIcon = this.icons.length - 1;
      this.isOpen = false;
      
      eventBus.emit('language-changed', selected.code);
      console.log('language-changed', selected.code ); 
      localStorage.setItem('selectedLanguage', selected.code);
    },
    handleClickOutside(event) {
      // Tıklanan element veya üst öğelerinde "icon" sınıfı varsa işlemi iptal ediyoruz.
      let el = event.target;
      while (el) {
        if (el.classList && el.classList.contains('icon')) {
          return;
        }
        el = el.parentElement;
      }
      // Eğer menü açıksa ve tıklama ikon dışında ise menüyü kapatıyoruz.
      if (this.isOpen) {
        this.isOpen = false;
      }
    }
  },
  mounted() {
    const savedLang = localStorage.getItem('selectedLanguage') || 'tr';
    eventBus.emit('language-changed', savedLang);
    const savedIndex = this.icons.findIndex(icon => icon.code === savedLang);
    if (savedIndex !== -1) {
      this.selectedIcon = savedIndex;
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.language-menu { 
  margin-top: -80px;
  left: 0;
  bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000;
  padding: 5px;
  border-radius: 0 2rem 2rem 0;
  transition: transform 0.5s;
  z-index: 1000;
  transform: translateX(0);
  opacity: 0.4;
  max-width: 5rem;
}
.language-menu.open {
  transform: translateX(0);
  opacity: 1;
}
.icon-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon-wrapper {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.icon {
  width: 100%;
  height: auto;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s, transform 0.3s;
  border: 2px solid transparent;
  border-radius: 50%;
  object-fit: contain;
}

.icon.selected {
  opacity: 1;
  border-color: #a8a8a7;
}
.triangle-indicator {
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  transition: transform 0.3s;
  cursor: pointer;
}
.triangle-indicator.rotated {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .language-menu {
    bottom: 5%;
    padding: 5px;
    transform: scale(0.6);
    left: -1rem; 
  } 
  .language-menu.open {
    left: 0rem; 
    padding: 10px;
  }
  .icon.selected { 
    border-color: transparent;
  }
}
</style>
