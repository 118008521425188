<template>
  <div style="display: flex; justify-content: center;">
    <div class="my-questions"> 
      <div v-if="isLoggedIn">
        <input v-model="searchQuery" type="text" placeholder="Sorularımda ara..." class="search"/> 
        <div v-if="filteredQuestions.length > 0">
          <div v-for="question in filteredQuestions" :key="question.question_id" class="question-card" @click="openQuestionDetails(question.question_id)">
            <p class="question-title">           
              <img 
                v-if="question.is_important" 
                src="@/components/forum/icons/important.svg" 
                alt="Önemli" 
                class="important-icon"
              />
              {{ question.title }}
            </p> 
            
            <p class="tag" v-if="question.tags.length > 0"> 
              <span v-for="tag in question.tags" :key="tag.tag_id" class="tag">
                #{{ tag.tag_name }}
              </span>
            </p>
  
            <div class="question-details"> 
              <p>Durum: 
                <span :class="question.is_solved ? 'solved' : 'unsolved'">
                  {{ question.is_solved ? 'Çözüldü' : 'Cevaplanıyor' }}
                </span>
              </p>
              <p>Oluşturulma: {{ formatDateTime(question.created_at) }}</p>
              <p>Görüntüleme: {{ question.view_count }}</p>
              <p>Toplam yanıt: {{ question.total_answers }}</p> 
              <p>Durum: 
                <span :class="statusClass(question.status)">
                  {{ getStatusText(question.status) }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p>Hiç soru bulunamadı.</p>
        </div>
      </div>
      <div class="please-login" v-else>
        <p>Lütfen giriş yapın</p> 
        <button class="confirm" @click="redirectToLogin">Giriş Yap</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { eventBus } from '@/utils/eventBus';

export default {
  name: "MyQuestions",
  data() {
    return {
      questions: [],
      searchQuery: ""  
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },
    filteredQuestions() {
      if (!this.searchQuery) return this.questions;
      return this.questions.filter(q =>
        q.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    async fetchQuestions() {
      try {
        const response = await axios.get("/promo/user/questions/myquestions");
        this.questions = response.data.data || [];
      } catch (error) {
        console.error("Kendi sorularını alırken hata oluştu:", error);
      }
    }, 
    openQuestionDetails(questionId) {
      eventBus.emit('openQuestion', questionId);
    },
    getStatusText(status) {
      switch (status) {
        case "Pending":
          return "Onay Bekliyor";
        case "Approved":
          return "Onaylandı";
        case "Rejected":
          return "Reddedildi";
        default:
          return "Bilinmiyor";
      }
    }, 
    statusClass(status) {
      switch (status) {
        case "Pending":
          return "status-pending";
        case "Approved":
          return "status-approved";
        case "Rejected":
          return "status-rejected";
        default:
          return "";
      }
    },
    redirectToLogin() {
      this.$router.push('/login');
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.fetchQuestions();
    }
  }
};
</script>

<style scoped> 
.my-questions {
  display: flex;
  flex-direction: column;
  gap: 5px; 
  max-width: 1620px;
  width: 100%;
}
 
.tag-filters {
  display: flex;
  gap: 0.1rem;
  margin-bottom: 0.1rem;
  flex-wrap: wrap;
}

.tag-filters button {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem; 
  border-radius: 0;
  border: 1px solid #022121;
  background-color: #022121; 
  cursor: pointer;
  transition: background 0.3s ease;
}

.tag-filters button:hover {
  background-color: #050505;
}

/* Seçili Olan Tag */
.tag-filters button.active {
  background-color: #050505; 
  color: white;
}

/* Soru Kartı */
.unsolved-questions {
    display: flex;
    flex-direction: column; 
    gap: 5px; 
    padding: 0.2rem; 
  } 
  .question-card {
    background-color: #050505;  
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;   
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  }
  .question-card:hover {
    background-color: black;  
    box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);  
    cursor: pointer;
  }
  .question-title{  
    font-size: 1rem; 
    font-weight: 500;
    margin: 0px;
    padding: 0;
  }
  .question-details{ 
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 5px;
    padding: 0;
  }
 
  .question-details p {  
    margin-top: 5px;
    margin-bottom: 0px;
    padding: 0;
  } 
  .tag {   
    font-size: 0.8rem;  
    margin: 0;
    color: #7c7c7c;
  }
 
.important-icon {
  width: 1rem; 
  height: 1rem;
}

@media (max-width: 768px) {
  .tag-filters button { 
    font-size: 0.6rem;  
  }
  .question-title{ 
    
    font-weight: 500; 
  }
  .question-details{ 
    font-size: 0.6rem; 
  }
  .tag {   
    font-size: 0.6rem;  
  }
}

/* Çözüldü - Çözülmedi (Durum) */
.solved {
  color: #106e35; 
}

.unsolved {
  color: #065959;  
}

/* Status Renkleri */
.status-pending {
  color: #917e5c; 
}

.status-approved {
  color: #106e35; 
}

.status-rejected {
  color: #a50000; 
}
.please-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style> 
