<template>
    <div class="randevu-container">     
        <RandevuHeader />  
        <UserCalender />
    </div>
  </template>
  
  <script>  
import RandevuHeader from './RandevuHeader.vue';
import UserCalender from './UserCalendar.vue';
  
  export default { 
    components: { 
      RandevuHeader,
      UserCalender
    },
    data() {
      return { 
      };
    },
    methods: { 
    },
    mounted() { 
    }
  };
  </script>
  
  
   
<style scoped> 
.randevu-container {
  padding-top: 120px;
}
@media (max-width: 768px) {
  .randevu-container{
  padding-top: 90px;
}
}
 </style>
  