<template>
  <div class="support-history-container">
    <table>
      <thead>
        <tr>
          <th>Talep ID</th>
          <th>Konu</th>
          <th>Durum</th>
          <th>Detaylar</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="ticketsToShow.length > 0">
          <tr v-for="ticket in ticketsToShow" :key="ticket.ticket_id">
            <td>{{ ticket.ticket_id }}</td>
            <td>{{ ticket.subject }}</td>
            <td>{{ ticket.status }}</td>
            <td>
              <button class="sh-buttons" @click="toggleDetails(ticket)">Detaylar</button>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td  colspan="4">Destek geçmişi bulunamadı</td>
        </tr>
      </tbody>
    </table>
    <button v-if="tickets.length > ticketsToShow.length" @click="showMoreTickets" class="moreButton">Daha Fazla Göster</button>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h4>Talep ID: {{ currentTicket.ticket_id }} | Durum: Kapalı</h4>
        <div v-for="response in currentTicket.responses" :key="response.response_id" class="response">
          <p><strong>{{ response.is_admin_response ? 'Destek Ekibi' : 'Siz' }}:</strong> {{ response.message }}</p>
          <span class="response-date">{{ formatDate(response.response_date) }}</span>
        </div>
        <button class="sh-buttons" @click="closeModal">Kapat</button>
      </div>
    </div>
  </div>
</template>
 
<script>
import axios from 'axios';

export default {
  data() {
    return {
      tickets: [],
      ticketsToShowCount: 5,
      showModal: false,
      currentTicket: null
    };
  },
  computed: {
    ticketsToShow() {
      return this.tickets.slice(0, this.ticketsToShowCount);
    }
  },
  methods: {
    async fetchSupportHistory() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/promo/user-tickets', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.tickets) {
          this.tickets = response.data.tickets.filter(ticket => ticket.status === 'Closed');
        }
      } catch (error) {
        console.error('Destek geçmişi getirilirken hata oluştu:', error);
      }
    },
    toggleDetails(ticket) {
      this.currentTicket = ticket;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.currentTicket = null;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    showMoreTickets() {
      this.ticketsToShowCount += 10;
    }
  },
  async mounted() {
    try {
      await this.fetchSupportHistory();
    } catch (error) {
      console.error(error);
    }
  }
};
</script> 

<style scoped>
.support-history-container {
  width: 100%;
} 
.ticket-details {
  margin-top: 1em;
}

.response {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #000;
  border-radius: 4px;
}
.sh-buttons {
  padding: 0.4em 1em;
  border-radius: 0.2em;
  cursor: pointer; 
  transition: background-color 0.3s ease;
}
.sh-buttons:hover {
  background-color: #070e11;
  color: #b1c3c9;
} 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #050505;
  padding: 2em;
  border-radius: 0.2em;
  width: 80%;
  max-width: 1300px;
  overflow-y: auto;
  max-height: 70%;
}
</style>
