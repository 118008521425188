<template>
  <div class="selected-detail">
    <p class="category-title">{{ details?.sub_category_name }}</p>
    <p class="title">{{ details?.name }}</p>

    <div class="product-header">
      <img
        class="head-image"
        v-if="details?.detail_image"
        :src="details.detail_image"
        alt="Detail Image"
        v-no-right-click
      />
      <div v-if="details?.product_batches" class="ci-container">
        <div class="batch-buttons">
          <div
            class="batch-button"
            v-for="(batch, index) in details?.product_batches"
            :key="batch.batch_id"
            :class="{ 'selected-batch': index === selectedBatchIndex }"
            @click="selectBatch(index)"
          >
            {{ batch.batch_name }}
          </div>
        </div>

        <div class="ci-codes" v-if="selectedBatch">
          <div class="ci-code-item" v-for="ci in selectedBatch.ci_codes" :key="ci.ci_id">
            <span class="color-dot" :style="{ backgroundColor: ci.color_code }"></span>
            <span>{{ ci.ci_code }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="minor-category-info">
      <div v-if="details?.sub_tone && details?.sub_tone !== 'null'" class="sub-tone-text">
        <img v-if="details?.sub_tone === 'Soğuk'" src="./icons/soguk.svg" alt="Soğuk icon" />
        <img v-else-if="details?.sub_tone === 'Sıcak'" src="./icons/sicak.svg" alt="Sıcak icon" />
        <img v-else-if="details?.sub_tone === 'Nötr'" src="./icons/notr.svg" alt="Nötr icon" />
        {{ details.sub_tone }}
      </div>
    </div>
 
    <div class="product-features" v-if="details?.product_features?.length">
      <div 
        class="feature-item" 
        v-for="feature in details.product_features" 
        :key="feature.feature_id"
      >
        <p class="feature-name">{{ feature.name }}</p>
        <p class="feature-detail">{{ feature.detail }}</p>
      </div>
    </div>
    

    <div class="description" v-html="details?.description"></div>

    <div class="sub-img">
      <div v-if="details?.background_image" class="background-image">
        <img :src="details.background_image" alt="Background Image" v-no-right-click />
      </div>
    </div>

    <div v-if="details?.documents && details.documents.length" class="document-icons">
      <a
        v-for="document in details.documents"
        :key="document.document_id"
        :href="document.document_link"
        target="_blank"
      >
        <img :src="document.icon_link" alt="Document Icon" class="document-icon" />
      </a>
    </div>

    <div class="buy-icon-container">
      <div class="buy-icon" @click="handleBuyClick">
        <img src="./icons/buy-now.svg" alt="Satın Al" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.selected-detail {
  line-height: 1.6;
  margin: 10px;
  overflow: auto;
}
.category-title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}
.title {
  font-size: 2rem;
  font-weight: 200;
  margin: 0;
  text-align: center;
}
.product-header {
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px; 
  padding: 1rem 0;
}
.head-image { 
  max-height: 30rem;
  max-width: 19rem;
}
.batch-buttons {
  display: flex;
  gap: 5px;
  margin-bottom: 15px; 
}
.batch-button {
  font-size: 1em;
  font-weight: 500;
  padding: 0px;
  border: none;
  background-color: transparent; 
  margin: 0px;
  transition: background-color 0.3s;
  cursor: pointer;
  opacity: 0.5;
} 
.batch-button:hover {
  color: white;
  opacity: 1;
}
.batch-button.selected-batch { 
  color: #fff; 
  border-color: #023032;
  opacity: 1;
}
.ci-codes { 
  display: flex;
  gap: 10px;
  flex-wrap: wrap; 
  flex-direction: column;
}
.ci-code-item {
  display: flex; 
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  font-weight: 300;
}
.color-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #666666;
}



.product-features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 2rem; 
  margin-top: 2rem; 
  flex-wrap: wrap;
}

.feature-item { 
  padding: 0.2em 2em;
  border-radius: 0.8em;
  border: 1px solid;
  border-color: #022121;
}

.feature-name {
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 5px;  
}

.feature-detail {
  font-size: 1em; 
  margin-top: 0px; 
} 

.description {
  max-width: 1200px; 
}
.sub-img {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.background-image img {
  max-width: 380px;
}
.document-icons {
  margin: 30px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.document-icon {
  width: 80px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.document-icon:hover {
  transform: scale(1.1);
}
.minor-category-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
}
.sub-tone-text img {
  max-width: 2rem;
}
.buy-icon-container {
  margin: 30px 0;
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
}
.buy-icon {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  border: 1px solid #065959;
  background-color: blackv;
  border-radius: 50%;
  display: flex; 
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.buy-icon:hover {
  transform: scale(1.1);
} 

@media (max-width: 600px) {  
   .title {
      font-size: 2.5em;
      font-weight: 200;
      margin: 0;
      text-align: center;
    }
    .batch-button { 
      font-size: 0.7em;  
    }
    .head-image { 
      max-height: 30rem;
      max-width: 13rem;
    }
    .ci-code-item { 
      font-size: 0.8rem; 
    }
    .product-features {  
      margin-bottom: 1.5rem; 
      margin-top: 1rem;  
    } 
    .feature-item { 
      padding: 0.1em 2em;
      border-radius: 0.8em; 
    } 
    .feature-name {
      font-weight: 600;
      font-size: 1em;  
    }
    
    .feature-detail {
      font-size: 0.9em; 
      margin-top: 0px; 
    } 
}
</style>

<script>
import { ref, watch, onMounted, computed } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router'


export default {
  name: "SelectedDetail",
  props: {
    minorCategoryId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const details = ref(null);
    const selectedBatchIndex = ref(0);

    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `/auth/minor-category/details/${props.minorCategoryId}`
        );
        details.value = response.data.minorCategory;
        selectedBatchIndex.value = 0;
      } catch (error) {
        console.error("Detaylar yüklenirken hata:", error);
      }
    };

    watch(
      () => props.minorCategoryId,
      fetchDetails,
      { immediate: true }
    );

    onMounted(() => {
      fetchDetails();
    });
    const router = useRouter()
    const handleBuyClick = () => {
      router.push("/shopwebsiteleri");
    };

    //const handleBuyClick = () => {
    //  const token = localStorage.getItem("token");
    //  const productName = details.value?.name || "";
//
    //  if (token) { 
    //    const base64Token = btoa(token);
 //
    //    window.open(
    //      `https://shop.noahpmu.com/?auth=${encodeURIComponent(base64Token)}&name=${encodeURIComponent(productName)}`,
    //      "_blank"
    //    );
    //  } else { 
    //    window.open("https://shop.noahpmu.com/", "_blank");
    //  }
    //};

    const selectedBatch = computed(() => {
      return details.value?.product_batches?.[selectedBatchIndex.value] || null;
    });

    const selectBatch = (index) => {
      selectedBatchIndex.value = index;
    };

    return {
      details,
      handleBuyClick,
      selectedBatchIndex,
      selectedBatch,
      selectBatch,
    };
  },
};
</script> 