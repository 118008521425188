<template>
    <div class="noah-aura-container">
     <div class="ai-logo">NOAH<span style="font-weight: 600; animation: colorChangeTwo 5s ease infinite;">AURA</span> </div>
  
      <p class="intro">Noah AURA, Noah PMU tarafından kalıcı makyaj (PMU) uzmanlarının teorik ve pratik bilgilerini, akademik kaynakları ve sektördeki güncel uygulama deneyimlerini bir araya getiren bir yapay zeka modelidir.
      </p>
  
      <section class="section">  
        <img class="ai-icon" src="@/assets/icon/noahai.svg" alt="NOAH AI Icon"  />
        <p>Noah AURA, kalıcı makyaj uygulamaları için özelleştirilmiş bir <strong>Large Language Model (LLM)</strong>'dir. Yapay zeka teknolojilerini kullanarak pigmentoloji, cilt anatomisi ve uygulama yöntemleri üzerine eğitilmiştir. 
           <br> <br>Bu model, PMU uygulayıcılarının ve eğitmenlerinin paylaştığı bilgi ve deneyimlerden elde edilen geniş kapsamlı verileri analiz eder ve kullanıcılarına uygun yanıtlar sunar.
        </p>
      </section>
  
      <section class="section">
        <h3><img class="head-icon" src="@/assets/icon/head.svg" />Model Temel Özellikler</h3>
        <ul>
          <li>Kalıcı makyaj terimleri ve kavramları üzerine eğitilmiş gelişmiş dil yetenekleri.</li>
          <li>Farklı cilt tonları ve cilt alt tonlarına göre pigment önerileri.</li>
          <li>Uygulama teknikleri konusunda pratik ve teorik rehberlik.</li>
          <li>Olası komplikasyonlar ve uygulama riskleri hakkında önleyici bilgi desteği.</li>
          <li>Gerçek zamanlı analiz ve soru-cevap yetenekleri.</li>
          <li>10.000'in üzerinde gerçek uygulama verisi ile eğitilmiştir.</li>
        </ul>
      </section>
  
      <section class="section">
        <h3><img class="head-icon" src="@/assets/icon/head.svg" />Teknolojik Altyapı ve Eğitim Süreci</h3>
        <p>Noah AURA'nın temelinde transformer tabanlı yapay zeka mimarisi bulunmaktadır. PMU uzmanlarından elde edilen geniş kapsamlı verilerle eğitilen model, alandaki pratik sorunları ve teorik soruları etkili bir biçimde yanıtlayabilir. Kullanılan veriler arasında vaka analizleri, uygulama sonrası sonuçlar ve pigment davranışları üzerine bilimsel araştırmalar bulunmaktadır.
        </p>
        <ul>
          <li><strong>Veri Kaynağı:</strong> Uzman deneyimleri ve akademik çalışmalar.</li>
          <li><strong>Yöntem:</strong> Transformer tabanlı derin öğrenme algoritmaları.</li>
          <li><strong>Eğitim Seti:</strong> Kalıcı makyaj teknikleri, pigment analizleri ve cilt anatomisi üzerine detaylandırılmış bilgi kümeleri.</li>
        </ul>
      </section>
  
      <section class="section">
        <h3><img class="head-icon" src="@/assets/icon/head.svg" />Kullanım Alanları</h3>
        <ul>
          <li>Eğitim süreçlerinde teorik destek ve pratik uygulama tavsiyeleri sunmak.</li>
          <li>Güncel uygulama teknikleri hakkında bilgilendirme ve öneriler.</li>
          <li>Renk seçimi ve pigment karışımları hakkında rehberlik.</li>
          <li>Komplikasyon yönetimi ve risk azaltma önerileri.</li>
        </ul>
      </section>
  
      <section class="section">
        <h3><img class="head-icon" src="@/assets/icon/head.svg" />Teknolojik Altyapı</h3>
        <p>Noah AURA, modern transformer mimarisiyle çalışır ve gelişmiş veri işleme yöntemleri kullanır: </p>
        <ul>
          <li><strong>PMU Özelleştirilmiş Vektör Veritabanı:</strong> Verimli ve hızlı erişim sağlar.</li>
          <li><strong>Anlık Yanıt Üretimi:</strong> Hızlı ve güvenilir yanıtlar sunar.</li>
          <li><strong>Multimodal Yaklaşım:</strong> Farklı veri tiplerini birleştirip karmaşık soruları yanıtlayabilir.</li>
        </ul>
      </section>
  
      <section class="section">
        <h3><img class="head-icon" src="@/assets/icon/head.svg" />Nasıl Çalışır?</h3>
        <ol>
          <li>Kullanıcılar tarafından sorulan soruları anında işler ve analiz eder.</li>
          <li>Veritabanındaki bilgileri sentezleyerek yanıt oluşturur.</li>
          <li>PMU uygulamalarında doğru kararlar alınmasına yardımcı olur.</li>
        </ol>
      </section>
  
      <div style="text-align: center; margin: 2rem 0;"> 
        <router-link to="/kullanim-kilavuzu" class="info-link"><img class="icon" src="@/assets/icon/klavuz.svg" alt="Kılavuz Icon"  /> <br> Detaylı kullanım kılavuzu için tıklayın.</router-link></div>
  
      <footer>
        <p>Noah AURA, PMU uygulamalarınıza yönelik bilgi desteği sağlayan uzman bir yardımcıdır.</p>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: "NoahAuraInfo",
  };
  </script>
  
  <style scoped>
  .noah-aura-container {
    padding: 1em; 
  }

  .ai-logo {
    font-size: 2rem;
    font-weight: 200;
  }

  .ai-icon {
    max-width: 10rem; opacity: 0.5;
  }
   
  h3 { display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 300;
    font-size: 1rem;
  }
  .head-icon{ min-width: 1.5rem; max-width: 3rem; }
  .intro, .section p, ul, ol {
    line-height: 1.6;
  }
  
  .section {
    margin-top: 2rem;
  }
  
  .info-link {
    color: #086b68;
    text-decoration: none;
    text-align: center;
    font-weight: 400; font-size: 1rem;
  }
  .icon{max-width: 3rem;}
  footer {
    text-align: center;
    opacity: 0.8;
    margin-top: 2rem;
  } 

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
  }
  li {
    margin: 0;
    list-style: none;
    padding: 2em;
    border-radius: 0.5em;
    background: linear-gradient(45deg, #040404, #011111, #040404); 
    border: 1px solid #011111;

  }
  </style>