<template>
  <div class="footer-main">
    <footer class="footer">
      <div class="footer-column"> 
        <p  @click="navigateTo('/hakkimizda')" class="menu-item">Hakkımızda</p>
        <p  @click="navigateTo('/urunler')" class="menu-item">Ürünler</p>
        <p  @click="navigateTo('/portal')" class="menu-item">Noah Portal</p>
        <p  @click="navigateTo('/ucretsizegitim')" class="menu-item">Online Eğitimler</p>
        <p  @click="navigateTo('/masterclassegitim')" class="menu-item">Master Class</p>  
      </div>
      <div class="footer-column"> 
        <p  @click="navigateTo('/sozlesmeler')" class="menu-item">Sözleşme Merkezi</p> 
        <p  @click="navigateTo('/kullanim-kosullari')" class="menu-item">Kullanım koşulları</p>
        <p  @click="navigateTo('/cerez-politikasi')" class="menu-item">Çerez politikası</p>
        <p  @click="navigateTo('/veri-gizliligi')" class="menu-item">Veri gizliliği</p>
        <p  @click="navigateTo('/sertifikalar')" class="menu-item">Standartlar</p>
        
      </div>
      <div class="footer-column">  
        <p  @click="navigateTo('/iletisim')" class="menu-item">İletişim</p>
        <p  @click="navigateTo('/kullanim-kilavuzu')" class="menu-item">Kullanım Kılavuzu</p> 
        <p  @click="navigateTo('/sss')" class="menu-item">SSS</p>
        <p  @click="navigateTo('/etkinlikler')" class="menu-item">Duyurular</p>  
        <p  @click="navigateTo('/marka-materyal')" class="menu-item">Marka Materyal</p> 
      </div>
      <div class="footer-column">  
        <img src="@/assets/icon/footer-logo.svg" class="logo" alt="Logo">  

        <div class="store-contianer">
          <img src="@/assets/icon/footer/ios.svg" class="store" alt="ios"> 
          <img src="@/assets/icon/footer/android.svg" class="store" alt="android"> 
          <img src="@/assets/icon/footer/hmos.svg" class="store" alt="hmos"> 
        </div>

      </div>
    </footer>

    <div class="footer-last-child"> 
          <div class="security">
            <img src="@/assets/icon/footer/ssl.svg" class="app-icon" alt="ssl"> 
            <img src="@/assets/icon/footer/kvkk.svg" class="app-icon" alt="kvkk"> 
            <img src="@/assets/icon/footer/aes.svg" class="app-icon" alt="aes"> 
          </div> 
          <div class="powered">
            <img 
              src="/assets/noahai.svg" 
              class="powered-icon" 
              alt="Noah AI"
              @click="openNoahAI"
            /> 
          </div>
    </div> 

    <div class="feed">
      <FeedCard />
    </div>

    <div class="sticky-bottom"><p>©Noah {{ currentYear }} Tüm hakları saklıdır.</p></div>
  </div>
</template>

<script>
import FeedCard from './feedback/FeedCard.vue';

export default {
  components: { 
    FeedCard,
  },
  name: 'MainFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    openNoahAI() {
      window.open("https://ai.noahpmu.com/", "_blank");
    }
  }
}
</script>

<style scoped>
.footer-main {
  background-color: #000000;
  margin-top: 5rem;
}

.footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 2rem 1rem 1rem 1rem; 
  font-size: 0.7rem; 
  user-select: none;
  z-index: 1005;
}

.logo {
  max-width: 180px;
  margin-bottom: 0.5rem;
}

.icon-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.icon { 
  max-height: 30px;
  cursor: pointer;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%; 
  background-color: #000000;
  padding: 0.5rem 0; 
  padding-right: 32px; 
  text-align: right;
  z-index: 1100;
}

.sticky-bottom p {  
  padding: 0;
  margin: 0;
  padding-right: 32px; 
  font-size: 0.6rem; 
} 

.menu-item {   
  font-size: 0.7rem;
  color: #cecece;
}

.menu-item:hover { 
  color: #ffffff;
  cursor: pointer;
}
.footer-column:last-child {
  opacity: 0.8;
}

.footer-column p {
  margin: 0.4em 0;
}
.store-contianer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.store{
  max-width: 2.5rem;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 8px 18px rgba(2, 48, 50, 0); 
  opacity: 0.8;
  transition: transform 0.3s ease, box-shadow 0.5s ease; 
}
.store:hover {
  max-width: 2.5rem;
  width: 100%;
  transform: scale(1.04); 
  box-shadow: 0 8px 18px rgba(133, 133, 133, 0.5); 
  opacity: 1;
} 
.app-icon {
  max-height: 4rem; padding-left: 1rem; opacity: 0.3;
} 

.footer-last-child { 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  width: 100%; 
}
.security { 
  display: flex; 
  align-items: center;  
  width: 100%; 
  opacity: 0.8;
}

.powered {
  width: 100%; 
  display: flex;
  justify-content: center;
}
.powered-icon {
  width: 100%;
  max-height: 5rem; 
  max-width: 12rem;
  margin-left: 12rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .sticky-bottom p {  
    padding: 0;
    margin: 0;
    padding-right: 32px; 
    font-size: 0.5rem;
  } 
  .icon-row {
    justify-content: space-between; 
  }
  .logo {
    max-width: 150px;
    opacity: 0.8;
    margin-bottom: 0.5rem;
  }
  .app-icon {
    max-height: 3rem; 
    padding-left: 1rem; 
    opacity: 0.3;
  } 
  .powered {
    flex-wrap: wrap;
    min-width: 5rem;
  }
  .powered-icon {
     width: 100%;
     max-height: 5rem; 
     max-width: 12rem; 
     margin-left:0rem;
   }
}  

</style> 