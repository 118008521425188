<template>
  <div>
    <div style="display: flex; justify-content: center; padding: 0 10px;">
      <div class="mode-area">
        <div class="advanced-buttons" v-show="isAdvancedMode">
          <div class="adv-button" :class="{ active: activePanel === 'customers' }" @click="setPanel('customers')">
            <img src="@/assets/icon/application/customers.svg" />
            <span>Müşterilerim</span>
          </div>
          <div class="adv-button" :class="{ active: activePanel === 'stats' }" @click="setPanel('stats')">
            <img src="@/assets/icon/application/stats.svg" />
            <span>İstatistiklerim</span>
          </div>
          <div class="adv-button" :class="{ active: activePanel === 'ai' }" @click="setPanel('ai')">
            <img src="@/assets/icon/application/ai.svg" />
            <span><b>AI</b> Asistan</span>
          </div>
        </div>
        <div class="mode-button-area">
          <div class="mode-button" @click="toggleMode">
            <template v-if="isAdvancedMode">
              <img src="@/assets/icon/application/mode.svg" /><span style="color:#b18f1d">BETA</span>
            </template>
            <template v-else>
              <img src="@/assets/icon/application/mode.svg" />
            </template>
          </div>
          <div class="settings-icon" @click="setPanel('settings')">
            <img src="@/assets/icon/application/settings.svg" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentPanel" style="display: flex; justify-content: center;">
      <component :is="currentPanel" class="settings-panel" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SettingsPanel from './advanced/SettingsPanel.vue';
import AiPanel from './advanced/AiPanel.vue';
import CustomersPanel from './advanced/CustomersPanel.vue';
import StatsPanel from './advanced/StatsPanel.vue';

export default {
  name: "AdvancedMenu",
  components: { SettingsPanel, AiPanel, CustomersPanel, StatsPanel },
  props: {
    isAdvancedMode: Boolean
  },
  data() {
    return {
      activePanel: null
    };
  },
  computed: {
    currentPanel() {
      switch (this.activePanel) {
        case 'customers': return 'CustomersPanel';
        case 'stats': return 'StatsPanel';
        case 'ai': return 'AiPanel';
        case 'settings': return 'SettingsPanel';
        default: return null;
      }
    }
  },
  methods: {
    async toggleMode() {
      try {
        const response = await axios.post('/promo/portal/advanced-mode/toggle');
        this.activePanel = null;
        this.$emit('toggleMode', response.data.is_advanced_mode_on);
      } catch (error) {
        console.error("Gelişmiş mod güncellenirken hata:", error);
      }
    },
    setPanel(panel) {
      this.activePanel = this.activePanel === panel ? null : panel;
    }
  }
};
</script>


<style scoped>
.mode-area {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  max-width: 1300px;
  width: 100%;
}

.advanced-buttons {
  display: flex;
  gap: 10px; 
  font-weight: 300;
}

.adv-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 1em 1rem;
  background: linear-gradient(45deg, #000000, #0a0a0a, #050505); 
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease; 
  animation: fadeInUp 1s ease;
}

.adv-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 53, 66, 0.822);
  background: linear-gradient(45deg, #020202, #022121, #050505);  
}

.adv-button img {
  max-width: 2.2rem;
  height: 0.6rem;
}

.mode-button-area {
  display: flex;
  align-items: center;
  margin-left: auto; 
  gap: 10px;
}

.mode-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 1em 1rem;
  min-height: 1.1rem;
  background: linear-gradient(45deg, #020202, #0a0a0a, #050505); 
  border-radius: 5px; 
  transition: background 0.3s ease;
  animation: fadeIn 1s ease;
}

.mode-button:hover { 
  box-shadow: 0 4px 10px rgba(0, 53, 66, 0.822);
  background: linear-gradient(45deg, #020202, #022121, #050505);  
}

.mode-button img {
  max-width: 2.2rem;
  height: 0.6rem;
}

.settings-icon img {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.settings-icon img:hover {
  transform: scale(1.2);
}

@media (max-width: 1000px) {
  .mode-area {
    flex-direction: column;
    align-items: stretch;
  }

  .mode-button-area {
    order: -1;
    align-self: flex-end;
    margin-bottom: 10px;
  }

  .advanced-buttons {
    justify-content: flex-start;
    flex-wrap: wrap; 
  }
  .adv-button {
    min-width: 8rem;
  }
}

@media (hover: none) and (pointer: coarse) {
  .mode-button:hover, .adv-button:hover {
  transform: none !important;
  box-shadow: none !important;
  scale: none !important;
  background: linear-gradient(45deg, #020202, #0a0a0a, #050505);  
}
}
.settings-panel {
  margin-top: 10px; 
  padding: 10px; 
  border-radius: 5px;
  max-width: 1280px;
  width: 100%;
  animation: fadeInDown 1s ease;
}
.adv-button.active {
background: linear-gradient(45deg, #020202, #011111, #020202); 
}

</style> 