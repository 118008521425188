<template>
  <div class="education-by-language">
    <!-- Loader bileşeni, veriler yüklenirken gösterilir -->
    <NoahLoader v-if="isLoading" :isLoading="isLoading" />

    <EducationUserHeader v-if="hasToken" /> 
    <div>
      <p class="title">Online Eğitimler</p>
      <p>Tüm üyelerimize açık olan online eğitimlerimizi aşağıda bulabilirsiniz.</p>
    </div> 
 
    <div class="language-icons">
      <div 
        v-for="lang in languages" 
        :key="lang.language_id" 
        class="language-icon" 
        :class="{ active: selectedLanguage && selectedLanguage.language_id === lang.language_id }"
        @click="selectLanguage(lang)"
      >
        <div v-html="lang.icon"></div>
      </div>
    </div> 
 
    <div class="education-boxes" v-if="educationLanguages.length">
      <div 
        v-for="eduLang in educationLanguages" 
        :key="eduLang.education.education_id" 
        class="education-box"
        @click="handleEducationClick(eduLang)"
      > 
        <p class="edu-title">
          {{ eduLang.education_name_translation || eduLang.education.name }}
        </p>
        <p class="edu-details">
          {{ eduLang.education.details }}
        </p> 
        <p v-if="eduLang.education.is_loggedin && !hasToken" class="login-warning">
          <img class="icon" src="./icons/login-key.svg" alt="Login Icon"  />
        </p>
      </div>
    </div>
    <div style="text-align: center;" v-else>
      <p>Bu dile ait eğitim bulunamadı.</p>
    </div>

    <div class="education-list-footer">
      <p class="test-et" @click="goToTest">KENDİNİ TEST ET</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EducationUserHeader from './EducationUserHeader.vue';
import NoahLoader from '../NoahLoader.vue';

export default {
  components: {
    EducationUserHeader,
    NoahLoader
  },
  data() {
    return {
      languages: [],
      selectedLanguage: null,
      educationLanguages: [],
      isLoading: true // Başlangıçta loader aktif
    };
  },
  computed: {  
    hasToken() {
      return !!localStorage.getItem('token');
    }
  },
  methods: {
    async fetchActiveLanguages() {
      try {
        this.isLoading = true;
        const response = await axios.get('/auth/languages/active');
        this.languages = response.data.languages;
        if (this.languages.length > 0) { 
          this.selectLanguage(this.languages[0]);
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Aktif diller alınırken hata:', error);
        this.isLoading = false;
      }
    },
    async fetchEducationByLanguage(language_id) {
      try {
        this.isLoading = true;
        const response = await axios.get('/auth/education/by-language', {
          params: { language_id }
        });
        this.educationLanguages = response.data.educationLanguages;
      } catch (error) {
        console.error('Eğitimler alınırken hata:', error);
      } finally {
        this.isLoading = false;
      }
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang;
      this.fetchEducationByLanguage(lang.language_id);
    }, 
    handleEducationClick(eduLang) {
      const token = localStorage.getItem('token'); 
      if (eduLang.education.is_loggedin && !token) {
        this.$router.push('/login');
      } else { 
        this.$router.push({
          name: 'EducationDetail',
          params: {
            educationId: eduLang.education.education_id,
            languageId: this.selectedLanguage.language_id
          }
        });
      }
    },
    goToTest() {
      this.$router.push({ path: 'kendinitestet' }); 
    }
  },
  mounted() {
    this.fetchActiveLanguages();
  }
};
</script>




<style scoped>
h3{ 
  font-size: 1rem;
  font-weight: 500;
}
.education-by-language { 
  padding: 120px 10px 10px 10px;
  text-align: center;
}
.language-icons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; 
  margin-top: 0;
}
.language-icon {
  cursor: pointer;
  text-align: center; 
  border: 1px solid transparent;
  border-radius: 4px;
  width: 2rem;
  opacity: 0.4;
  transition: transform 0.3s ease, color 0.2s ease, opacity 0.4s ease;

}
.language-icon:hover {
  opacity: 0.8;
  transform: scale(1.2);
}
.language-icon.active {
  opacity: 1;
  transform: scale(1.2);
}
.education-boxes {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  gap: 10px;  
}
.education-box {
  background-color: #050505;
  flex: 1 1 calc(25% - 20px);  
  max-width: 300px; 
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column; 
  cursor: pointer;  
  padding: 5px; 
  transition: transform 0.3s ease, box-shadow 0.5s ease, color 0.2s ease; 
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(2, 48, 50, 0);  
  border: 1px solid transparent;
}
.education-box:hover {  
  color: white;
  transform: scale(1.04); 
  opacity: 1;
  box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5);
  background-color: #000; 
}
.edu-title{
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.05em;
}
.edu-detail{
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: 0.1rem;
}
.login-warning{  
  margin-top: -1rem; 
}
.icon { 
  width: 2em;
  opacity: 0.5;
}
.education-list-footer{ 
  margin: 5em 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.test-et{
  background-color: #070707;
  padding: 2em;
  font-size: 1.2em;
  border: 1px solid #065959;
  max-width: 15em;
  border-radius: 1em;
  color: #065959;
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
.test-et:hover { 
  cursor: pointer;
  transform: scale(1.05);
  border: 1px solid #187774;
  color: #187774;
}

@media (max-width: 768px) {
  .language-icon {
    cursor: pointer;
    text-align: center;
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 1.5rem;
    opacity: 0.4;
  }
}

@media (max-width: 1024px) {
  .education-box {
    flex: 1 1 calc(33.33% - 20px);  
  }
  .education-by-language { 
  padding: 70px 10px 10px 10px; 
}
}
 
@media (max-width: 768px) {
  .education-by-language { 
  padding: 70px 10px 10px 10px; 
}
.edu-title{
  font-size: 1.2em; 
}
  .education-box {
    flex: 1 1 calc(50% - 22px); 
    box-shadow: 0 8px 18px rgba(2, 48, 50, 0.2); 
    font-size: 1em;
  }
  .category-icon {
    max-height: 2em; 
  }
}  
@media (max-width: 359px) {
  .education-box {
    flex: 1 1 calc(100% - 20px); 
  }
}
</style>
