<template>
  <div class="education-detail"> 
    <NoahLoader v-if="isLoading" :isLoading="isLoading" />

    <div class="container">
    <router-link to="/ucretsizegitim" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/profile/education.svg" alt="Ana Sayfa Icon" />
      </div>
    </router-link>   
    </div>

    <div class="serial-no-list">
      <div
        v-for="(topic, index) in educationData"
        :key="topic.serial_no"
        class="serial-no-box"
        :class="{
          selected: index === currentIndex,
          visited: visitedDataIds.includes(topic.data_id)
        }"
        @click="selectTopic(index)"
      >
        {{ topic.serial_no }}
      </div>
    </div>

    <div v-if="currentTopic" class="topic-detail"> 
      <div class="image-container" v-if="currentTopicImage"> 
        <img :src="currentTopicImage" alt="Eğitim Görseli" @load="handleImageLoad" v-no-right-click />
      </div> 
    </div>

    <div class="navigation-icons">
      <button
        v-if="currentIndex > 0"
        @click="previousTopic"
        class="nav-btn"
      >
        Önceki <img class="btn-icon" src="./icons/before.svg" alt="Önceki" />
      </button>
      <button
        v-if="currentIndex < educationData.length - 1"
        @click="nextTopic"
        class="nav-btn"
      >
        <img class="btn-icon" src="./icons/after.svg" alt="Sonraki" />Sonraki
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NoahLoader from '../NoahLoader.vue';

export default {
  name: 'EducationDetail',
  components: { NoahLoader },
  props: {
    educationId: {
      type: [String, Number],
      required: true,
    },
    languageId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      educationData: [],
      currentIndex: 0,
      visitedDataIds: [],
      isLoading: true, 
    };
  },
  computed: {
    currentTopic() {
      return this.educationData[this.currentIndex] || null;
    },
    currentTopicImage() {
      if (!this.currentTopic) return null;
      return this.isMobile ? this.currentTopic.mobile_image_link : this.currentTopic.image_link;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    async fetchEducationData() {
      try {
        const token = localStorage.getItem('token'); 
        const apiRoute = token ? '/promo/educationdata/withlang' : '/auth/educationdata/withlang';
        const response = await axios.get(apiRoute, {
          params: {
            education_id: this.educationId,
            language_id: this.languageId,
          },
        });
        this.educationData = response.data.educationData; 
        this.$nextTick(() => {
          if (!this.currentTopicImage) {
            this.isLoading = false;
          }
        });
        if (token) {
          this.fetchVisitedRecords();
        }
      } catch (error) {
        console.error('Eğitim detayları alınırken hata oluştu:', error);
        this.isLoading = false;
      }
    },
    markCurrentAsVisited() { 
    const topic = this.currentTopic;
    if (topic && topic.data_id && !this.visitedDataIds.includes(topic.data_id)) {
      this.visitedDataIds.push(topic.data_id);
    } 
    const token = localStorage.getItem('token');
    if (token && topic) {
      axios.post('/promo/user-education-record', {
        education_id: this.educationId,
        language_id: this.languageId,
        serial_no: topic.serial_no,
      }).catch((err) => {
        console.error('Kullanıcı eğitim kaydı yapılırken hata:', err);
      });
    }
  },
    async fetchVisitedRecords() {
      try {
        const response = await axios.get('/promo/myeducationrecords');
        if (response.data.success && Array.isArray(response.data.data)) {
          const records = response.data.data; 
          const match = records.find(
            (item) =>
              item.education_id == this.educationId &&
              item.language_id == this.languageId
          );
          if (match && Array.isArray(match.dataRecords)) { 
            this.visitedDataIds = match.dataRecords
              .filter((rec) => rec.data_id != null)
              .map((rec) => rec.data_id);
          }
        }
      } catch (error) {
        console.error('Kullanıcının eğitim kayıtları çekilirken hata oluştu:', error);
      }
    },
    selectTopic(index) {

      if (index === this.currentIndex) {
        return;
      }
      this.currentIndex = index;
      this.scrollToTop();
 
      if (this.currentTopic && (this.currentTopic.mobile_image_link || this.currentTopic.image_link)) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }

      const token = localStorage.getItem('token');
      const topic = this.educationData[index];
      if (!topic) return;
 
      if (topic.data_id && !this.visitedDataIds.includes(topic.data_id)) {
        this.visitedDataIds.push(topic.data_id);
      }
 
      if (token) {
        axios
          .post('/promo/user-education-record', {
            education_id: this.educationId,
            language_id: this.languageId,
            serial_no: topic.serial_no,  
          })
          .catch((err) => {
            console.error('Kullanıcı eğitim kaydı yapılırken hata:', err); 
          });
      }
    },
    previousTopic() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.educationData.length - 1; 
      }
      this.scrollToTop();
    },
    nextTopic() {
      if (this.currentIndex < this.educationData.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0; 
      }
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleResize() {
      this.$forceUpdate();
    }, 
    handleImageLoad() {
      this.isLoading = false;
    },
    handleKeyDown(event) {
      switch (event.key) {
        case 'ArrowLeft':
          this.previousTopic();
          this.markCurrentAsVisited();
          this.isLoading = true;
          break;
        case 'ArrowRight':
          this.nextTopic();
          this.markCurrentAsVisited();
          this.isLoading = true;
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.fetchEducationData();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyDown);
  },
};
</script>


<style scoped>
.education-detail {
  color: white;
  padding: 150px 20px;
  max-width: 1360px;
  position: relative;
}
.serial-no-list {
  display: flex;
  gap: 0.5em;
  margin-bottom: 20px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #065959 transparent;
  width: 100%;
}
.serial-no-box {
  min-width: 4em;
  height: 4em;
  border: 1px solid #2d2d2d;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} 
.visited {
  border-color: #106e35;
  color: #106e35;
}
.selected {
  border-color: #c2c2c2;
  color: #c2c2c2;
} 
.image-container img {
  width: 100%;
  height: auto;
  margin: 10px auto;
  display: block;
}
.text-data {
  margin-top: 20px;
  text-align: justify;
}
.navigation-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}
.btn-icon {
  max-height: 2rem;
}

 

.container {
  display: flex; 
  justify-content: flex-start;
  align-items: center;  
  flex-wrap: wrap;
  margin: 1rem 0;
}

.content {
  margin-bottom: 4rem;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em; 
  padding: 0 1em;
  min-height: 4em;
  min-width: 5em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border: 1px solid #2d2d2d;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}
  
.icon {
  min-width: 2em;
  max-height: 1.5em; 
}
 
@media (max-width: 768px) { 
  .education-detail { 
    padding: 5rem 20px; 
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
  .nav-btn { 
  gap: 5px;
  margin-top: 20px;
  padding: 1.5em 3em;
}
}
</style> 