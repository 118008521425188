<template>
  <div class="shop-websites-by-language">
    <NoahLoader v-if="isLoading" :isLoading="isLoading" />

    <div class="language-boxes">
      <div
        class="language-box"
        v-for="site in websites"
        :key="site.id"
        @click="openInNewTab(site.link)"
      >
        <div class="icon" v-html="site.language.icon"></div>
        <p v-if="site.name">{{ site.name }}</p>
        <p v-if="site.description">{{ site.description }}</p>
        <p v-if="site.manager">{{ site.manager }}</p>
        <p v-if="site.address">{{ site.address }}</p>
        <p v-if="site.phone">{{ site.phone }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NoahLoader from './NoahLoader.vue';

export default {
  components: { NoahLoader },
  data() {
    return {
      websites: [],
      isLoading: true
    };
  },
  methods: {
    async fetchWebsites() {
      try {
        const response = await axios.get('/auth/shopwebsites');
        this.websites = response.data.websites;
      } catch (error) {
        console.error('Shop websites alınırken hata:', error);
      } finally {
        this.isLoading = false;
      }
    },
    openInNewTab(link) {
      window.open(link, '_blank');
    }
  },
  mounted() {
    this.fetchWebsites();
  }
};
</script>

<style scoped> 
.shop-websites-by-language {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;  
  min-height: 90vh;  
  text-align: center;
  padding: 120px 10px 10px 10px;
}
 
.language-boxes {
  width: 80%;   
  max-width: 1200px;
  min-height: 50vh;  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; 
  align-items: flex-start;  
}
 
.language-box {
  background-color: #050505;
  min-width: 200px; 
  flex: 1 1 ; 
  min-height: 150px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  border-radius: 10px;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(2, 48, 50, 0);
  padding: 1em;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.5s ease, color 0.2s ease;
}
.language-box:hover {
  transform: scale(1.04);
  box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5);
  background-color: #000;
  color: #fff;
}
 
.icon {
  width: 5em;
  height: 5em;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}
 
@media (max-width: 768px) {
  .language-boxes {
    padding: 10px;
  }
  .language-box {
    flex: 1 1 calc(50% - 20px); 
    min-width: 100px ;
  flex: 1 1 ; 
  min-height: 150px; 
  }
} 
@media (max-width: 360px) {
  .language-box {
    flex: 1 1 100%; 
  }
}
</style>

