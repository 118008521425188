<template>
  <div class="top-container">
    <AiHeader /> 
    <ChatWindow /> 
  </div>
</template>

<script> 
import AiHeader from './AiHeader.vue';
import ChatWindow from './ChatWindow.vue'; 

export default {
  data() {
    return { 
    };
  },
  components: {     
    AiHeader,
    ChatWindow
  },
};
</script>
  
<style scoped> 
.top-container{
  padding-top: 120px;
}
@media (max-width: 768px) {
  .top-container{
  padding-top: 90px;
}
}
</style>
  