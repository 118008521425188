<template>
  <div class="noah-masters">  
    <div class="filter-container">
      <button
        v-for="level in uniqueLevels" 
        :key="level"
        class="filter-button"
        :style="{
          opacity: selectedLevel === level || selectedLevel === null ? 1 : 0.5,
          transform: selectedLevel === level ? 'scale(1.1)' : 'scale(1)'
        }"
        @click="filterByLevel(level)"
      >
        <img :src="getLevelIcon(level)" alt="Seviye İkonu" class="filter-icon" />
      </button>
    </div>

    <div class="masters-container">
      <div 
         v-for="master in getSortedMasters()" 
         :key="master.master_id" 
         class="master-card"
       >
        <img v-if="master.image_link" :src="master.image_link" alt="Master Fotoğrafı" class="master-image" />

        <div class="master-level">
          <img v-if="master.level_icon" :src="master.level_icon" alt="Seviye İkonu" class="level-icon" />
          <p class="level-title">{{ master.level }}</p>
        </div>

        <div class="master-details">
          <p class="master-name">{{ master.name }}</p>
          <p class="master-info" v-if="master.phone"><img class="item-icon" src="../assets/icon/world/phone.svg" />  {{ master.phone }}</p>
          <p class="master-info" v-if="master.instagram"><img class="item-icon" src="../assets/icon/world/instagram.svg" />  {{ master.instagram }}</p>
          <p class="master-info" v-if="master.country"><img class="item-icon" src="../assets/icon/world/location.svg" />  {{ master.country }} / {{ master.city }}</p> 
          <p class="master-bio" v-if="master.bio">{{ master.bio }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      masters: [],
      selectedLevel: null,
    };
  },
  computed: {
    uniqueLevels() {
      return [...new Set(this.masters.map((master) => master.level))];
    },
    filteredMasters() {
      if (!this.selectedLevel) {
        return this.masters;
      }
      return this.masters.filter((master) => master.level === this.selectedLevel);
    },
  },
  methods: {
    async fetchMasters() {
      try {
        const response = await axios.get("/auth/noah-masters");
        this.masters = response.data.masters || [];
      } catch (error) {
        console.error("Noah masterlar alınırken hata oluştu:", error);
      }
    },
    filterByLevel(level) {
      this.selectedLevel = this.selectedLevel === level ? null : level;
    },
    getLevelIcon(level) {
      const master = this.masters.find((master) => master.level === level);
      return master ? master.level_icon : "";
    },
    getSortedMasters() {
      return [...this.filteredMasters].sort((a, b) => {
        const aOrder = a.sira_no ? parseInt(a.sira_no) : Infinity;
        const bOrder = b.sira_no ? parseInt(b.sira_no) : Infinity;
        return aOrder - bOrder;
      });
    },
  },
  mounted() {
    this.fetchMasters();
  },
};
</script>


<style scoped>
.noah-masters {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 320px;
} 
.masters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.item-icon{
  max-width: 1em;
  max-height: 1em;
}

.master-card {  
  min-width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
  background-color: #050505; 
}

.master-card:hover {
  transform: translateY(-5px);
  background-color: black;
}

.master-image {
  width: 100%;
  max-height: 380px;
  object-fit: cover;
}

.master-level { 
  padding: 10px;
}

.level-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: -120px;
  background-color: black;
  border-radius: 20%;
}

.level-title {
  font-size: 1em;
  font-weight: 200;
  margin: 0 0 5px 0;
}

.master-details {  
  margin-top: -20px;
  padding: 5px;
}

.master-name {
  font-size: 1.2em; 
  margin: 0 0 5px 0;
  font-weight: 500;
}

.master-info {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 1em; 
  margin: 5px 0;
}

.master-bio {
  font-size: 0.9em; 
  margin-top: 10px;
  text-align: justify;
}

.filter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 5px;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.filter-icon {
  width: 5rem;
  height: 5rem;
}

@media (max-width: 560px) {
  .masters-container {
    flex-wrap: wrap;
    gap: 5px; 
  }

  .master-card {
    width: calc(48% - 5px); 
    min-width: unset; 
  }

  .master-image {
    border-radius: 8px;
  }
 
  .masters-container .master-card:only-child {
    width: 80%;
  }
  .filter-icon {
  max-width: 4rem;
  max-height: 4rem;
}
}

</style>
