<template>
  <div class="my-educations dark-theme"> 
    <div v-if="userEducations.length === 0" class="no-records">
      <p>Henüz online bir eğitime çalışmadınız.</p>
    </div>
 
    <div
      v-for="(edu, index) in userEducations"
      :key="`edu-${edu.education_id}-${edu.language_id}-${index}`"
      class="education-card"
    >
      <div class="education-header">
        <img
          v-if="edu.educationName"
          class="title-icon"
          src="./icons/head.svg"
          alt="icon"
        />
        <div class="education-info">
          <h3 class="education-name">{{ edu.educationName }}</h3>
          <p class="education-lang">Dil: {{ edu.languageName }}</p>
        </div>
      </div>
 
      <div class="record-chips">
        <div
          v-for="(record, idx) in edu.dataRecords"
          :key="`${record.data_id}-${idx}`"
          class="record-chip"
        >
          <div class="chip-row">
            <span class="chip-label">Ünite/Konu: </span>
            <span class="chip-value">{{ record.serial_no }}</span>
          </div>
          <div class="chip-row">
            <span class="chip-label">İlk Çalışma: </span>
            <span class="chip-value">{{ formatDateTime(record.created_at) }}</span>
          </div>
          <div class="chip-row">
            <span class="chip-label">Son Çalışma: </span>
            <span class="chip-value">{{ formatDateTime(record.updated_at) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MyEducations',
  data() {
    return {
      userEducations: [],
    };
  },
  methods: {
    async fetchUserEducations() {
      try {
        const response = await axios.get('/promo/myeducationrecords');
        if (response.data.success) {
          this.userEducations = response.data.data || [];
        } else {
          this.userEducations = [];
        }
      } catch (error) {
        console.error('Eğitim kayıtları alınırken hata oluştu:', error);
        this.userEducations = [];
      }
    }, 
  },
  mounted() {
    this.fetchUserEducations();
  },
};
</script>

<style scoped> 
.dark-theme {  
  box-sizing: border-box;
  display: flex;  
  justify-content: center;
  align-items: flex-start;  
  flex-wrap: wrap;
}
 
.no-records { 
  padding: 1.5rem;
  border-radius: 8px; 
  font-style: italic;
  margin: 2rem auto;
  max-width: 1600px;
}
 
.education-card {
  background: #050505;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  padding: 1em 0.5em; 
  transition: transform 0.3s ease;
  max-width: 1600px;
  width: 100%;
}

.education-card:hover {
  transform: translateY(-3px);
}
 
.education-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.2em;
}
 
.title-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.8em;
}
 
.education-info {
  display: flex;
  flex-direction: column;
}

.education-name {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.education-lang {
  margin-top: 0.1em;
  font-size: 0.8em; 
} 
.record-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6em;
  margin-top: 0.5em;
}

.record-chip {
  background-color: #0a0a0a;
  border-radius: 8px;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  transition: background-color 0.3s ease;
  min-width: 150px;
}

.record-chip:hover {
  background-color: #000;
}
 
.chip-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
 
.chip-label {
  font-size: 0.8em; 
  font-weight: 500;
  margin-right: 0.3em;
}
 
.chip-value {
  font-size: 0.8em; 
}
</style>
