<template>
  <div class="settings-container">
    <div class="change-password-container">
      <h3>Şifre Değiştir</h3>

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="currentPassword">Mevcut Şifre</label>
          <input
            v-model="currentPassword"
            type="password"
            id="currentPassword"
            placeholder="Mevcut şifrenizi girin"
            required
          />
        </div>

        <div class="form-group">
          <label for="newPassword">Yeni Şifre</label>
          <input
            v-model="newPassword"
            type="password"
            id="newPassword"
            placeholder="Yeni şifrenizi girin"
            required
          />
        </div>

        <div class="form-group">
          <label for="confirmPassword">Yeni Şifreyi Tekrar Girin</label>
          <input
            v-model="confirmPassword"
            type="password"
            id="confirmPassword"
            placeholder="Yeni şifrenizi tekrar girin"
            required
          />
        </div>

        <button class="confirm" type="submit">Şifreyi Güncelle</button>
      </form>

      <div v-if="passwordMessage" :class="passwordMessageClass">
        {{ passwordMessage }}
      </div>
    </div>

    <div class="twofa-settings-container">
      <h3>2FA Ayarları</h3>

      <!-- 2FA Açıklama Kutusu -->
      <div class="info-box">
        <p><strong>İki Adımlı Doğrulama (2FA) Nedir?</strong></p>
        <p>
          İki Adımlı Doğrulama (2FA), hesabınıza ekstra bir güvenlik katmanı ekler. 
          Bu özellik etkinleştirildiğinde, hesabınıza giriş yapmak için sadece şifrenizi 
          değil, aynı zamanda e-posta adresinize gönderilen 6 haneli bir doğrulama kodunu 
          da girmeniz gerekecektir. Bu kod, her giriş denemesinde yenilenir ve sadece 
          2 dakika geçerlidir.
        </p>
        <p>
          2FA, hesabınızı yetkisiz erişimlere karşı korumaya yardımcı olur ve güvenliğinizi 
          artırır.
        </p>
      </div>

      <div v-if="loading" class="loading-message">Yükleniyor...</div>

      <div v-else>
        <p class="status-fa green" v-if="twofaEnabled">2FA şu anda açık.</p>
        <p class="status-fa" v-else>2FA şu anda kapalı. Etkinleştirmek için açabilirsiniz.</p>

        <div v-if="twofaMessage" :class="twofaMessageClass">{{ twofaMessage }}</div>

        <button @click="toggle2FA" :disabled="loading">
          {{ twofaEnabled ? '2FA’yı Kapat' : '2FA’yı Aç' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // Şifre Değiştirme İçin
      currentPassword: "",   
      newPassword: "",       
      confirmPassword: "",    
      passwordMessage: "",        
      passwordMessageClass: "",   

      // 2FA Ayarları İçin
      twofaEnabled: false,  
      loading: false,      
      twofaMessage: '',      
      twofaMessageClass: '',  
    };
  },
  mounted() {
    this.get2FAStatus();
  },
  methods: {
    // Şifre Değiştirme İşlemi
    async handleSubmit() {
      if (this.newPassword !== this.confirmPassword) {
        this.passwordMessage = "Yeni şifreler uyuşmuyor.";
        this.passwordMessageClass = "error";
        return;
      }

      try {
        const response = await axios.post("/promo/changepassword", {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });

        this.passwordMessage = response.data.message;
        this.passwordMessageClass = "success";

        this.currentPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
      } catch (error) { 
        if (error.response) {
          this.passwordMessage = error.response.data.message;
        } else {
          this.passwordMessage = "Şifre güncellenirken bir hata oluştu. Lütfen tekrar deneyin.";
        }
        this.passwordMessageClass = "error";
      }
    },

    // 2FA Durumunu Getir
    async get2FAStatus() {
      try {
        this.loading = true;
        const response = await axios.get('/promo/get2fastatus');
        this.twofaEnabled = response.data.twofaEnabled;
      } catch (error) {
        this.twofaMessage = "2FA durumu alınırken bir hata oluştu.";
        this.twofaMessageClass = "error";
      } finally {
        this.loading = false;
      }
    },

    // 2FA'yı Aç/Kapa
    async toggle2FA() {
      this.loading = true;
      try {
        const enable = !this.twofaEnabled;
        const response = await axios.post('/promo/update2fastatus', { enable });

        this.twofaMessage = response.data.message;
        this.twofaMessageClass = 'success';
        this.twofaEnabled = enable;
      } catch (error) {
        this.twofaMessage = error.response ? error.response.data.message : "Bir hata oluştu.";
        this.twofaMessageClass = "error";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.settings-container {
  display: flex;
  gap: 20px;
  max-width: 900px;
  margin: auto;
  padding: 20px;
  flex-wrap: wrap;
}

.change-password-container,
.twofa-settings-container {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: #050505;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label { 
  margin-bottom: 5px;
  display: block;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px; 
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 10px; 
  border: none;
  cursor: pointer;
  border-radius: 5px; 
  margin-top: 20px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
 
.info-box p {
  margin: 0 0 10px; 
}

.info-box p:last-child {
  margin-bottom: 0;
}

.loading-message {
  text-align: center;
  color: #888;
}

.success {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
.status-fa {
  font-size: 1.2em;
}
.green {
  color: green;
}
@media (max-width: 600px) {
  .settings-container { 
   flex-direction: column;
  }
}
</style>