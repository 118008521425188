<template>
  <div class="profile-container">
    <div class="profile-card">
      <div class="profile-pic">
        <img 
          :src="profilePicture" 
          alt="Profil Resmi" 
          class="profile-image" 
          @click="openModal"
        />
        <img
          src="@/assets/icon/edit.svg"
          alt="Edit"
          class="edit-icon"
          @click="toggleTooltip"
        />
        <div v-if="showTooltip" class="tooltip">
          <button @click="triggerFileInput">Güncelle</button>
          <button @click="removeProfilePicture">Kaldır</button>
        </div>
        <input
          type="file"
          ref="fileInput"
          @change="handleFileChange"
          accept="image/*"
          style="display: none"
        />
      </div>

      <div class="user-info">
        <h2 class="username">
          <span @click="goToProfile">{{ username }}</span>
          <img
            v-if="verifiedIcon"
            :src="verifiedIcon"
            @click="goToLevel"
            alt="Hesap Doğrulama Seviyesi"
            class="verified-icon"
          />
        </h2>
        <div class="actions">
          <button @click="goToNotifications">
            <img
              class="btn-icon"
              src="@/assets/icon/profile/notify.svg"
              alt="Bildirimler"
            />
            Bildirimler
          </button>
          <button @click="goToActivities">
            <img
              class="btn-icon"
              src="@/assets/icon/profile/activity.svg"
              alt="Aktiviteler"
            />
            Aktiviteler
          </button>
        </div>
      </div>
    </div>
 
    <div v-if="showModal" class="modal" @click="closeModal">
      <div class="modal-content">
        <img :src="profilePicture" alt="Profil Resmi" class="modal-image" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      verifiedLevel: "",
      profilePicture: require("@/assets/icon/default-profile.png"),
      showTooltip: false,
      showModal: false,
    };
  },
  computed: {
    verifiedIcon() {
      return this.getVerifiedIcon(this.verifiedLevel);
    },
  },
  methods: {
    async fetchProfileCard() {
      try {
        const response = await axios.get("/promo/my-profile-card");
        if (response.data.success) {
          this.username = response.data.data.user_name || "";
          this.verifiedLevel = response.data.data.verified_level || "";
          if (response.data.data.profile_picture) {
            this.profilePicture = response.data.data.profile_picture;
          }
        }
      } catch (error) {
        console.error("Profil bilgileri alınırken hata oluştu:", error);
      }
    },
    goToNotifications() {
      this.$router.push("/hesabim/bildirimler");
    },
    goToActivities() {
      this.$router.push("/hesabim/aktiviteler");
    },
    goToProfile() {
      this.$router.push("/hesabim");
    },
    goToLevel() {
      this.$router.push("/hesap-seviyeleri");
    },
    toggleTooltip(event) {
      event.stopPropagation();
      this.showTooltip = !this.showTooltip;
    },
    triggerFileInput() {
      this.showTooltip = false;
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("uploadType", "profilpictures");
      formData.append("image", file);
      try {
        const response = await axios.post("/promo/my-new-profile-pic", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          this.profilePicture = response.data.data.image_link;
        }
      } catch (error) {
        console.error("Profil resmi güncellenirken hata oluştu:", error);
      }
    },
    async removeProfilePicture() {
      this.showTooltip = false;
      try {
        const response = await axios.delete("/promo/remove-my-profile-pic");
        if (response.data.success) {
          this.profilePicture = require("@/assets/icon/default-profile.png");
        }
      } catch (error) {
        console.error("Profil resmi silinirken hata oluştu:", error);
      }
    },
    getVerifiedIcon(level) {
      if (!level || level === 0) {
        return "";
      }
      let iconPath = "";
      try {
        iconPath = require(`@/assets/icon/profile/level/${level}.svg`);
      } catch (e) {
        try {
          iconPath = require(`@/assets/icon/profile/level/${level}.png`);
        } catch (err) {
          console.warn(`Verified icon for level "${level}" not found.`);
        }
      }
      return iconPath;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleClickOutside(e) {
      if (this.showTooltip) {
        const tooltipEl = this.$el.querySelector('.tooltip');
        const editIcon = this.$el.querySelector('.edit-icon');
        if (
          tooltipEl &&
          !tooltipEl.contains(e.target) &&
          editIcon &&
          !editIcon.contains(e.target)
        ) {
          this.showTooltip = false;
        }
      }
    },
  },
  mounted() {
    this.fetchProfileCard();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.profile-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
}

/* Profil Resmi */
.profile-pic {
  width: 33.33%;
  min-width: 100px;
  max-width: 120px;
  min-height: 100px;
  max-height: 120px;
  aspect-ratio: 1/1;
  background-color: #050505;
  border-radius: 50%;
  position: relative;
  border: 2px solid #023032;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1rem;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.8;
}

/* Düzenleme İkonu */
.edit-icon {
  position: absolute;
  left: 5px;
  top: 80%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  opacity: 0.7;
}

.edit-icon:hover {
  opacity: 1;
}

/* Tooltip Stili */
.tooltip {
  position: absolute;
  top: calc(100% + 5px);
  left: 10px;
  background-color: #000;
  padding: 5px 10px;
  border-radius: 2rem;
  display: flex;
  gap: 10px;
  z-index: 100;
  animation: shadowColorChangeMini 3s infinite ease-in-out;
}

.tooltip button {
  background: none;  
  padding: 5px 10px;
  border-radius: 2rem;
  border: 1px solid #023032;
  cursor: pointer;
}

.tooltip button:hover {
  background-color: #023032;
}

/* Kullanıcı Bilgileri */
.user-info {
  padding: 1rem 0 1rem 2rem;
  background-color: #050505;

  background: linear-gradient(45deg, #020202, #011111, #020202); 

  border-radius: 0 1rem 1rem 0;
  margin-left: -1rem;
  max-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.username {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.username span:hover {
  color: white;
}

.verified-icon {
  max-width: 18px;
  margin-left: 10px;
}

.verified-icon:hover {
  transform: scale(1.2);
}

.actions {
  display: flex;
}

.btn-icon {
  max-width: 1em;
  margin-right: 5px;
  opacity: 0.5;
}

.actions button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  transition: 0.3s;
  padding: 0;
  margin: 0 1em;
}

.actions button:hover {
  text-decoration: none;
}

.actions button:hover .btn-icon {
  opacity: 1;
}
 
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.modal-image {
  width: 100%;
  height: auto;
  cursor: pointer;
} 
@media (max-width: 600px) {
  .profile-card {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .profile-pic {
    min-width: 100px;
    max-width: 120px;
    min-height: 100px;
    max-height: 120px;
  }
  .user-info {
    min-height: auto;
    max-height: 50px;
    width: 60%;
  }
  .actions button {
    margin: 0;
  }
  .edit-icon {
    width: 2rem; left: 0;
  }
}

@media (max-width: 360px) {
  .profile-card {
    flex-direction: column;
    align-items: center;
    justify-content: center; 
  }
  .profile-pic {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
  }
  .user-info {
    background-color: #050505;
    border-radius: 1rem;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
</style>
