<template>
    <div class="container">
      <router-link to="/soru-cevap" class="box">
        <div class="icon">
          <img class="icon" src="@/assets/icon/sorucevap.svg" alt="Soru&Cevap Icon"  />
        </div>
        <span>Soru&Cevap</span>
      </router-link>
  
      <router-link to="/noah-chat" class="box">
        <div class="icon">
          <img class="icon" src="@/assets/icon/noahchat.svg" alt="NOAHCHAT Icon"  />
        </div>
        <span>NOAH<b style="font-weight: 500;">CHAT</b></span>
      </router-link>

      <router-link to="/noahai" class="box">
        <div class="icon">
          <img class="icon" src="@/assets/icon/noahai.svg" alt="NOAHCHAT Icon"  />
        </div>
        <span>NOAH<b style="font-weight: 500;">AI</b></span>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "NavigationBoxes",
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center; 
    padding: 20px;
  }
  
  .box {
    display: flex; 
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    min-width: 15em;
    min-height: 5em; 
    border-radius: 8px;
    text-decoration: none; 
    background-color: #050505; 

    background: linear-gradient(45deg, #050505);


    transition: transform 0.2s;
    color: #b5b5b5;
    font-size: 1.2em;
    font-weight: 300;
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease; 
  }
  
  .box:hover {
    transform: scale(1.05); 
    color: white;
    box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2); 

    background: linear-gradient(45deg, #010101, #0a0a0a, #020202);

  }
  .icon {
    min-width: 3em;
    max-height: 2.5em;
    opacity: 0.8;
  }
  .box:hover .icon {
    opacity: 1; 
  }
  @media (max-width: 768px) {
    .container { padding-top: 5rem; flex-wrap: wrap; margin-bottom: -3rem;
  } 
  .box { 
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
  </style>
