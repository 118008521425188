<template>
  <div class="app-main"> 
    <ProfileCard v-if="loggedIn" />

    <AuthPortalHead v-else /> 

    <ApplicationList
      :applications="applications"
      :loggedIn="loggedIn"
      @navigateToApplication="navigateToApplication"
      @goLogin="goLogin"
    />
    <FooterMenu />

    <SecurityMain />

  </div>
</template>

<script>
import axios from "axios";
import FooterMenu from './FooterMenu.vue';
import ProfileCard from "../profile/ProfileCard.vue";
import ApplicationList from "./ApplicationList.vue";
import AuthPortalHead from "./AuthPortalHead.vue"; 
import SecurityMain from "../security/SecurityMain.vue";

export default {
  data() {
    return {
      applications: [],
      loggedIn: false,
    };
  },
  components: {
    FooterMenu,
    ProfileCard,
    AuthPortalHead,
    ApplicationList,
    SecurityMain
  },
  methods: {
    async fetchApplications() {
      const response = await axios.get("/auth/applications");
      this.applications = response.data.applications;
    },
    navigateToApplication(version) {
      this.$router.push({ path: `/portal/${version}` });
    },
    goLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  mounted() {
    this.loggedIn = !!localStorage.getItem("token");
    this.fetchApplications();
  },
};
</script>

<style scoped>
.app-main {
  padding: 120px 10px 10px 10px;
}
</style>
