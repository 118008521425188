<template>
  <div class="container"> 
    <router-link to="/" class="box">
      <div class="icon">
        <img class="icon" src="./icons/home.svg" alt="Ana Sayfa Icon" />
      </div>
    </router-link>
 
    <div class="box" @click="setActiveComponent('UnsolvedQuestions')" :class="{ active: currentComponent === 'UnsolvedQuestions' }">
      <div class="icon">
        <img class="icon" src="./icons/guncel.svg" alt="Güncel Sorular" />
      </div>
      <span>Güncel Sorular</span>
    </div>
 
    <div class="box" @click="setActiveComponent('CreateQuestion')" :class="{ active: currentComponent === 'CreateQuestion' }">
      <div class="icon">
        <img class="icon" src="./icons/yenisoru.svg" alt="Yeni Soru Sor" />
      </div>
      <span>Yeni Soru Sor</span>
    </div>
 
    <div class="box" @click="setActiveComponent('QuestionArchive')" :class="{ active: currentComponent === 'QuestionArchive' }">
      <div class="icon">
        <img class="icon" src="./icons/arsiv.svg" alt="Arşiv" />
      </div>
      <span>Arşiv</span>
    </div>
 
    <div class="box" @click="setActiveComponent('MyQuestions')" :class="{ active: currentComponent === 'MyQuestions' }">
      <div class="icon">
        <img class="icon" src="./icons/sorularim.svg" alt="Sorularım" />
      </div>
      <span>Sorularım</span>
    </div>
  </div>
 
  <div class="content">
    <component :is="currentComponent" :question-id="selectedQuestionId" />
  </div>
  
</template>

<script>
import UnsolvedQuestions from "@/components/forum/UnsolvedQuestions.vue";
import CreateQuestion from "@/components/forum/CreateQuestion.vue";
import QuestionArchive from "@/components/forum/QuestionArchive.vue";
import MyQuestions from "@/components/forum/MyQuestionsMain.vue";
import QuestionDetails from "@/components/forum/QuestionDetails.vue";
import { eventBus } from "@/utils/eventBus";

export default {
  name: "NavigationBoxes",
  components: {
    UnsolvedQuestions,
    CreateQuestion,
    QuestionArchive,
    MyQuestions,
    QuestionDetails,
  },
  data() {
    return {
      currentComponent: "UnsolvedQuestions", 
      selectedQuestionId: null,
    };
  },
  methods: {
    setActiveComponent(component) {
      this.currentComponent = component;
    }
  },
  mounted() { 
    eventBus.on('openQuestion', (questionId) => {
      window.scrollTo(0, 0);
      this.selectedQuestionId = questionId;
      this.currentComponent = 'QuestionDetails';
    });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.content{margin-bottom: 4rem;}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 12em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparent;  
}

.box:first-child {
  min-width: 5em;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}
 
.box.active {
  border-bottom: 1px solid #023032;
  color: white;
}

.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.box.active .icon {
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    padding-top: 5rem;
    flex-wrap: wrap; 
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
</style>
