<template>
  <div class="how-to-use-gallery"> 

    <div class="karga-box">
      <img class="top-karga" src="@/assets/icon/klavuz.svg" alt="Kılavuz İkon"  />
      <p class="title">Portal Kullanım Kılavuzu</p>
    </div>
    <!-- Kategori Butonları -->
    <div class="container">
      <div
        v-for="category in categories"
        :key="category.id"
        class="box"
        :class="{ active: selectedCategory === category.id }"
        @click="selectedCategory = category.id"
      >
        {{ category.name }}
      </div>
    </div>

    <!-- Kategoriler ve Görseller -->
    <div v-if="selectedCategoryData" class="category">
      <h3>{{ selectedCategoryData.name }}</h3>
      <p>{{ selectedCategoryData.detail }}</p>

      <div class="images">
        <div
          v-for="image in filteredImages"
          :key="image.id"
          class="image-container"
          @click="toggleImage(image)"
        >
          <img
            :src="image.imagelink"
            :alt="image.title"
            class="thumbnail"
            :class="{ expanded: expandedImage === image }"
          />
          <p>{{ image.title }}</p>
        </div>
      </div>
    </div>

    <!-- Büyütülmüş Görsel -->
    <div v-if="expandedImage" class="overlay" @click="expandedImage = null">
      <img :src="expandedImage.imagelink" :alt="expandedImage.title" class="full-image" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      categories: [],
      selectedCategory: null,
      expandedImage: null,
    };
  },
  computed: {
    selectedCategoryData() {
      return this.categories.find(category => category.id === this.selectedCategory) || null;
    },
    filteredImages() {
      return this.selectedCategoryData ? this.selectedCategoryData.images : [];
    },
  },
  methods: {
    async fetchCategoriesWithImages() {
      try {
        const response = await axios.get('/auth/howtousecategories-with-images');
        this.categories = response.data;
        if (this.categories.length > 0) {
          this.selectedCategory = this.categories[0].id;
        }
      } catch (error) {
        console.error('Veriler alınırken hata:', error);
      }
    },
    toggleImage(image) {
      this.expandedImage = this.expandedImage === image ? null : image;
    },
  },
  mounted() {
    this.fetchCategoriesWithImages();
  },
};
</script>

<style scoped>
.how-to-use-gallery {
  padding: 150px 10px 10px 10px;
}

.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 10em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}

.box.active {
  border-bottom: 1px solid #023032;
  color: white;
}

.category {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; 
}

.image-container {
  cursor: pointer;
  max-width: 150px;
  text-align: center;
}

.thumbnail {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 0.2rem;
}

.expanded {
  transform: scale(1.5);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-image {
  max-width: 90%;
  max-height: 90%;
}
h3 {
  color: #086b68;
  font-size: 1.2rem;
  font-weight: 400;
}
.karga-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.top-karga  {
  max-width: 4rem;
  max-height: 4rem;
}
.title  {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 0.2rem; 
}
</style>