<template>
    <div class="top-container">
      <ForumMainNavigation /> 
    </div>
  </template>
  
<script> 
import ForumMainNavigation from './ForumMainNavigation.vue';

  export default {
    data() {
      return { 
      };
    },
    components: {    
      ForumMainNavigation
    },
  };
  </script>
    
  <style scoped> 
  .top-container{
    padding-top: 150px;
  }
  @media (max-width: 768px) {
    .top-container{
    padding-top: 30px;
  }
}
  </style>
    