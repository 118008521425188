<template>
  <div class="user-statistics">
    <p class="head">        
       <img src="./icons/stats.svg" class="icon" />
        İstatistiklerim
      </p>

    <div v-if="loading" class="loading">
      İstatistikler yükleniyor...
    </div>

    <div v-else-if="error" class="error">
      <p>Hata: {{ error }}</p>
    </div>

    <div v-else class="stats">
      <div class="stat-item">
        <strong>Ortalama Skor:</strong>
        <span>{{ stats.averageScore || 'Veri yok' }}</span>
      </div>
      <div class="stat-item">
        <strong>Toplam Test:</strong>
        <span>{{ stats.totalAttempts }}</span>
      </div>
      <div class="stat-item">
        <strong>Tamamlanan Testler:</strong>
        <span>{{ stats.completedAttempts }}</span>
      </div>
      <div class="stat-item">
        <strong>Devam Eden Testler:</strong>
        <span>{{ stats.inProgressAttempts }}</span>
      </div>
      <div class="stat-item">
        <strong>Süresi Dolan Testler:</strong>
        <span>{{ stats.expiredAttempts }}</span>
      </div>
      <div class="stat-item">
        <strong>Toplam Cevaplanan Soru:</strong>
        <span>{{ stats.totalAnswers }}</span>
      </div>
      <div class="stat-item">
        <strong><img src="./icons/ok.svg" class="mini-icon" />Doğru Yanıt Sayısı:</strong>
        <span>{{ stats.totalCorrect }}</span>
      </div>
      <div class="stat-item">
        <strong><img src="./icons/no.svg" class="mini-icon" />Yanlış Yanıt Sayısı:</strong>
        <span>{{ stats.totalIncorrect }}</span>
      </div>

    </div>
  </div>
</template>
 
<script>
import axios from 'axios';
import { eventBus } from '@/utils/eventBus';

export default {
  name: 'UserStatistics',
  data() {
    return {
      stats: {},
      loading: true,
      error: null,
    };
  },
  created() {
    this.fetchStatistics();
    eventBus.on('reloadStats', this.fetchStatistics);
  },
  beforeUnmount() {
    eventBus.off('reloadStats', this.fetchStatistics);
  },
  methods: {
    async fetchStatistics() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get('/education/my-test-stats');
        this.stats = response.data.statistics;
      } catch (error) {
        this.error = error.response?.data?.message || 'Bilinmeyen bir hata oluştu.';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
 
<style scoped> 
.head {  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 12px;
  font-size: 1.5em;
  font-weight: 300;   

}
.icon{max-height: 1.5em;}
.loading,
.error {
  text-align: center;
  font-size: 1rem; 
}

.stats {
  display: flex;
  flex-wrap: wrap; 
  gap: 5px;  
  justify-content: center;  
}

.stat-item { 
  background: #050505;
  border: 1px solid #022121;
  border-radius: 10px; 
  padding: 1.5em 1em;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; 
  max-width: 250px;
  width: 100%;
  transition: background-color 0.3s;
}
.stat-item:first-child {    
  animation: shadowColorChangeMini 3s infinite ease-in-out;
}

.stat-item strong { 
  display: flex; 
  align-items: center;
  text-align: center; 
  flex-direction: column;
  margin-bottom: 8px;
  font-weight: 400; 
  font-size: 1.2em;  
  gap: 3px;
}
.mini-icon{
  max-height: 1em;
  opacity: 0.5;
}

.stat-item span {
  font-size: 1.2rem;
  font-weight: 400;
  color: #187774;
}
.stat-item:hover { 
  background-color: black;
}

@media (max-width: 768px) { 

  .stats {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .stat-item {
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
  }

  .stat-item strong {
    font-size: 1em;
    margin-bottom: 2px;
  }

  .stat-item span {
    font-size: 1.25em;
    margin-top: 0;
  }
}
</style>