<template>
  <div class="container">
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label class="title">Tür:</label><div class="emptyfive"></div>
        <div class="radio-group">
          <label
            :class="['radio-option', transaction.type === 'income' ? 'active-income' : 'inactive-income']"
          >
            <input
              type="radio"
              value="income"
              v-model="transaction.type"
            />
            Giriş
          </label>
          <label
            :class="['radio-option', transaction.type === 'expense' ? 'active-expense' : 'inactive-expense']"
          >
            <input
              type="radio"
              value="expense"
              v-model="transaction.type"
            />
            Çıkış
          </label>
        </div>
      </div>

      <div class="form-group">
        <label class="title" for="amount">Miktar:</label> <div class="emptyfive"></div>
        <input
          type="number"
          v-model="transaction.amount"
          id="amount"
          class="form-control"
          placeholder="1"
          required
          step="0.01"
        />
      </div>

      <div class="form-group">
        <label class="title" for="description">Açıklama:</label> <div class="emptyfive"></div>
        <input
          type="text"
          v-model="transaction.description"
          id="description"
          class="form-control"
          placeholder="Örn. Elektrik Faturası"
        />
      </div>

      <div class="form-group">
        <label class="title" for="transaction_date">Tarih:</label><div class="emptyfive"></div>
        <input
          type="date"
          v-model="transaction.transaction_date"
          id="transaction_date"
          class="form-control"
        />
      </div>

      <button
        type="submit"
        :style="{ backgroundColor: transaction.type === 'income' ? '#106e35' : '#9b0101' }"
        class="btn btn-primary"
      >
        {{ transaction.type === 'income' ? 'Giriş Ekle' : 'Çıkış Ekle' }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { eventBus } from "@/utils/eventBus"; 

export default {
  props: ["id"],
  data() {
    return {
      transaction: {
        type: "income",
        amount: "",
        description: "",
        transaction_date: new Date().toISOString().split("T")[0],
        space_id: this.id,
      },
    };
  },
  methods: {
    async submitForm() {
      try {
        await axios.post("/promo/user/mt-transaction", this.transaction);
        eventBus.emit("reloadHistory");
        this.transaction = {
          type: "income",
          amount: "",
          description: "",
          transaction_date: new Date().toISOString().split("T")[0],
          space_id: this.id,
        };
        eventBus.emit("reloadHistory");
      } catch (error) {
        console.error("İşlem eklenirken hata oluştu:", error);
        alert("Bir hata oluştu, tekrar deneyin.");
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

form {
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 40px 20px;
  max-width: 1600px;
  width: 100%;
}

.form-group {
  margin-bottom: 5px;
}

.radio-group {
  display: flex;
  gap: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 1rem;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  user-select: none;
}

.radio-option input {
  display: none;
}

/* Aktif ve pasif durumlar */
.active-income {
  background-color: #106e35;
  color: white;
  border: 1px solid #106e35;
}

.inactive-income { 
  border: 1px solid #106e35; 
}

.active-expense {
  background-color: #9b0101;
  color: white;
  border: 1px solid #9b0101;
}

.inactive-expense {
  background-color: rgb(0, 0, 0);
  border: 1px solid #9b0101; 
}

.title {
  font-size: 1.2em;
  font-weight: 400; 
}

button {
  width: 100%;
  height: auto;
  font-weight: 500;
} 

@media (min-width: 768px) {
  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
  }

  .form-group:nth-child(1) {
    grid-column: span 4;
  }

  .form-group:nth-child(2),
  .form-group:nth-child(3),
  .form-group:nth-child(4) {
    grid-column: span 1;
  }

  .form-group:nth-child(5) {
    grid-column: span 4;
  }
}

@media (max-width: 767px) {
  form {
    display: block;
  }

  .form-group {
    width: 100%;
    margin-bottom: 20px;
  }

  .form-group:nth-child(1),
  .form-group:nth-child(2) {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
  }

  .form-group:nth-child(2) {
    margin-right: 0;
  }

  .form-group:nth-child(3) {
    width: 100%;
  }

  .form-group:nth-child(4),
  .form-group:nth-child(5) {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
  }

  .form-group:nth-child(5) {
    margin-right: 0;
  }
}
</style>
