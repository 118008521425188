<template>
  <div class="wallet-container">
    <div v-if="archivedMtSpaces.length > 0" class="wallet-list">
      <div 
        v-for="space in archivedMtSpaces" 
        :key="space.id" 
        class="wallet-card" 
        @click="goToWalletDetail(space.id)"
      >
        <div class="wallet-header">
          {{ space.name }}

          <div class="settings-box">
            <img 
              src="./icons/unarchive.svg" 
              alt="Arşivden Çıkar" 
              class="settings-icon" 
              @click.stop="unarchiveWallet(space.id)"
            />
            <img 
              src="./icons/settings.svg" 
              alt="Ayarlar" 
              class="settings-icon" 
              @click.stop="openSettings(space)"
            />
          </div>
        </div>
        
        <div class="wallet-desc"> 
          {{ space.description }} 
        </div>
        
        <div class="wallet-currency">
          {{ getCurrencyName(space.currency_id) }}
        </div>
      </div>
    </div> 
    <div v-else class="empty-message">
      Arşivlenmiş cüzdanınız bulunmuyor.
    </div>
  
    <div v-if="settingsModal" class="overlay" @click="settingsModal = false">
      <div class="modal" @click.stop>
        <h3>Cüzdan Ayarları</h3>
        <label>Cüzdan Adı:</label>
        <input v-model="selectedWallet.name" type="text" maxlength="50"/>

        <label>Açıklama:</label>
        <textarea v-model="selectedWallet.description" maxlength="250"></textarea>

        <div class="modal-buttons">
          <button class="confirm" @click="updateMtSpace">Kaydet</button> 
          <button class="cancel delete" @click="settingsModal = false">İptal</button>
        </div>

        <div class="delete-container">
          <h3>Bu cüzdanı sil</h3>
          <p>Lütfen dikkat, silme işlemi geri alınamaz.</p>
          <button class="delete" @click="confirmDeleteWallet">Sil</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        archivedMtSpaces: [],
        currencies: [],
        settingsModal: false,
        selectedWallet: null,
      };
    },
    methods: { 
      async fetchArchivedMtSpaces() {
        try {
          const response = await axios.get('/promo/user/archived-mt-spaces');
          this.archivedMtSpaces = response.data.data;
        } catch (error) {
          console.error("Arşivlenmiş MtSpaces alınırken hata oluştu:", error);
        }
      },
   
      async unarchiveWallet(mtspace_id) {
        try {
          await axios.put(`/promo/user/mt-spaces/${mtspace_id}/unarchive`);
          this.fetchArchivedMtSpaces(); 
        } catch (error) {
          console.error("MtSpace arşivden çıkarılırken hata oluştu:", error);
        }
      },
   
      getCurrencyName(currency_id) {
        const currency = this.currencies.find(c => c.currency_id === currency_id);
        return currency ? currency.currency_name : 'Bilinmeyen';
      },
   
      goToWalletDetail(id) {
        this.$router.push({ name: 'WalletDetail', params: { id } });
      },
   
      openSettings(wallet) {
        this.selectedWallet = { ...wallet };
        this.settingsModal = true;
      },
   
      async updateMtSpace() {
        try {
          if (!this.selectedWallet) return;
          await axios.put(`/promo/user/mt-spaces/${this.selectedWallet.id}`, {
            name: this.selectedWallet.name,
            description: this.selectedWallet.description
          });
          this.fetchArchivedMtSpaces();
          this.settingsModal = false;
        } catch (error) {
          console.error("MtSpace güncellenirken hata oluştu:", error);
        }
      },
   
      confirmDeleteWallet() {
        if (confirm("Bu işlem geri alınamaz. Cüzdanı kalıcı olarak silmek istediğinize emin misiniz?")) {
          this.deleteWallet();
        }
      },
   
      async deleteWallet() {
        try {
          if (!this.selectedWallet) return;
          await axios.delete(`/promo/user/mt-spaces/${this.selectedWallet.id}/delete`);
          this.fetchArchivedMtSpaces();
          this.settingsModal = false;
        } catch (error) {
          console.error("MtSpace silinirken hata oluştu:", error);
        }
      },
   
      async fetchCurrencies() {
        try {
          const response = await axios.get('/auth/currencies');
          this.currencies = response.data;
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchArchivedMtSpaces();
      this.fetchCurrencies();
    }
  };
  </script>
  
  <style scoped>
  .wallet-container {
    padding: 10px 0;
  }
  
  .wallet-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem; 
    justify-content: center;
  }
  
  .wallet-card {
    background: #050505; 
    max-width: 380px;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  }
  
  .wallet-card:hover {
    background: #000;  
    transform: scale(1.0);
    color: white;
    box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5); 
  }
  
  .wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 1.4em;
  }
  
  .wallet-desc {
    font-weight: 400;
    font-size: 1em;
    margin-top: 0.3rem;
  }
  
  .wallet-currency {
    font-weight: 400;
    font-size: 0.9em;
    margin-top: 1rem;
  }
  .settings-box{
    display: flex;
    gap: 15px;
    padding: 1em 0; 
  }
  .settings-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .settings-icon:hover {
    transform: scale(1.2);
  }
  
  .add-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #050505;
    font-size: 1.2em;
    font-weight: 300;
    max-width: 10rem;
  }
  
  .icon { 
    max-width: 1.5rem;  
    margin: 0.5em; 
  }
  
  .add-wallet p {   
    margin: 0.5em; 
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  
  .modal {
    background: #000;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 8px;
    border: 1px solid #022121; 
    max-width: 600px;
    width: 100%;
  } 
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .delete-container {
    padding: 1rem;
    background-color: #000;
    border: 1px solid #650101;
    margin: 1rem 0;
    border-radius: 1em;
  }
  .empty-message {
  text-align: center;
  padding: 2rem;
  font-size: 1.2em;
  color: #777;
}
  </style>
  