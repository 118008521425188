<template>
    <div class="panel-main">
        <div class="panel">

        
                 <h3>Bildirim Seçenekleri</h3>
                 <div v-for="item in optionLabels" :key="item.key" class="setting-item">
                   <span>{{ item.label }}</span>
                   <div @click="toggle(item.key)" :class="['switch', { active: settings[item.key] }]">
                     <div class="circle"></div>
                   </div>
                 </div>
                 <hr style="border-color: #050505; ">
                 <h3>Bildirim Türleri</h3>
                 <div v-for="item in typeLabels" :key="item.key" class="setting-item">
                   <span>{{ item.label }}</span>
                   <div @click="toggle(item.key)" :class="['switch', { active: settings[item.key] }]">
                     <div class="circle"></div>
                   </div>
                 </div>

       </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "SettingsPanel",
    data() {
      return {
        settings: {},
        optionLabels: [ 
          { key: 'is_appoinment_notification_on', label: 'Randevu Uygulaması Bildirimleri' },
          { key: 'is_accounting_notification_on', label: 'Muhasebe Uygulaması Bildirimleri' },
          { key: 'is_note_notification_on', label: 'Notlarım Bildirimleri' },
        ],
        typeLabels: [
          { key: 'is_email_notification_on', label: 'E-posta ile Bildirim' },
          { key: 'is_whatsapp_notification_on', label: 'Whatsapp ile Bildirim' }, 
          { key: 'is_local_notification_on', label: 'Uygulama İçi Bildirim' }
        ]
      };
    },
    async mounted() {
      await this.fetchSettings();
    },
    methods: {
      async fetchSettings() {
        const response = await axios.get('/promo/portal/notification-settings');
        this.settings = response.data.settings;
      },
      async updateSetting(key) {
        await axios.post('/promo/portal/update-setting', { field: key, value: this.settings[key] });
      },
      toggle(key) {
        this.settings[key] = !this.settings[key];
        this.updateSetting(key);
      }
    }
  };
  </script>
  
  <style scoped>
  .panel-main {  
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .panel {  
    margin-top: -20px;
    border-radius: 10px; 
    max-width: 260px; 
    width: 100%;
    background-color: #050505;
    padding: 1rem;
  }
  .settings-panel h4 {
    margin-bottom: 12px;
    font-weight: bold;
  }
  .setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .switch {
    width: 46px;
    height: 24px;
    background: #2a3942;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s;
  }
  .switch .circle {
    width: 20px;
    height: 20px;
    background: #cacaca;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.3s;
  }
  .switch.active {
    background: #106e35;
  }
  .switch.active .circle {
    transform: translateX(22px);
  }
  </style>
  