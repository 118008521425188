<template>

  <div class="main-container">
  <div class="head-content">
    <p class="title">İletişim ve Destek</p> 
    <p>Herhangi bir sorunuz, endişeniz veya geri bildiriminiz varsa, sizden haber almak isteriz. Aşağıdaki kanallardan bizimle iletişime geçebilirsiniz:</p>
  </div>

  <div class="head-content"> 
    <p class="sub-titleb">Kullanıcı Hesabı ve Genel Sorular</p>
    <p >Genel sorularınız ve kullanıcı hesabınızla ilgili konular için SSS bölümümüzü ziyaret etmek, daha hızlı yanıt almanızı sağlayabilir.</p>
  </div>
  
  <div class="contact-container"> 

    <section class="contact-section">
      <h4 class="section-title">Genel İletişim</h4>
      <p>Genel sorularınız için bize şu e-posta adresinden ulaşabilirsiniz:</p>
      <button v-if="!emailVisible.service" @click="showEmail('service')">
         E-postayı Göster
       </button>
       <button v-else @click="sendEmail('info@noahpmu.com', 'service')">
         {{ buttonTexts.service }}
       </button>
    </section> 

    <section class="contact-section">
      <h4 class="section-title">Kullanıcı Desteği</h4>
      <p>Kullanıcı desteği için hesabınıza giriş yaparak sağ üst köşedeki <b>"Profil"</b> butonu ardından <b>"Destek Taleplerim"</b> Butonu aracılığıyla bir destek bileti oluşturabilirsiniz. <br>   
      </p>
      <p>> Ayrıca aşağıdaki E-posta adresinden iletişime geçebilirsiniz</p>
      <button v-if="!emailVisible.support" @click="showEmail('support')">
         E-postayı Göster
       </button> 
      <button v-else @click="sendEmail('destek@noahpmu.com', 'support')">
        {{ buttonTexts.support }}
      </button>
    </section>  

    <section class="contact-section">
      <h4 class="section-title">Geri Bildirim</h4>
      <p>Geri bildirimlerinizi bekliyoruz. <br>
       Aşağıdaki e-posta adresi üzerinden geri bildirimlerinizi paylaşabilirsiniz. <br>
       Ayrıca hesabınıza giriş yaparak "Keşfet" bölümündeki anketlere katılabilir ve geri bildirim formlarını doldurabilirsiniz.</p>
       <button v-if="!emailVisible.feedback" @click="showEmail('feedback')">
         E-postayı Göster
       </button> 
      <button v-else @click="sendEmail('geribildirim@noahpmu.com', 'feedback')">
        {{ buttonTexts.feedback }}
      </button>
    </section>  

  </div>  
  
    <div class="buttoncontainer">
      <div class="join-now-button" @click="redirectToWhatsApp">
        <img src="@/assets/icon/whatsapp.svg" alt="WhatsApp" class="whatsapp-icon" />
        <span>WhatsApp İletişim Hattı</span>
      </div>

      <div class="join-now-button" @click="goToAccount">
        <img src="@/assets/icon/profile/support-white.svg" alt="WhatsApp" class="whatsapp-icon" />
        <span>Destek Kaydı</span>
      </div>
    </div> 

  <div class="head-content"> 
    <h4  class="title">Bizi Takip Edin</h4>
      <p>Bizi takip edin ve sosyal medya kanallarımızdan bizimle iletişime geçin:</p> 
      <a href="https://instagram.com/noahpmushop" target="_blank" rel="noopener noreferrer"><b>Instagram:</b> &#64;noahpmushop</a> 
  </div> 
 

  <div class="address">
    <img class="location" src="/assets/location.svg"/> 

    <p>Miu Academy Kozmetik San. ve Tic. Ltd. Şti. </p>
    <p>İstanbul / TÜRKİYE</p>  
  </div>  
</div>
</template>


<script> 

export default { 
  data() {
    return {
      emailVisible: {
        service: false,
        support: false,
        marketing: false,
        feedback: false,
      },
      buttonTexts: {
        service: 'info@noahpmu.com',
        support: 'destek@noahpmu.com', 
        feedback: 'geribildirim@noahpmu.com',        
      },  
    };
  },
  methods: { 
    redirectToWhatsApp() {
      window.open('https://wa.me/905331348139', '_blank');
    }, 
    goToAccount() {
      this.$router.push({ path: '/hesabim/destek' }); 
    },
    showEmail(key) {
      this.emailVisible[key] = true;
    },
    async sendEmail(email, key) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        const emailLink = `mailto:${email}`;
        window.location.href = emailLink;
      } else {
        try {
          const textarea = document.createElement('textarea');
          textarea.value = email;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          this.buttonTexts[key] = 'Kopyalandı!';
          setTimeout(() => {
            this.buttonTexts[key] = email;
          }, 1500);
        } catch (err) {
          console.error('Metin kopyalanırken hata oluştu: ', err);
          this.buttonTexts[key] = 'Kopyalama başarısız';
          setTimeout(() => {
            this.buttonTexts[key] = email;
          }, 3000);
        }
      }
    }
  }, 
}
</script>



<style scoped>   
.main-container { 
  padding: 5rem 1em;
} 
.contact-container { 
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex: 1 1 40em; 
  overflow: hidden; 
  flex-wrap: wrap;  
} 
.contact-section {
  flex: 1; 
  padding: 2em; 
  border-radius: 1em; 
  background: #040404; 
  margin: 0.4em; 
  min-width: 20em;
  max-width: 45em;
  transition: opacity 0.3s ease;
} 
.section-title { 
   font-size: 1.4em;  
   font-weight: 400; 
 }

 .modal-form { 
  display: flex;
  justify-content: center;
 }
 .modal-button {
  margin-top: 2em;
  padding: 0.5em 2em;
  background-color: #050505;
  color: #b1c3c9;
  border: none;
  border-radius: 2em;
  cursor: pointer;
  transition: background-color 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14em; 
  font-weight: 300;
}
.icon {
 max-width: 1.2em;
 margin-right: 1em;
}
.modal-button:hover {
  background-color: #000;
  color: #cbe5ef;
}

button {
  padding: 0em;
  border: none;
  background: transparent;
  text-decoration: none;
  color: #065959;
  cursor: pointer; font-weight: 600;
 }
 button:hover  { 
  color: #187774;
 }
 
 a { 
  text-decoration: none;
  color: #065959;
  cursor: pointer;
  font-size: 1rem;
 }
 a:hover  { 
  color: #187774;
 }
.head-content {
  text-align: center; 
  margin: 3em 0em;
 } 
 .sub-title { 
   margin-bottom: -0.5em;
   font-size: 1.6em;  
 }
 .sub-titleb {  
   font-size: 1.6em;  
 }
 .buttoncontainer{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
}
.join-now-button {
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 10px 20px;  
  border-radius: 2em;
  cursor: pointer;
  text-align: center; 
  border: 1px solid #000000; 
  transition: transform 0.3s ease, box-shadow 0.5s ease; 
  background-color: #0e3031;
}

.join-now-button:hover {
  transform: scale(1.04); 
  box-shadow: 0 8px 18px rgba(55, 55, 55, 0.3); 
  background-color: #005c4a;
  
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.address {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.address p{
  margin: 0;
  padding: 0;
}
.location {
  max-width: 3rem;
  margin: 1rem;
}
</style>