<template>
  <div class="user-calendar">
    <vue-cal
      locale="tr"
      view="week"
      :time-step="15"
      :events="events"
      :disable-views="['years' ]"
      @cell-click="addEvent"
      @event-click="editEvent"
    />

    <transition :name="selectedEvent ? 'fade' : ''">
      <div v-if="selectedEvent" class="modal">
        <h3>Randevu Düzenle</h3>
        <input v-model="selectedEvent.title" placeholder="Başlık" />
        <button @click="deleteEvent">Sil</button>
        <button @click="selectedEvent = null">Kapat</button>
      </div>
    </transition>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  components: {
    VueCal,
  },
  data() {
    return {
      events: [
        { start: '2025-03-20 14:10', end: '2025-03-20 15:30', title: 'Örnek Randevu' }
      ],
      selectedEvent: null
    };
  },
  methods: {
    addEvent({ start }) {
      const parsedStart = new Date(start);
      if (isNaN(parsedStart.getTime())) {
        console.error('Geçersiz tarih formatı:', start);
        return;
      }

      const end = new Date(parsedStart);
      end.setMinutes(end.getMinutes() + 60);

      const newEvent = {
        start: parsedStart.toISOString(),
        end: end.toISOString(),
        title: 'Yeni Randevu'
      };

      this.events.push(newEvent);
    },
    editEvent(event) {
      this.selectedEvent = event;
    },
    deleteEvent() {
      this.events = this.events.filter(e => e !== this.selectedEvent);
      this.selectedEvent = null;
    }
  }
};
</script> 
 
<style scoped>
.user-calendar {
    background-color: #000000;
    padding: 10px;
    border-radius: 5px;
    color: #e0e0e0; 
}

/* VueCal genel alan */
.vuecal {
    background-color: #0a0a0a; 
    border: 1px solid #023032;
    border-radius: 5px;
}

/* Takvim başlık ve menüler */
.vuecal__title-bar,
.vuecal__menu {
    border-radius: 5px;
    background-color: #0a0a0a;
    color: #e0e0e0;
    border-bottom: 1px solid #023032;
    overflow-y: auto;
    padding: 1rem 0;
}

/* Haftalık başlıklar */
.vuecal__weekdays-headings {
    background-color: #050505; 
}
.vuecal__no-event{background-color: #2b1608;}
/* Hücreler */
.vuecal__cell {
    background-color: #0a0a0a;
    border: none; 
    padding: 1em;
    margin: 5px;
}
.vuecal__cell-content { 
    border: none;  
}
.vuecal__cell--today {
    background-color: #241d1d;
}

.vuecal__cell--selected {
    background-color: #000;
}



/* Event kutuları */
.vuecal__event {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
}

.vuecal__event--focus,
.vuecal__event:focus {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.2);
}

/* Modal */
.modal {
    background: #222;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 15px rgba(0,0,0,0.7);
    width: 300px;
    z-index: 1000;
}

.modal h3 {
    margin-top: 0;
}

.modal input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    background: #333;
    color: #fff;
    border: 1px solid #555;
    border-radius: 4px;
}

.modal button {
    margin-right: 10px;
    padding: 8px 12px;
    background: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal button:hover {
    background: #555;
}

/* Butonlar */
.vuecal__view-btn {
    color: #e0e0e0;
}

.vuecal__view-btn--active {
    border-color: #00adb5;
    background: #2c2c2c;
}

/* Navigasyon okları ve today butonu */
.vuecal__arrow,
.vuecal__today-btn {
    color: #e0e0e0;
}

.vuecal__arrow--highlighted,
.vuecal__today-btn--highlighted,
.vuecal__view-btn--highlighted {
    background: #333;
}


@media screen and (max-width: 550px) { 
  .user-calendar { 
    padding: 0; 
}
 }





 .vuecal__cell-date {
    padding: 8px;
}

</style>