<template>
  <div class="create-ticket-container">
    <div class="ticket-details" v-if="hasOpenTicket">
      <div>
        <p class="active-header">Aktif Destek Talebi</p>
        <p><strong>Bölüm:</strong> {{ openTicket.department }}</p>
        <p><strong>Talep ID:</strong> {{ openTicket.ticket_id }}</p>
        <p><strong>Durum:</strong> {{ openTicket.status }}</p>
        <p><strong>Konu:</strong> {{ openTicket.subject }}</p>
        <p><strong>Tarih:</strong> {{ formatDate(openTicket.created_at) }}</p>

        <div v-for="response in sortedResponses" :key="response.id"  :class="{'user-response': !response.is_admin_response, 'admin-response': response.is_admin_response}">
          <p><strong>{{ response.is_admin_response ? response.responder.first_name + ' ' + response.responder.last_name : 'Siz' }}:</strong></p>
          <pre class="response-message">{{ response.message }}</pre> 
          <span class="response-date">({{ formatDate(response.response_date) }})</span>
        </div>
        

      </div>
      <div v-if="canUserRespond">
        <textarea id="additionalMessage" v-model="message" required minlength="15" maxlength="1500"></textarea>
        <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <div class="button-container">
          <button @click="addResponseToTicket">Yanıt Ekle</button>
        </div>
      </div>
      <div v-else>
        <p class="alert alert-warning">Başka bir mesaj eklemeden önce destek ekibinin yanıt vermesini bekleyin.</p>
      </div>
    </div>
    <form class="request-form" v-else @submit.prevent="submitTicket">
      <p class="active-header"> Destek Talebi Oluştur</p>
      <p class="active-more">- Daha hızlı yanıt almak için destek ihtiyacınıza en uygun bölümü seçtiğinizden emin olun.</p>
      <p class="active-more">- Ortalama yanıt süremiz 2 saattir.</p>
      <p class="active-more">- Hemen yardım mı gerekiyor? Anında destek için WhatssApp hattımızı kullanabilirsiniz.</p>
      <p class="active-more">- Destek talebinizle ilgili güncellemeleri almak için e-posta bildirimlerinizin açık olduğundan emin olun.</p>
      <div class="form-group">
        <label for="department">Bölüm:</label>
        <select id="department" v-model="department">
          <option value="Genel">Genel Soru</option>
          <option value="Portal">Noah Portal</option>
          <option value="Hesap">Kullanıcı Hesabı</option>
          <option value="Sipariş">Sipariş</option> 
        </select>
      </div>
      <div class="form-group">
        <label for="subject">Konu:</label>
        <input type="text" id="subject" v-model="subject" required minlength="3" maxlength="25" />
      </div>
      <div class="form-group">
        <label for="message">Mesaj:</label>
        <textarea id="message" v-model="message" required minlength="15" maxlength="1500"></textarea>
      </div>
      <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
      <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div class="button-container">
        <button type="submit">Destek Talebini Gönder</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      department: 'Genel',
      subject: '',
      message: '',
      successMessage: '',
      errorMessage: '',
      hasOpenTicket: false,
      canUserRespond: false,
      openTicket: null
    };
  },
  async mounted() {
    await this.checkOpenTickets();
  },
  computed: {
  sortedResponses() {
    return this.openTicket 
      ? [...this.openTicket.responses].sort((a, b) => new Date(a.response_date) - new Date(b.response_date))
      : [];
  }
},
  methods: {
    async checkOpenTickets() {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error("Kullanıcı yetkilendirilmemiş.");
    
        const response = await axios.get('/promo/user-tickets', { headers: { Authorization: `Bearer ${token}` } });
        const tickets = response.data.tickets;
    
        const openOrRespondedTicket = tickets.find(ticket => ticket.status === 'Open' || ticket.status === 'Responded');
    
        if (openOrRespondedTicket) {
          this.hasOpenTicket = true;
          this.openTicket = {
            ...openOrRespondedTicket,
            responses: [...openOrRespondedTicket.responses]
          };
    
          const lastResponse = this.openTicket.responses[this.openTicket.responses.length - 1];
          this.canUserRespond = lastResponse && lastResponse.is_admin_response;
        } else {
          this.hasOpenTicket = false;
        }
      } catch (error) {
        console.error('Kullanıcı talepleri getirilirken hata oluştu:', error);
      }
    },
    async submitTicket() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/promo/create-ticket', {
          subject: this.subject,
          message: this.message,
          department: this.department
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.success) {
          this.successMessage = 'Talep başarıyla oluşturuldu!';
          this.subject = '';
          this.message = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 3000);
          await this.checkOpenTickets();
        } else {
          this.errorMessage = 'Talep oluşturulurken hata oluştu. Lütfen tekrar deneyin.';
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
      } catch (error) {
        console.error('Talep oluşturulurken hata oluştu:', error);
        this.errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Talep oluşturulurken hata oluştu. Lütfen tekrar deneyin.';
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      }
    },
    async addResponseToTicket() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/promo/add-response', {
          message: this.message,
          ticket_id: this.openTicket.ticket_id
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.success) {
          this.successMessage = 'Yanıt başarıyla eklendi!';
          this.message = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 4000);
          await this.checkOpenTickets();
        } else {
          this.errorMessage = response.data.error || 'Yanıt eklenirken hata oluştu. Lütfen tekrar deneyin.';
          setTimeout(() => {
            this.errorMessage = '';
          }, 4000);
        }
      } catch (error) {
        console.error('Yanıt eklenirken hata oluştu:', error);
        if (error.response && error.response.data && error.response.data.error) {
          this.errorMessage = error.response.data.error;
        } else {
          this.errorMessage = 'Yanıt eklenirken hata oluştu. Lütfen tekrar deneyin.';
        }
        setTimeout(() => {
          this.errorMessage = '';
        }, 4000);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  }
};
</script>



<style scoped> 
.active-more { 
  padding-left: 1em;  
}
.request-form {
  padding: 1em;
  background-color: #050505;
}

.create-ticket-container {
  width: 100%;
  margin: auto;
}

.form-group {
  margin-bottom: 1em;
}

label {
  display: block;
  margin-bottom: 0.4em;
  font-weight: 600;
}

input, textarea {
  margin-top: 0.4em;
  width: 100%;
  min-height: 1.8em;  
}
textarea { 
  min-height: 3em;
  padding: 0.6em;
  max-width: 99%;
}

input, textarea:focus {
  width: 100%;  
  outline: none;
}
.button-container {
  text-align: right; 
}

button {
  padding: 0.6em 3em;  
  border-radius: 0.2em;
  cursor: pointer; 
  transition: background-color 0.3s ease;
}
 

.alert {
  margin: 2em 0;
  padding: 1em;
  border-radius: 0.4em;
  text-align: center;
}

.alert-success {
  background-color: #000;
  color: #127750; 
}

.alert-danger {
  background-color: #000;
  color: #953342; 
}

.alert-warning {
  background-color: #000;
  color: #917e5c; 
}

.ticket-details {
  border: none;
  padding: 1em;
  margin-bottom: 2em;
  background-color: #050505; 
}

.response-date {
  color: #5c5c5c;
  margin-left: 10px;
}

.active-header { 
  font-weight: 600;  
  margin: 1em 0em;
  color: #127750;
}

.user-response, .admin-response {
  background-color: #000;
  padding: 0.4em 1em;
  margin-top: 0.6em;
  max-width: 80%; 
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
} 
.user-response {
  border-radius: 0em 1em  1em  1em ;
  border-left: 1px solid #127750;
  margin-left: 0;
  margin-right: auto;
} 
.admin-response {
  border-radius: 1em 0em 1em  1em ;
  border-right: 1px solid #87915c;
  margin-left: auto;
  margin-right: 0;
} 

select {   
    padding: 0.4em; 
    border-radius: 0.2em;  
}  
select:focus { 
    outline: none; 
}   
.response-message {
  white-space: pre-wrap; 
}
</style> 