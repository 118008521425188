<template>
    <div class="test-solver">
      <!-- X Butonu -->
      <button class="close-x-btn" @click="$emit('close')">X</button>
  
      <div v-if="loading" class="loading-container">Yükleniyor...</div>
      <div v-else>
        <div v-if="completed" class="completed-container"> 
          <h3>Test Tamamlandı</h3>
          <img src="./icons/ok.svg" class="icon" />
          <p style="font-size: 1rem;">Tebrikler Testi bitirdiniz!</p>
          <p v-if="score !== null" class="score-text">Puanınız: <strong>{{ score }} / 100</strong></p>
          <button @click="$emit('close')" class="close-btn">Kapat</button>
        </div>
        <div v-else class="question-container">
          <!-- Progress Bar -->
          <div class="progress">
            <p class="progress-text">Soru {{ currentQuestionIndex + 1 }} / {{ questions.length }}</p>
            <div class="progress-bar-container">
              <div 
                class="progress-bar" 
                :style="{ width: ((currentQuestionIndex + 1) / questions.length * 100) + '%' }"
              ></div>
            </div>
          </div>
  
          <div class="question" v-if="currentQuestion">
            <h4 class="question-text">{{ currentQuestion.question_text }}</h4>
            <div class="answers">
              <div class="answer-option" v-for="answer in currentQuestion.answers" :key="answer.question_answer_id">
                <label class="custom-radio">
                  <input 
                    type="radio" 
                    :value="answer.question_answer_id" 
                    v-model="selectedAnswerId"
                  />
                  <span class="radio-label">{{ answer.answer_text }}</span>
                </label>
                <div class="separator"></div>
              </div>
            </div>
          </div>
  
          <button 
            class="action-btn" 
            :disabled="selectedAnswerId === null" 
            @click="submitAnswer"
          >
            {{ isLastQuestion ? 'Bitir' : 'Sonraki Soru' }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
     
  <script>
  import axios from 'axios';
  import { eventBus } from '@/utils/eventBus';

  export default {
    props: {
      testId: {
        type: Number,
        required: true
      },
      attemptId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        questions: [],
        answeredQuestions: [],
        currentQuestionIndex: 0,
        selectedAnswerId: null,
        completed: false,
        score: null
      };
    },
    computed: {
      currentQuestion() {
        return this.questions[this.currentQuestionIndex];
      },
      isLastQuestion() {
        return this.currentQuestionIndex === this.questions.length - 1;
      }
    },
    methods: {
      async fetchQuestions() {
        try {
          const response = await axios.get(`/education/get-test-questions/${this.testId}?attempt_id=${this.attemptId}`);
          this.questions = response.data.questions;
          this.answeredQuestions = response.data.answeredQuestions || [];
  
          const answeredCount = this.answeredQuestions.length;
          if (answeredCount >= this.questions.length) {
            this.completed = true;
          } else {
            this.currentQuestionIndex = answeredCount;
          }
  
          this.loading = false;
        } catch (error) {
          console.error('Sorular alınırken hata:', error);
          this.loading = false;
        }
      },
      async submitAnswer() {
        if (this.selectedAnswerId === null) return;
  
        const questionId = this.currentQuestion.question_id;
        
        try {
          await axios.post('/education/answer-question', {
            attempt_id: this.attemptId,
            question_id: questionId,
            selected_answer_id: this.selectedAnswerId
          });
        } catch (error) {
          console.error('Cevap gönderilirken hata:', error);
        }
  
        this.selectedAnswerId = null;
        
        if (this.isLastQuestion) { 
          try {
            const finishResponse = await axios.post('/education/finish-test', { attempt_id: this.attemptId });
            if (finishResponse.data.success) {
              this.score = finishResponse.data.score; 
              this.$nextTick(() => { 
                eventBus.emit('reloadStats');
              });
            }
          } catch (error) {
            console.error('Test bitirilirken hata:', error);
          }
          
          this.completed = true;
        } else {
          this.currentQuestionIndex++;
        }
      }
    },
    async mounted() {
      await this.fetchQuestions(); 
    }
  };
  </script> 
   
  <style scoped>
  .test-solver {  
    padding: 10px;  
    background: #000;
    min-height: 300px;
    position: relative; 
  }
  
  .loading-container {
    text-align: center;
    font-size: 16px;
    color: #aaa;
    margin-top: 50px;
  }
  
  .completed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    margin-top: 50px;
  }
  .completed-container h5 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #187774;
  }
  .score-text {
    margin-top: 10px;
    font-size: 16px;
  }
  .close-btn {
    background: #106e35;
    border: none;
    padding: 10px 20px; 
    border-radius: 6px;
    margin-top: 20px;
    cursor: pointer;
  }
  .close-btn:hover {
    background: #0b5c2a;
  }
  
  .question-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .progress {
    margin-bottom: 20px;
  }
  .progress-text {
    font-size: 14px;
    margin-bottom: 8px;
    color: #ccc;
  }
  .progress-bar-container {
    background: #333;
    height: 6px;
    border-radius: 5px;
    overflow: hidden;
  }
  .progress-bar {
    background: #065959;
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .question-text {
    font-size: 1.1em;
    margin-bottom: 15px; 
  }
  .answers {
    margin-bottom: 20px;
  }
  .answer-option {
    margin: 10px 0;
  }
  
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer; 
    position: relative;
    padding-left: 30px;
    user-select: none;
  }
  
  .custom-radio input[type="radio"] {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    appearance: none;
    width: 16px;
    height: 16px;
    margin: 0;
    border: 2px solid #888;
    border-radius: 50%;
    background-color: #1f1f1f;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .custom-radio input[type="radio"]:checked {
    background-color: #065959;
    border-color: #187774;
  }
  
  .custom-radio input[type="radio"]:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 99, 145, 0.3);
  }
  
  .radio-label {
    margin-left: 10px; 
  }
  
  .separator {
    border-bottom: 1px solid #333;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .icon {
  max-width: 2rem;
}
  
  .action-btn {
    background-color: #106e35; 
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  .action-btn:disabled {
    background-color: #0b3018;
    cursor: not-allowed;
  }
  .action-btn:not(:disabled):hover {
    background-color: #0b5c2a;
  }
  .close-x-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #9b0101;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  font-weight: bold;
}

.close-x-btn:hover {
  color: #d00000;
}
  </style>
  