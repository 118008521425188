<template>
  <div class="survey-results-modal">
    <div class="modal-content">
      <button class="close-button" @click="close">Kapat</button>
      <div class="modal-header">
        <h3 class="title">{{ surveyData.title }}</h3>
        <p><b>Durum:</b> {{ surveyData.status }}</p>
        <p><b>Toplam Katılımcı Sayısı:</b> {{ totalParticipants }}</p>
      </div>
      <div class="modal-body">
        <div class="survey-content" v-for="question in surveyQuestions" :key="question.question_id">
          <h4>{{ question.question_text }}</h4>
          <div v-if="question.question_type === 'text'">
            <p class="title-sub">
              Lütfen dikkat: Metin olarak gönderilen yanıtlar kısalık ve gizlilik nedeniyle hariç tutulmuştur.
            </p>
          </div>
          <ul v-else>
            <li v-for="option in question.options" :key="option.option_id">
              {{ option.option_text }}: {{ option.responseCount }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    surveyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      surveyData: {},
      surveyQuestions: [],
      totalParticipants: 0
    };
  },
  async mounted() {
    await this.fetchSurveyResults();
  },
  methods: {
    async fetchSurveyResults() {
      try {
        const response = await axios.get(`/auth/survey-results/${this.surveyId}`);
        this.surveyData = response.data.survey;
        this.surveyQuestions = response.data.questions;
        this.totalParticipants = response.data.totalParticipants;
      } catch (error) {
        console.error('Anket sonuçları alınırken hata oluştu:', error);
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.survey-results-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a; 
  z-index: 10000;
  padding: 1em;
  box-sizing: border-box;
}

.modal-content {
  position: relative;
  max-width: 960px;
  width: 100%;
  background-color: #000;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
}

/* Kapat düğmesi için pozisyonlama */
.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  margin: 1em 0.5em;
  padding: 0.5em 2em;
  min-width: 9em;
  background-color: #9b0101; 
  border: none;
  border-radius: 2em;
  cursor: pointer;
  z-index: 2;
}
.close-button:hover {
  background-color: #d00000;
}

/* Başlık ve özet bilgileri içeren alan */
.modal-header {
  padding: 3em 1em 1em 1em;
  background-color: #000;
  color: #fff;
  z-index: 1;
}

/* Kaydırılabilir içerik alanı */
.modal-body {
  padding: 1em;
  background-color: #000;
  color: #fff;
  overflow-y: auto;
  flex-grow: 1;
}

.survey-content {
  background-color: #050505;
  padding: 2em;
  margin: 0.5em 0;
  border-radius: 0.5em;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

.title { 
  font-size: 1.2rem;
  font-weight: 600;
} 

.title-sub { 
  font-size: 1em;
  font-weight: 600;
  color: #187774;
} 
</style>
