<template>
    <div class="login-container">
      <div class="login-form">
        
        <form @submit.prevent="handleLogin">
          <div class="form-group">

            <img class="icon" src="@/assets/icon/login-icon.svg" alt="Login Icon"  />
            <h2>Kullanıcı Girişi</h2>
            <label for="e_mail_or_user_name">Kullanıcı Adı veya E-posta</label>
            <input type="text" id="e_mail_or_user_name" v-model="e_mail_or_user_name" required />
          </div>
          <div class="form-group">
            <label for="password">Şifre</label>
            <input type="password" id="password" v-model="password" required />
          </div>
          <div v-if="message" :class="messageClass">{{ message }}</div>
          <button type="submit">Giriş Yap</button>
        </form>
        <div class="login-links">
          <p @click="navigateToForgotPassword" class="link">> Şifremi Unuttum</p>
          <p>
            Üye değil misiniz?
          </p>
          <p @click="navigateToRegister" class="register-link">Şimdi Üye Ol!</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        e_mail_or_user_name: '',
        password: '',
        message: '',
        messageClass: 'error', 
      };
    },
    methods: {
      async handleLogin() {
        try {
          const response = await axios.post('/auth/login', {
            e_mail_or_user_name: this.e_mail_or_user_name,
            password: this.password,
          });
  
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user_id);
            this.message = 'Giriş başarılı! Ana sayfaya yönlendiriliyorsunuz.';
            this.messageClass = 'success';
            setTimeout(() => {
              this.$router.push({ path: '/' });
            }, 2000);
          } else {
            this.message = 'Giriş başarısız. Lütfen bilgilerinizi kontrol edin.';
            this.messageClass = 'error';
          }
        } catch (error) {
          console.error('Giriş yapılırken hata oluştu:', error);
          this.message = 'Giriş başarısız. Lütfen bilgilerinizi kontrol edin.';
          this.messageClass = 'error';
        }
      },
      navigateToForgotPassword() {
        this.$router.push({ path: '/forgot-password' });
      },
      navigateToRegister() {
        this.$router.push({ path: '/kayitol' });
      },
    },
    mounted() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/' });
      }
    },
  };
  </script>
  
  
  <style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
  }
  
  .login-form {
    background: #050505;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  h2 {
    margin: 0 1em; 
    color: #065959;
    font-weight: 400;
    font-size: 1.2rem;
  }
  
  .form-group {
    margin-bottom: 0em;
    text-align: center;
  }
  
  label {
    display: block;
    margin-bottom: 0.8em; 
  } 
  button {
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 5px;
    background-color: #065959;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 15px;
  }
  
  button:hover {
    background-color: #187774;
  }
  
  .login-links {
    margin-top: 1em; 
  }
  
  .link {
    color: #969696; 
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
  }
  
  .link:hover {
    color: #187774;
  }

  .register-link {
    color: #187774; 
    cursor: pointer;
    font-size: 1.3em;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 2rem;
  }
  .register-link:hover {
    color: #22a5a1;  
  }
  .icon {
    max-width: 5rem;
    margin: 1rem 0 0 0;
  }
  </style>
  