<template> 
  <div class="user-chats">
    <div v-for="chat in userChats" :key="chat.id" class="chat-item">
      <div class="first" @click.stop="deleteChat(chat.id)"><img src="@/assets/icon/delete.svg" class="item-icon" /></div>
      <p @click="selectChat(chat)">{{ chat.title }}</p> 
      <div class="first"  @click.stop="editChatName(chat)"><img src="@/assets/icon/edit-chat-name.svg" class="item-icon" /></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  emits: ['chat-selected'],
  setup(_, { emit }) {
    const userChats = ref([]);

    const fetchUserChats = async () => {
      try {
        const response = await axios.get("/promo/user/noahaura/chats");
        userChats.value = response.data.chats;
      } catch (error) {
        console.error("Geçmiş sohbetler alınamadı:", error);
      }
    };

    const selectChat = (chat) => {
      emit('chat-selected', chat);
    };

    const deleteChat = async (chatId) => {
      if (confirm("Bu sohbeti silmek istediğinize emin misiniz?")) {
        try {
          await axios.delete(`/promo/user/noahaura/chat/${chatId}`);
          userChats.value = userChats.value.filter(chat => chat.id !== chatId);
        } catch (error) {
          console.error("Sohbet silinirken hata:", error);
        }
      }
    };

    const editChatName = async (chat) => {
      const newName = prompt("Yeni sohbet adı:", chat.title);
      if (newName && newName.trim() !== "") {
        try {
          await axios.put(`/promo/user/noahaura/chat/${chat.id}/rename`, { chat_name: newName });
          chat.title = newName;
        } catch (error) {
          console.error("Sohbet adı güncellenirken hata:", error);
        }
      }
    };

    onMounted(fetchUserChats);

    return { userChats, selectChat, deleteChat, editChatName };
  }
};
</script>

<style scoped>
.user-chats {
  max-height: 20rem;
  overflow-y: auto; 
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.4em; 
}
.chat-item {
  display: flex;
  gap: 1em;
  align-items: center; 
  padding: 0.5em;
  cursor: pointer;  
  background-color: #0c0c0c;  
  flex-grow: 1;
}
.chat-item:hover {
  background: #000000;
}
.first {
  display: flex; 
  align-items: center; 
  justify-content: center;
  padding: 0.5em; 
  min-width: 1rem; 
  opacity: 0.6;
  transition: transform 0.3s ease-in-out; 
}
.item-icon {
  max-width: 1rem; 
}
.first:hover {
  transform: scale(1.3); 
  opacity: 1;
}
</style> 