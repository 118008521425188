<template>
    <div class="modal-backdrop" @click.self="$emit('close')">
      <div class="modal-content">
        <h3>Geri Bildirim</h3>  
   
        <div v-if="showSuccess" class="success-message">Teşekkürler! Geri bildiriminiz bize ulaştı.
        </div>
   
        <div v-else>
          <label>Başlık:</label>
          <input v-model="title" placeholder="Başlık (isteğe bağlı)" />
  
          <label>Geri Bildirim Türü:</label>
          <select v-model="type">
            <option value="Faydalı">Faydalı</option>
            <option value="Yanıt yetersiz">Yanıt yetersiz</option>
            <option value="Yanıt yanlış">Yanıt yanlış</option>
          </select>
  
          <label>Mesajınız:</label>
          <textarea v-model="feedback" placeholder="Geri bildiriminiz..." rows="4"></textarea>
  
          <div class="actions">
            <button class="send-btn" @click="submitFeedback">Gönder</button>
            <button class="close-btn" @click="$emit('close')">Kapat</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { ref } from "vue";
  import axios from "axios";
  
  export default {
    props: ['messageId', 'chatId'],
    emits: ['close'],
    setup(props, { emit }) {
      const feedback = ref("");
      const title = ref("");
      const type = ref("Faydalı");
      const showSuccess = ref(false);
  
      const submitFeedback = async () => {
        if (feedback.value.length < 10) {
          alert("Geri bildiriminiz en az 10 karakter olmalıdır.");
          return;
        }
  
        const combinedFeedback = `
        [Başlık]: ${title.value || '-'} 
        [Tür]: ${type.value}
        [Mesaj]: ${feedback.value}
        `;
  
        try {
          await axios.post("/promo/user/noahaura/feedback", {
            chat_id: props.chatId,
            message_id: props.messageId,
            feedback: combinedFeedback.trim()
          });
   
          showSuccess.value = true;
   
          setTimeout(() => {
            showSuccess.value = false;
            feedback.value = "";
            title.value = "";
            type.value = "Faydalı";
            emit('close');
          }, 3000);
  
        } catch (err) {
          console.error("Feedback gönderilemedi:", err);
        }
      };
  
      return { feedback, title, type, submitFeedback, showSuccess };
    }
  }
  </script>
  
  
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #050505;
    padding: 1.5em;
    border-radius: 10px;
    border: 1px solid #022121;
    min-width: 320px;
    display: flex;
    flex-direction: column; 
    gap: 5px;
  }
  
  input, textarea, select {
    width: 100%;
    padding: 0.5em;
    border-radius: 5px; 
  }
  
  textarea {
    resize: none;
  }
  
  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-btn {
    background: #065959;
    color: white;
  }
  
  .send-btn:hover {
    background: #053737;
  }
  
  .close-btn {
    background: #0e0e0e;
    color: white;
  }
  
  .close-btn:hover {
    background: #252525;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  
  .success-message {
  background: #000;
  color: #227a41;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  transition: opacity 0.3s ease;
}
  </style>
  