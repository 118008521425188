<template>
    <div class="education-by-language">  
    </div>
  </template>
  
  <script> 
  export default {
    data() {
      return { 
      };
    },
    computed: {  
    }, 
  };
  </script>