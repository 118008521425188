<template>
  <div class="question-details">
    <p v-if="loading">Yükleniyor...</p>
    <p v-if="error" class="error-message">{{ error }}</p>

    <div v-if="question" class="question-container">
      <h2 class="question-title">
        <img class="btn-icon" src="./icons/head.svg">{{ question.title }}
      </h2>

      <p class="question-details-text">{{ question.details }}</p>

      <div class="tags-container">
        <span v-for="tag in question.tags" :key="tag.tag_id" class="tag">#{{ tag.tag_name }}</span>
      </div>
      <p class="question-meta">
        <span>{{ question.username }}</span> |
        <span>{{ formatDateTime(question.created_at) }}</span> |
        <img class="btn-icon" src="./icons/eye.svg">{{ question.view_count }}
      </p>

      <div class="answers-container">
        <p class="question-title yanitlar">
          <img class="btn-icon" src="./icons/yanitlar.svg">Yanıtlar
        </p>
        <div v-if="question.answers.length > 0">
          <div
            v-for="answer in question.answers"
            :key="answer.answer_id"
            class="answer-card"
            :class="{ 'my-answer': answer.user_id === currentUserId }"
          >
            <p class="answer-content">{{ answer.content }}</p>
            <p class="answer-meta">
              <span>{{ answer.username }}</span> |
              <span>{{ formatDateTime(answer.created_at) }}</span>
            </p>

            <div class="votes-container">
              <button
                @click="vote(answer.answer_id, 'Upvote')"
                :class="{ voted: answer.user_vote === 'Upvote' }"
              >
                <img class="btn-icon" src="./icons/thumb-up.svg" alt="UpVote"> {{ answer.upvotes }}
              </button>
              <button
                @click="vote(answer.answer_id, 'Downvote')"
                :class="{ voted: answer.user_vote === 'Downvote' }"
              >
                <img class="btn-icon" src="./icons/thumb-down.svg" alt="DownVote"> {{ answer.downvotes }}
              </button>
            </div>

            <div style="display: flex; justify-content: flex-end;">
                <div 
                  v-if="answer.user_id === currentUserId"
                  class="delete-answer-button"
                  @click="deleteAnswer(answer.answer_id)"
                ><img class="delete-icon" src="./icons/delete.svg">
                  Yanıtı Sil
                </div>
            </div>
            
          </div>
        </div>
        <p v-else>Bu soruya henüz yanıt verilmemiş.</p>
      </div>

      <AnswerTheQuestion :questionId="questionId" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AnswerTheQuestion from "./AnswerTheQuestion.vue";

export default {
  name: "QuestionDetails",
  props: {
    questionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      question: null,
      loading: true,
      error: null,
      currentUserId: null
    };
  },
  components: {
    AnswerTheQuestion
  },
  computed: {
    apiEndpoint() {
      return localStorage.getItem("token")
        ? "/promo/user/forum/questiondetails"
        : "/auth/user/forum/questiondetails";
    },
  },
  methods: {
    async fetchQuestionDetails() {
      try {
        const response = await axios.get(`${this.apiEndpoint}/${this.questionId}`, {
          headers: localStorage.getItem("token")
            ? { Authorization: `Bearer ${localStorage.getItem("token")}` }
            : {},
        });

        if (response.data.success) {
          this.question = response.data.data;
        } else {
          this.error = "Soru bulunamadı.";
        }
      } catch (error) {
        console.error("Soru detayları alınırken hata oluştu:", error);
        this.error = "Soru detayları alınırken bir hata oluştu.";
      } finally {
        this.loading = false;
      }
    },
    async deleteAnswer(answerId) {
      const confirmDelete = confirm("Yanıtı silmek istediğinizden emin misiniz?");
      if (!confirmDelete) return;

      try {
        const response = await axios.delete(`/promo/user/forum/answers/${answerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.data.success) {
          this.fetchQuestionDetails();  
        } else {
          alert("Yanıt silinemedi.");
        }
      } catch (error) {
        console.error("Yanıt silinirken hata oluştu:", error);
        alert("Bir hata oluştu, yanıt silinemedi.");
      }
    },
    async vote(answerId, voteType) {
      if (!localStorage.getItem("token")) {
        alert("Oy verebilmek için giriş yapmalısınız.");
        return;
      }

      try {
        const response = await axios.post(
          "/promo/user/forum/vote",
          { answer_id: answerId, vote_type: voteType },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        );
        if (response.data.success) {
          this.fetchQuestionDetails();
        }
      } catch (error) {
        console.error("Oy verirken hata oluştu:", error);
        alert("Oy verirken bir hata oluştu.");
      }
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleDateString("tr-TR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
    }
  },
  mounted() {
    this.fetchQuestionDetails();
    this.currentUserId = Number(localStorage.getItem("user_id")) || null;
  },
};
</script>



<style scoped>
.question-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.question-container {
  background-color: #050505;
  padding: 1rem;
  border-radius: 5px;
  max-width: 1620px;
  width: 100%;
}

.question-title {
  font-weight: 300;
  display: flex;
  align-items: center;
}

.question-meta {
  font-size: 0.7rem;
  color: #707070;
  margin-bottom: 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
}

.question-details-text {
  font-size: 0.8rem; 
  margin-bottom: 15px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
}

.tag {  
  border-radius: 4px;
  font-size: 0.8rem; 
  color: #187774;
}

.answers-container {
  margin-top: 20px;
}

.answer-card {
  background-color: #050505;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border-top: solid 1px #282828;
}

.answer-meta {
  font-size: 0.7rem; 
  color: #707070;
  margin-bottom: 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.yanitlar {
  font-size: 1rem; 
}
.btn-icon{
  max-width: 1.2rem;
  max-height: 1.2rem;
  margin-right: 4px;
} 
.votes-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
}

.votes-container button {
  background-color: transparent;
  border: 1px solid #282828;
  padding: 4px 8px;
  cursor: pointer; 
  font-size: 0.6rem;
  border-radius: 5px;
}

.votes-container button:hover {
  background-color: #023032;
}

.votes-container button.voted {
  background-color: #023032;
  border-color: #023032; 
}

.error-message {
  color: #9b0101;
}
.question-meta, .answer-meta {
  font-weight: 500; 
}

@media (max-width: 600px) {
  .tag {   
   font-size: 0.6rem;  
}
.question-meta, .answer-meta {
  font-weight: 400; 
}
}

.answer-card.my-answer { 
  border-right: 1px solid #66583f;
} 
.delete-answer-button {
  font-weight: 500;
  margin-top: 8px; 
  color: #917e5c; 
  opacity: 0.7;
  cursor: pointer;
}
.delete-answer-button:hover { 
  opacity: 1; 
}

.delete-icon{ height: 8px; margin-right: 5px;}

</style>
