<template>
  <div>
    <div class="sertifikalar-list">
      <div class="sertifikalar-list-head">
        <p class="title">ULUSLARARASI ÜRÜN SERTİFİKALARIMIZ</p>
        <p class="sertifikalar-desc">
          Noah PMU olarak tüm ürünlerimizde uluslararası yüksek standartlar kullanmaktayız. <br>
          Sertifikalarımızdan bazıları aşağıda listelenmiştir. <br> Daha fazlası için <b>info@noahpmu.com</b> ile iletişime geçebilirsiniz. <br><br>
          >> "İndir" butonlarına tıklayarak ilgili dokümanı indirebilirsiniz.
        </p>
      </div> 
      
      <div v-if="documents && documents.length" class="document-icons">
        <div v-for="document in documents" :key="document.document_id" class="document-item">
          <img :src="document.icon_link" alt="Document Icon" class="document-icon" />
          <p class="document-name">{{ document.name }}</p>
          <button @click="downloadDocument(document.document_link)" class="download-button">
            <img src="/assets/download.svg" alt="indir" class="icon" />  İndir
          </button>
        </div>
      </div>
      
      <div v-else class="no-documents">
        <p>Henüz görüntülenecek doküman bulunmamaktadır.</p>
      </div>
    </div> 
  </div>
</template>

<script>
import axios from "axios"; 

export default { 
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    async fetchDocuments() {
      try {
        const response = await axios.get("/auth/generaldocuments");
        this.documents = response.data.documents || []; 
      } catch (error) {
        console.error("Dokümanlar alınırken hata oluştu:", error);
      }
    },
    downloadDocument(documentLink) {
      window.open(documentLink, "_blank");  
    }
  },
  mounted() {
    this.fetchDocuments();
  },
};
</script> 


<style scoped>
.certificates-section {
  margin-top: 20px;
}

.sertifikalar-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 50px 20px; 
}

.sertifikalar-list-head {
  margin-top: 5em;
  width: 100%;
  text-align: center;
}

.sertifikalar-title {
  font-size: 1.9em;
  margin-bottom: 0;
  font-weight: 300;
}

.sertifikalar-desc {
  font-size: 1.2em;
  font-weight: 400;
  color: #757575;
  margin-top: 0.5em;
}

.document-icons { 
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
  max-width: 1600px;
}

.document-item {
  display: flex;
  align-items: center;
  flex-direction: column; 
  max-width: 180px; 
}

.document-icon {
  width: 100px;
  height: 100px;
  cursor: default;
  transition: transform 0.2s ease-in-out;
}

.document-icon:hover {
  transform: scale(1.05);
}

.document-name {
  margin-top: 10px;
  font-size: 1.2em; 
  text-align: center;
}

.no-documents {
  margin-top: 30px;
  font-size: 1.2em;
  color: #757575;
}

@media (max-width: 768px) {
  .document-item { 
    flex: 1 1 calc(48% - 20px);
    font-size: 1em; 
}
  .document-name { 
  font-size: 1.1em;  
}
.sertifikalar-desc {
  font-size: 1em;
  font-weight: 400; 
}
.document-icon {
  width: 90px; 
}
}

button{ max-width: 5rem;
max-height: 1.4rem;
box-shadow: 0 8px 18px rgba(2, 48, 50, 0); 
transition: transform 0.3s ease, box-shadow 0.5s ease; 
} 
button:hover{ box-shadow: 0 2px 8px rgba(133, 133, 133, 0.3); 
}
.icon{ max-width: 0.8em;
max-height: 0.8em;
margin-right: 0.5em;
}
</style>
