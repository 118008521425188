<template> 
  <div class="main-div">
    <div class="noah-chat"> 
      <div v-if="chatRooms.length > 0" class="chat-rooms" v-show="chatRooms.length > 1">
        <div class="rooms-buttons">
          <button
            v-for="room in chatRooms"
            :key="room.id"
            @click="selectRoom(room.id)"
            :class="{ active: room.id === selectedRoomId }"
          >
            {{ room.name }}
          </button>
        </div> 
        <div class="notification-control" v-if="selectedRoom">
          <img 
            :src="require('./icons/notify.svg')"  
            class="notification-icon" 
            @click="toggleNotification(selectedRoom)" 
            :style="{ opacity: selectedRoom.notificationsActive ? 1 : 0.5 }"
          />
        </div>
      </div>
      <div v-else class="no-chat-rooms">Lütfen Bekleyin...</div>

      <div v-if="selectedRoomId" class="chat-window">
        <div ref="messagesContainer" class="messages">
          <div
            v-for="message in messages"
            :key="message.id"
            class="message-bubble"
            :class="{ myMessage: message.user_id === currentUserId }"
          >
            <div class="message-author">
              <span v-if="message.user">
                {{ message.user.user_name }}
                <img 
                  v-if="message.user.verified_level > 0"
                  :src="getVerifiedIcon(message.user.verified_level)"
                  alt="Verified Icon"
                  class="verified-icon"
                />
              </span>
              <span v-else>Anonim</span>
            </div>
            <div class="message-content">{{ message.message }}</div>
            <div class="message-time">{{ formatDateTime(message.created_at) }}</div>
          </div>
        </div>
        <div v-if="isLoggedIn" class="input-area">
          <textarea
            v-model="newMessage"
            @keydown="handleKeydown"
            placeholder="Mesajınız..." 
          ></textarea>
          <button @mousedown.prevent="sendMessage">
            <img src="@/assets/icon/send.svg" alt="Gönder" class="icon" />
          </button> 
        </div>
        <div v-else class="login-prompt">
          Chat'e katılmak için lütfen giriş yapın.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      chatRooms: [],
      selectedRoomId: null,  
      messages: [],
      newMessage: "",
      isLoggedIn: false,
      currentUserId: null,
      notifySound: null,
      notificationStorageKey: 'chat_notifications'
    };
  },
  computed: {
    selectedRoom() {
      return this.chatRooms.find(room => room.id === this.selectedRoomId);
    }
  },
  methods: {
    async fetchChatRooms() {
      try {
        const response = await axios.get("/auth/chatrooms"); 
        const storedNotifications = JSON.parse(localStorage.getItem(this.notificationStorageKey)) || {};
        this.chatRooms = response.data.data.map(room => ({
          ...room, 
          notificationsActive: storedNotifications[room.id] !== undefined ? storedNotifications[room.id] : false, 
        }));
        if (this.chatRooms.length > 0) {
          this.selectRoom(this.chatRooms[0].id);
        }
      } catch (error) {
        console.error("Sohbet odaları alınırken hata oluştu:", error);
      }
    },

    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },

    async fetchMessages(roomId) {
      try {
        const response = await axios.get(`/auth/chatrooms/${roomId}/messages`);
        this.messages = response.data.data;
        this.$nextTick(() => this.scrollToBottom());
      } catch (error) {
        console.error("Mesajlar alınırken hata oluştu:", error);
      }
    },

    selectRoom(roomId) {
      this.selectedRoomId = roomId;  
      this.fetchMessages(roomId);
    },

    async sendMessage() {
      if (!this.newMessage.trim()) return;
      try {
        await axios.post(`/promo/chatrooms/${this.selectedRoomId}/messages`, {
          message: this.newMessage,
          user_id: this.currentUserId,
        });
        this.newMessage = "";
        this.$nextTick(() => {
          const textarea = document.querySelector(".input-area textarea");
          if (textarea) {
            textarea.focus();
          }
        });
      } catch (error) {
        console.error("Mesaj gönderilirken hata oluştu:", error);
      }
    },

    checkLoginStatus() {
      const token = localStorage.getItem("token");
      this.isLoggedIn = !!token;
      this.currentUserId = Number(localStorage.getItem("user_id")) || null;
    },

    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },

    getVerifiedIcon(level) {
      let iconPath = "";
      try { 
        iconPath = require(`@/assets/icon/profile/level/${level}.svg`);
      } catch (e) {
        try { 
          iconPath = require(`@/assets/icon/profile/level/${level}.png`);
        } catch (err) {
          console.error(`Verified icon for level ${level} not found.`);
        }
      }
      return iconPath;
    },

    playNotifySound() {
      if (this.notifySound) {
        this.notifySound.play().catch(err => {
          console.error("Bildirim sesi oynatılırken hata:", err);
        });
      }
    },

    toggleNotification(room) {
      room.notificationsActive = !room.notificationsActive;
      this.updateNotificationStorage(room.id, room.notificationsActive);
    },

    updateNotificationStorage(roomId, status) { 
      const storedNotifications = JSON.parse(localStorage.getItem(this.notificationStorageKey)) || {};
      storedNotifications[roomId] = status;
      localStorage.setItem(this.notificationStorageKey, JSON.stringify(storedNotifications));
    }
  },

  watch: {
    messages: {
      handler() {
        this.$nextTick(() => this.scrollToBottom());
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchChatRooms();
    this.checkLoginStatus();

    try {
      this.notifySound = new Audio(require("@/assets/sounds/notifym2.mp3"));
    } catch (error) {
      console.error("Bildirim sesi yüklenirken hata:", error);
    }

    if (this.$socket) {
      this.$socket.on("newChatMessage", (data) => {
        if (Number(data.room_id) === Number(this.selectedRoomId)) {
          this.messages.push(data);
          this.scrollToBottom();
        }
        const room = this.chatRooms.find(r => Number(r.id) === Number(data.room_id));
        if (room && room.notificationsActive) {
          this.playNotifySound();
        }
      });
    } else {
      console.error("WebSocket bağlantısı kurulamadı.");
    }
  }, 

  beforeUnmount() { 
    if (this.$socket) {
      this.$socket.off("newChatMessage");
    }
  },
};
</script>


<style scoped>
.main-div {
  display: flex; 
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 150px 10px 10px 10px; 
}
.noah-chat {
  display: flex;
  flex-direction: column;  
  max-width: 1620px;
  width: 100%;
}
.chat-rooms {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}

.rooms-buttons {
  display: flex;
  overflow-y: auto;
}

.rooms-buttons button {
  padding: 5px 5px 5px 5px;
  border: none;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(to bottom, #000, #333333);
  cursor: pointer; 
  margin: 2px;
  min-width: 4rem;
}

.rooms-buttons button.active {
  background: linear-gradient(to bottom, #041414, #177270);
  color: white;
}

.notification-control {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.notification-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.no-chat-rooms {
  text-align: center;
  color: gray;
  padding: 20px;
}

.chat-window {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 45rem;
  margin-top: -10px; 
  padding: 15px 5px 5px 5px;
  border-radius: 10px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px;   
}

.message-bubble {
  margin-bottom: 10px;
  padding: 5px 8px;
  border-radius: 5px; 
 
}

.message-bubble.myMessage {
  background-color: #050505; 
} 

.message-author span {
  display: flex; 
  align-items: center; 
}

.verified-icon {
  max-width: 1rem;
  max-height: 1rem; 
  margin-left: 5px;
}

.message-content {
  font-size: 1.1em;
  padding: 2px 0;
}

.message-time {
  text-align: right;
  font-size: 0.8em;
  color: #666666;
}  

.input-area {
  display: flex;
  gap: 10px;
  align-items: center;
}

.input-area textarea {
  height: 4rem;
  min-height: 4rem;
  max-height: 5rem;
  width: 100%;
  padding: 10px;
  border: 1px solid #041414;
  border-radius: 5px; 
  box-sizing: border-box;
}

.input-area button {
  padding: 10px;
  background-color: #023032;  
  border: none;
  cursor: pointer;
}

.icon {
  max-width: 1rem;
}

.login-prompt {
  padding: 10px;
  text-align: center;
  color: gray;
}

@media (max-width: 768px) {
  .chat-window { 
    max-height: 30rem; 
  }
  .main-div { 
    padding: 80px 10px 10px 10px; 
  }
  .message-bubble.myMessage {
  background-color: #030b0b;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.myMessage .message-author span {
  display: flex; 
  align-items: center; justify-content: flex-end;
}
}
</style>
