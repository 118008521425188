<template> 
  <div>
    
  <div class="container"> 

    <router-link to="/" class="box" style="max-width: 5em;">
      <div class="icon">
        <img class="icon" src="@/assets/icon/home.svg" alt="Ana Sayfa Icon" />
      </div>
    </router-link>

    <router-link to="/portal" class="box" style="max-width: 5em;">
      <div class="icon">
        <img class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Portal Icon" />
      </div>
    </router-link>  

 
    <div class="box maxs" @click="setActiveComponent('MyWallets')" :class="{ active: currentComponent === 'MyWallets' }">
      <div class="icon">
        <img class="icon" src="./icons/wallet.svg" alt="Cüzdanlarım" />
      </div>
      <span>Cüzdanlarım</span>
    </div>
     

  <div class="box maxs" @click="setActiveComponent('ArchivedWallets')" :class="{ active: currentComponent === 'ArchivedWallets' }">
      <div class="icon">
        <img class="icon" src="./icons/archive.svg" alt="Arşiv" />
      </div>
      <span>Arşiv</span>
   </div>  
  </div>
  
   <div class="content">
      <component :is="currentComponent" :id="selectedWalletId" />
    </div>

</div>
</template>

<script> 
import ArchivedWallets from './ArchivedWallets.vue';
import MyWallets from './MyWallets.vue';
import WalletDetail from './WalletDetail.vue';

export default {
  components: {
    MyWallets,
    WalletDetail,
    ArchivedWallets
  },
  data() {
    return {
      currentComponent: "MyWallets",
      selectedWalletId: null
    };
  },
  methods: {
    setActiveComponent(component) {
      this.currentComponent = component;
  
      if (component === "MyWallets") { 
        const currentPath = this.$route.path;
        if (currentPath.startsWith("/portal/muhasebe/")) {
          this.$router.push("/portal/muhasebe");
        }
      }
    }
  }, 
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.selectedWalletId = newId;
          this.currentComponent = "WalletDetail";
        } else {
          this.currentComponent = "MyWallets";
          this.selectedWalletId = null;
        }
      }
    }
  }
//YEDEK BUTON
 // <div class="box maxs" @click="setActiveComponent('SharedWallet')" :class="{ active: currentComponent === 'SharedWallet' }">
 //   <div class="icon">
 //     <img class="icon" src="./icons/shared.svg" alt="Ortak cüzdanlarım" />
 //   </div>
 //   <span>Ortak Cüzdanlarım</span>
 // </div>
};
</script>

 
<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 120px;
}
.content{margin-bottom: 4rem;}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 5em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505; 
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparent;  
}

.maxs {
  min-width: 16em;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}
 
.box.active {
  border-bottom: 1px solid #023032;
  color: white; 
} 
.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.box.active .icon {
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    padding-top: 7rem;
    flex-wrap: wrap; 
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
</style> 