<template>
  <div class="showrooms-container">
    <div class="showroom-card" v-for="showroom in showrooms" :key="showroom.id">
      <h3 class="showroom-name">{{ showroom.name }}</h3>
      <p class="showroom-phone" v-if="showroom.phone">
        <a :href="`tel:${showroom.phone}`">
          <img class="icon" src="../assets/icon/world/phone.svg" alt="Telefon" />
          {{ showroom.phone }}
        </a>
      </p> 
      <p class="showroom-instagram" v-if="showroom.instagram">
        <img class="icon" src="../assets/icon/world/instagram.svg" alt="Instagram" />
        {{ showroom.instagram }}
      </p>
      <p class="showroom-address">
        <img class="icon" src="../assets/icon/world/location.svg" alt="Adres" />
        {{ showroom.address }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      showrooms: [], 
    };
  },
  methods: {
    async fetchShowrooms() {
      try {
        const response = await axios.get("/auth/showrooms");
        this.showrooms = response.data.data || [];
        console.log("Showroomlar başarıyla alındı:", this.showrooms);
      } catch (error) {
        console.error("Showroomlar alınırken hata oluştu:", error);
      }
    },
  },
  mounted() {
    this.fetchShowrooms();
  },
};
</script>

<style scoped>
.showrooms-container {
  display: flex;
  min-height: 80vh;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 120px 10px 120px 10px;
}

.showroom-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.333% - 20px);
  min-width: 300px;
  max-width: 400px; 
  min-height: 300px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #050505; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  align-items: center;
  justify-content: center;
}

.showroom-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: black;
}

.showroom-name {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 10px;
}

.showroom-phone,
.showroom-instagram,
.showroom-address {
  font-size: 1em;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .showrooms-container {
    flex-direction: column;
  }
  .showroom-card {
    width: 90%;
  }
}

a{ display: flex; align-items: center; justify-content: center; text-decoration: none; color: #f2f2f2;}
</style>
