<template>
    <div class="answer-form">
      <h3> <img class="btn-icon" src="./icons/send.svg"> Yanıtınızı Paylaşın</h3>  

      <div class="please-login" v-if="!isLoggedIn">
        <p>Yanıt verebilmek için <router-link class="router-link" to="/login">> lütfen giriş yapın</router-link></p>  
      </div>


      <div v-if="successMessage" class="success-container">
        <p class="success-message">{{ successMessage }}</p>
        <button @click="resetForm">Yeni bir yanıt daha ekle</button>
      </div>
   
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
   
      <form v-if="isLoggedIn && !successMessage" @submit.prevent="submitAnswer">
        <textarea v-model="content" placeholder="Yanıtınızı buraya yazın..." required></textarea>
  
        <div class="button-container">
          <button type="submit">Yanıtı Gönder</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "AnswerTheQuestion",
    props: {
      questionId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        content: "",
        successMessage: "",
        errorMessage: "",
      };
    },
    computed: {
      isLoggedIn() {
        return !!localStorage.getItem("token");
      },
    },
    methods: {
      async submitAnswer() {
        this.errorMessage = "";  
  
        try {
          const response = await axios.post(
            "/promo/user/forum/answerthequestion",
            {
              question_id: this.questionId,
              content: this.content,
            },
            {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
          );
  
          if (response.data.success) {
            this.successMessage = "Yanıtınız başarıyla gönderildi ve moderasyon onayına sunuldu.";
            this.content = ""; 
          } else {
            this.errorMessage = response.data.message || "Bilinmeyen bir hata oluştu.";
          }
        } catch (error) {
          console.error("Yanıt gönderirken hata oluştu:", error);
          this.errorMessage =
            error.response?.data?.message || "Yanıt gönderilirken bir hata oluştu.";
        }
      },
      resetForm() {
        this.successMessage = "";
        this.content = "";
      },
    },
  };
  </script>
  
  <style scoped>
  .answer-form {
    margin-top: 20px;
    border-top: #0a4f26 1px solid;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px; 
  }
  
  button {
    background-color: #106e35;
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0a4f26;
  }
  
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .success-message {
    color: #106e35;
    font-weight: bold;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  .login-warning {
    color: red;
    font-weight: bold;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  h3{ font-weight: 400; display: flex; align-items: center;}

  .btn-icon{
  max-width: 1rem;
  max-height: 1rem;
  margin-right: 2px;
} 
 .router-link { color:  #187774; text-decoration: none; font-weight: 500;}
  </style>
  