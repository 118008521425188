<template>
  <div>
    <div class="master-levels-list">
      <div class="master-levels-list-head">
        <p class="master-levels-title">MASTER SEVİYELERİ</p>
        <p class="master-levels-desc">
          Noah PMU olarak profesyonel seviyede uzmanlık gerektiren standartları sağlamaktayız. <br />
          Master seviyelerimiz aşağıda listelenmiştir.
        </p>
      </div>
      <div v-if="masterLevels && masterLevels.length" class="master-levels-icons">
        <div v-for="level in masterLevels" :key="level.level_id" class="master-level-item">
          <img
            v-if="level.icon_link"
            :src="level.icon_link"
            alt="Master Level Icon"
            class="master-level-icon"
          />
          <p class="master-level-name">{{ level.title }}</p>
          <p class="master-level-description" style="font-weight:600;" v-html="level.description"></p>
          <p class="master-level-description" v-if="level.details" v-html="level.details"></p>

          <!-- Sertifika Görselleri -->
          <div v-if="level.certificates && level.certificates.length" class="certificates-thumbnails">
            <img
              v-for="certificate in level.certificates"
              :key="certificate"
              :src="certificate"
              alt="Certificate Thumbnail"
              class="certificate-thumbnail"
              @click="openModal(certificate)"
            />
          </div>
        </div>
      </div>
      <div v-else class="no-master-levels">
        <p>Henüz görüntülenecek master seviyesi bulunmamaktadır.</p>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content">
        <img :src="selectedImage" alt="Certificate Full Size" class="modal-image" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      masterLevels: [],
      showModal: false,
      selectedImage: null,
    };
  },
  methods: {
    async fetchMasterLevels() {
      try {
        const response = await axios.get("/auth/master-levels");
        this.masterLevels = response.data.masterLevels || [];
        console.log("Master seviyeleri başarıyla alındı:", this.masterLevels);
      } catch (error) {
        console.error("Master seviyeleri alınırken hata oluştu:", error);
      }
    },
    openModal(image) {
      this.selectedImage = image;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedImage = null;
    },
  },
  mounted() {
    this.fetchMasterLevels();
  },
};
</script>
  
  <style scoped>
  .master-levels-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 50px 10px 5px 10px;
    margin-bottom: 0;
  }
  
  .master-levels-list-head {
    margin-top: 5em;
    width: 100%;
    text-align: center;
  }
  
  .master-levels-title {
    font-size: 1.9em;
    margin-bottom: 0;
    font-weight: 300;
  }
  
  .master-levels-desc {
    font-size: 1.2em;
    font-weight: 400; 
    margin-top: 0.5em;
  }
  
  .master-levels-icons {
    margin: 50px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

  }
  
  .master-level-item { 
    min-width: 130px; 
    display: flex;  
      flex-direction: column;
      justify-content: center; 
      align-items: center;
     border: 1px solid #282828 ;
      padding: 2em;
      border-radius: 1em;
  }
  .master-level-item:last-child {  
    border: 1px solid #977554;  
  }
  
  .master-level-icon {
    width: 120px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .master-level-icon:hover {
    transform: scale(1.1);
  }
  
  .master-level-name {
    margin: 10px 0 5px 0;
    font-size: 2.5em;
    font-weight: 200; 
    text-align: center;
  }
  
  .master-level-description {
    margin-top: 5px;
    font-size: 1em; 
    text-align: center;
  }
  
  .no-master-levels {
    margin-top: 30px;
    font-size: 1.2em; 
  }
  

  /* Bileşen Temel Stilleri */
.certificates-thumbnails {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.certificate-thumbnail {
  width: 120px;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.certificate-thumbnail:hover {
  transform: scale(1.1);
}

/* Modal Stilleri */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  background: #000000;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #917e5c;
}

.modal-image {
  max-width: 1200px;
  border-radius: 5px;
}
/* Mobil uyum */
@media (max-width: 768px) {
  .modal-content {
    max-width: 95%; 
    max-height: 85%; 
    padding: 5px;
  }

  .modal-image {
    max-width: 100%;  
    max-height: 100%; 
  }
}
  </style>
  