<template>
  <div class="notes-container">
    <div v-if="notes.length === 0" class="no-notes">
      Arşivlenmiş notunuz yok.
    </div>
    <div class="notes-list">
      <div v-for="note in notes" :key="note.id" class="note-card">
        <div class="note-content" @click="openNoteModal(note)">
          <h3>{{ truncate(note.note_title, 50) }}</h3>
          <p>{{ truncate(note.note_text, 100) }}</p>
          <small>Oluşturuldu: {{ formatDateTime(note.created_at) }}</small>
          <small>Güncellendi: {{ formatDateTime(note.updated_at) }}</small>
        </div>
        <div class="note-actions" @click.stop>
          <button class="btn-tr" @click="unarchiveNote(note)">
            <img class="btn-icon" src="./icons/unarchive.svg" alt="Arşivden Çıkar">
          </button>
          <div v-if="confirmDelete === note.id" class="delete-confirmation">
            <button class="delete" @click="deleteNote(note.id)">Sil</button>
            <button @click="cancelDelete">Vazgeç</button>
          </div>
          <button v-else class="delete-btn btn-tr" @click="confirmDelete = note.id">
            <img class="btn-icon" src="./icons/delete.svg" alt="Sil">
          </button>
        </div>
      </div>
    </div>
 
    <div v-if="selectedNote" class="modal" @click.self="closeNoteModal">
      <div class="modal-content">
        <div class="modal-close">
          <button class="close-btn" @click="closeNoteModal">Kapat</button>
        </div>
        <h3>{{ selectedNote.note_title }}</h3>
        <p>{{ selectedNote.note_text }}</p>
        <small>Oluşturuldu: {{ formatDateTime(selectedNote.created_at) }}</small>
        <small>Güncellendi: {{ formatDateTime(selectedNote.updated_at) }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      notes: [],
      confirmDelete: null,
      selectedNote: null
    };
  },
  methods: {
    async fetchArchivedNotes() {
      const response = await axios.get('/promo/user/mynotes/archive');
      this.notes = response.data;
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    formatDateTime(datetime) {
      const date = new Date(datetime);
      return date.toLocaleString();
    },
    async unarchiveNote(note) {
      await axios.put(`/promo/user/mynotes/${note.id}`, { is_archived: false });
      this.fetchArchivedNotes();
    },
    cancelDelete() {
      this.confirmDelete = null;
    },
    async deleteNote(id) {
      await axios.delete(`/promo/user/mynotes/${id}`);
      this.confirmDelete = null;
      this.fetchArchivedNotes();
    },
    openNoteModal(note) {
      this.selectedNote = note;
    },
    closeNoteModal() {
      this.selectedNote = null;
    }
  },
  mounted() {
    this.fetchArchivedNotes();
  }
};
</script>

<style scoped>
.notes-container {
  padding: 1em 0;
}
.notes-list {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap; 
  justify-content: center;
}
.note-card {
  background: #050505;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 728px;
  cursor: pointer;
}
.note-card:hover {
  background: #000;
}
.note-content {
  flex: 1;
}
.note-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.note-actions button {
  margin-left: 5px;
}
.btn-icon {
  max-width: 1.2rem;
  max-height: 1.2rem;
  filter: grayscale(60%); 
}
.no-notes {
  text-align: center;
  color: gray;
  margin-top: 20px;
}
.delete-confirmation button {
  margin-left: 5px;
}
.btn-tr {
  border: none;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
}
.btn-tr:hover {
  transform: scale(1.2);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.modal-content {
  background: #000;
  padding: 20px;
  border-radius: 5px;
  max-width: 728px;
  width: 100%;
  border: 1px solid #282828;
}
.modal-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.close-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}
</style>
