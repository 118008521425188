<template>
  <div class="survey-modal">
    <div class="survey-content">
      <button class="close-button" @click="close">Kapat</button>
    
      <div v-if="!surveyCompleted">
        <div class="survey-info" v-if="surveyData && currentStep === 0">
          <h3 class="title">{{ surveyData.title }}</h3>
          <p class="duration"><b>Süre:</b> {{ surveyData.duration }} Dakika</p>
          <p>{{ surveyData.description }}</p>
          <p v-if="surveyData.amount != null && surveyData.currency_name != null"><b>Ödül:</b> {{ surveyData.amount }} {{ surveyData.currency_name }}</p>
          <button class="step-button" @click="nextStep">Başla</button>
        </div>
    
        <div v-else-if="questions.length && currentStep > 0 && currentStep <= questions.length">
          <div v-if="currentQuestion">
            <p class="title">Soru - {{ currentStep }} / {{ questions.length }}</p>
            <h3 class="question">{{ currentQuestion.question_text }}</h3>
      
            <div class="multiple_choice" v-if="currentQuestion.question_type === 'multiple_choice'">
              <div class="option-check" v-for="option in currentQuestion.options" :key="option.option_id">
                <input 
                  type="checkbox" 
                  :name="'question' + currentStep" 
                  :value="option.option_id" 
                  :checked="answers[currentQuestion.question_id] === option.option_id"
                  @change="handleCheckboxChange(option.option_id, currentQuestion.question_id)"> 
                <span style="margin-left: 0.5em;">{{ option.option_text }}</span>
              </div>
            </div>
      
            <div v-else-if="currentQuestion.question_type === 'text'">
              <input 
                type="text" 
                :name="'question' + currentStep" 
                v-model="answers[currentQuestion.question_id]" 
                maxlength="250" 
                placeholder="Minimum 3, maksimum 250 karakter">
            </div>
          </div>
<div class="controls">         
  <button class="step-button" @click="previousStep" v-if="currentStep > 1">Önceki</button>
  <button class="step-button" @click="nextStep" :disabled="!answers[currentQuestion.question_id]">Sonraki</button>
</div>
        </div>
      </div>

      <div class="thanks" v-if="surveyCompleted">
        <img :src="require(`@/assets/discover/survey-completed.svg`)"/> 
        <h3>Ankete katıldığınız için teşekkür ederiz!</h3>
        <button class="step-button" @click="close">Kapat</button>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  props: {
    surveyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      surveyData: {},
      questions: [],
      currentStep: 0,
      answers: {},
      surveyCompleted: false
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentStep - 1];
    }
  },
  mounted() {
    this.fetchSurveyData();
  },
  methods: {
    handleCheckboxChange(selectedOptionId, questionId) {
      if (this.answers[questionId] === selectedOptionId) {
        this.answers[questionId] = null;
      } else {
        this.answers[questionId] = selectedOptionId;
      }
    },
    async fetchSurveyData() {
      try {
        const response = await axios.get(`/auth/survey/${this.surveyId}`);
        this.surveyData = response.data;
        this.questions = response.data.questions;
      } catch (error) {
        console.error('Anket verileri alınırken hata oluştu:', error);
      }
    },
    nextStep() {
      if (this.currentStep < this.questions.length) {
        this.currentStep++;
      } else {
        this.submitSurvey();
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    async submitSurvey() {
      try {
        const token = localStorage.getItem('token');
        let url = '/auth/survey/submit-responses';
        if (token) {
          url = '/promo/survey/submit-responses';
        }
        await axios.post(url, {
          surveyId: this.surveyId, 
          answers: this.answers
        }, token ? { headers: { Authorization: `Bearer ${token}` } } : {});
        
        this.surveyCompleted = true; 
        localStorage.setItem(`surveyCompleted_${this.surveyId}`, 'true');
      } catch (error) {
        console.error('Anket yanıtları gönderilirken hata oluştu:', error);
 
        if (error.response && error.response.data && error.response.data.message === "Bu ankete zaten katıldınız.") {
 
          this.surveyCompleted = true; 
          localStorage.setItem(`surveyCompleted_${this.surveyId}`, 'true');
          alert('Bu ankete zaten katıldınız.');
        } else if (error.response && error.response.status === 400) {
          alert('Bu ankete zaten katıldınız.');
        } else {
          alert('Yanıtlarınızı gönderirken bir hata oluştu. Lütfen tekrar deneyin.');
        }
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

 
<style scoped>
.survey-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a; 
  z-index: 10000;
}

.survey-content {
  max-width: 1000px;
  width: 100%;
  background-color: #000;
  padding: 3em 1em;
  margin: 0.5em;
  border-radius: 1em;
  min-height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.survey-info { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title { 
  font-size: 1.2rem;
  font-weight: 600;
} 
.question {  
  font-weight: 500;
} 
.multiple_choice input{
  max-width: 2em;
}
.controls { 
  display: flex; 
  justify-content: space-between;
  align-items: center;
}
.step-button { 
  padding: 0.5em 2em;
  margin: 1em 0.5em;
  min-width: 9em;
  background-color: #0e311d; 
  border: none;
  border-radius: 2em;
  cursor: pointer;
  transition: background-color 0.4s;
} 
.step-button:hover {
  background-color: #005c1f; 
}
.option-check {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  text-align: left;
} 
.thanks { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
.thanks img { 
max-height: 5em;
}

input[type="text"] {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: none; 
}

input[type="text"]:focus {
  outline: none;
  border-color: #e6e6e6;
}
.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  margin: 1em 0.5em;
  padding: 0.5em 2em;
  min-width: 9em;
  background-color: #9b0101; 
  border: none;
  border-radius: 2em;
  cursor: pointer;
}
.close-button:hover {
  background-color: #d00000;
}
</style>