<template>
  <div>
    <AdvancedMenu
      :isAdvancedMode="isAdvancedMode"
      @toggleMode="isAdvancedMode = $event"
    /> 
    <div class="application-list">
      <div
        v-for="application in applications"
        :key="application.application_id"
        class="application-item"
        :class="{
          'application-inactive': (!application.is_active) || (application.is_login_required && !loggedIn)
        }"
        @click="(application.is_active && (!application.is_login_required || loggedIn)) ? emitNavigate(application.version) : null"
      >
        <img 
          :src="require(`@/assets/icon/application/${application.version}.svg`)"
          class="application-icon" 
        />
        <span>{{ application.name }}</span>
        <span
          v-if="application.is_login_required && !loggedIn && application.is_active"
          @click.stop="emitGoLogin"
          style="cursor: pointer; color: #187774;"
        >
          Lütfen Giriş Yapınız
        </span>
        <span v-if="!application.is_active" class="coming-soon">Çok Yakında</span>
      </div>
      <p v-if="applications.length === 0">Lütfen Bekleyin...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AdvancedMenu from './AdvancedMenu.vue'

export default {
  name: "ApplicationList",
  components: {
    AdvancedMenu
  },
  props: {
    applications: Array,
    loggedIn: Boolean
  },
  data() {
    return {
      isAdvancedMode: false
    };
  },
  mounted() {
    this.fetchAdvancedMode();
  },
  methods: {
    async fetchAdvancedMode() {
      try {
        const response = await axios.get('/promo/portal/advanced-mode');
        this.isAdvancedMode = response.data.is_advanced_mode_on;
      } catch (error) {
        console.error("Mod durumu alınamadı:", error);
      }
    },
    toggleMode() {
      this.isAdvancedMode = !this.isAdvancedMode;
    },
    emitNavigate(version) {
      this.$emit("navigateToApplication", version);
    },
    emitGoLogin() {
      this.$emit("goLogin");
    }
  }
};
</script>


<style scoped>  
.application-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px; 
}

.application-item {
  background: linear-gradient(45deg, #020202, #0a0a0a, #020202);  
  flex: 1 1 calc(25% - 20px);  
  max-width: 300px; 
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer; 
  font-size: 1.2em;
  padding: 7px;
  font-weight: 300;
  transition: transform 0.3s ease, box-shadow 0.5s ease, color 0.2s ease; 
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(2, 48, 50, 0);  
  border: 1px solid transparent;
}

.application-inactive {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: default;
  color: #dadada;
}

.application-item:not(.application-inactive):hover {
  color: white;
  transform: scale(1.04);
  opacity: 1;
  box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5);
}

.application-item img {
  opacity: 1;
}

.application-item:hover img {
  opacity: 1;
}

.application-inactive:hover img {
  filter: grayscale(100);
}

.application-icon {
  max-height: 3em;
  margin: 10px;
}

.coming-soon {
  font-size: 0.9em;
  color: #808080;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .application-item {
    flex: 1 1 calc(33.33% - 20px);
  }
}

@media (max-width: 768px) {
  .application-item {
    flex: 1 1 calc(50% - 22px); 
    box-shadow: 0 8px 18px rgba(2, 48, 50, 0.2); 
    font-size: 1em;
  }
  .application-icon {
    max-height: 3em; 
  }
  .application-list { 
    padding: 10px 10px 10px 10px; 
  } 
}

@media (max-width: 359px) {
  .application-item {
    flex: 1 1 calc(100% - 20px);
  }
} 
</style> 