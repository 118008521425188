<template>
  <div class="activity-container">  
    <h3>Hesap Aktivileri</h3>
    <template v-if="!isLoading">
      <div v-if="activities.length === 0">Henüz bir aktivite yok.</div>

      <table class="activity-table">
        <thead>
          <tr>
            <th>Aktivite</th>
            <th>Tarih</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in activities" :key="activity.id">
            <td>{{ activity.activity }}</td>
            <td>{{ formatDateTime(activity.created_at) }}</td>
          </tr>
        </tbody>
      </table>

      <button v-if="!isFetchingAll && !isAllFetched" @click="fetchAllActivities" class="fetch-all-btn">Tüm Aktiviteleri Getir</button>
    </template>

    <div v-else>Yükleniyor...</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      activities: [], 
      isLoading: true,  
      isFetchingAll: false,  
      isAllFetched: false,  
    };
  },
  methods: {
    async fetchActivities() {
      try {
        const response = await axios.get("/promo/myactivity");
        this.activities = response.data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Aktiviteler alınırken hata oluştu:", error);
      }
    },

    async fetchAllActivities() {
      this.isFetchingAll = true;
      try {
        const response = await axios.get("/promo/myactivity/all");
        this.activities = response.data.data;
        this.isFetchingAll = false;
        this.isAllFetched = true; 
      } catch (error) {
        console.error("Tüm aktiviteler alınırken hata oluştu:", error);
        this.isFetchingAll = false;
      }
    }
  },
  mounted() {
    this.fetchActivities(); 
  },
};
</script>

<style scoped>
.activity-container {
  max-width: 1600px;
  margin: 0 auto; 
  border-radius: 8px; 
} 

table {
  border-collapse: collapse;
  margin-bottom: 20px;
}
 
th {
  border-bottom: 1px solid #636363;
}
 
</style>
