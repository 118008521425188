<template>
  <div style="padding: 120px 5px;"> 
    <NoahMasters />
  </div>
</template>

<script> 
import NoahMasters from './NoahMasters.vue';

export default {
  components: { 
    NoahMasters
  }
};
</script>
