<template>
  <div class="main-div"> 
    <NoahLoader :isLoading="isLoading" />
 
    <div class="container" v-if="!isLoading">
      <div
        class="box"
        v-for="(item, index) in slides"
        :key="item.id"
        @click="updateSubCategory(index)"
        :class="{ active: selectedCategory?.id === item.id }"
      > 
        <div class="icon" v-html="item.icon"></div>
        {{ item.name }}
      </div>
    </div>
 
    <SubCategory
      v-if="selectedCategory && !isLoading"
      :selectedCategory="selectedCategory"
    />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import SubCategory from './SubCategory.vue';
import NoahLoader from '../NoahLoader.vue';

export default {
  name: 'ProductMain',
  components: {
    SubCategory,
    NoahLoader
  },
  setup() {
    const slides = ref([]);
    const selectedCategory = ref(null);
    const isLoading = ref(true);

    const fetchSubCategories = async () => {
      try {
        const response = await axios.get('/auth/sub-categories');
        slides.value = response.data.subCategories
          .map((subcategory) => {
            // XML bildirimi varsa temizle
            let cleanedIcon = subcategory.icon
              ? subcategory.icon.replace(/<\?xml.*?\?>/, '').trim()
              : '';

            return {
              id: subcategory.id,
              name: subcategory.name,
              icon: cleanedIcon,
              sira_no: subcategory.sira_no
            };
          }) 
          .sort((a, b) => (a.sira_no ?? 9999) - (b.sira_no ?? 9999));
 
        if (slides.value.length > 0) {
          selectedCategory.value = slides.value[0];
        }
        isLoading.value = false;
      } catch (error) {
        console.error('Alt kategoriler yüklenirken hata:', error);
        isLoading.value = false;
      }
    };

    const updateSubCategory = (index) => {
      if (slides.value[index]) {
        selectedCategory.value = slides.value[index];
      }
    };

    onMounted(fetchSubCategories);

    return {
      slides,
      selectedCategory,
      isLoading,
      updateSubCategory
    };
  }
};
</script>

<style scoped>
.main-div {  
  padding-top: 10rem;
}

.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px; 
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1.5em;
  gap: 0.5em;
  min-width: 5em;
  min-height: 2em;
  border-radius: 2px;
  text-decoration: none;
  background-color: #050505;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}

.box.active {
  border-bottom: 1px solid #187774;
  color: white;
} 
.icon {
  display: inline-block;
  width: 1rem; 
  height: 1rem;
  opacity: 0.8;
}
 
.icon svg {
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .main-div {  
  padding-top: 7rem;
}
}
</style>
