<template>
  <div class="container">
    <div class="main">
      <h4>Cüzdan geçmişi</h4>
      <div class="filter">
        <select v-model="selectedPeriod" @change="handlePeriodChange">
          <option value="daily">Günlük</option>
          <option value="weekly">Haftalık</option>
          <option value="monthly">Aylık</option>
          <option value="custom">Özel Tarih</option>
        </select>
  
        <div v-if="selectedPeriod === 'custom'" class="date-range">
          <label for="date">Başlangıç</label>
          <input type="date" v-model="startDate" />
          <label for="date">Bitiş</label>
          <input type="date" v-model="endDate" />
          <button class="green" @click="fetchTransactions">Uygula</button>
        </div>
      </div>
  
      <table>
        <thead>
          <tr>
            <th>Tür</th>
            <th>Açıklama</th>
            <th>Miktar</th>
            <th>Tarih</th>
            <th class="hide">Oluşturulma Tarihi</th>
            <th class="hide">Güncellenme Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions" :key="transaction.id">
            <td :style="{ color: transaction.type === 'income' ? '#106e35' : '#9b0101' }">
              <select v-if="editingId === transaction.id" v-model="transaction.type">
                <option value="income">Giriş</option>
                <option value="expense">Çıkış</option>
              </select>
              <span v-else>{{ transaction.type === 'income' ? 'Giriş' : 'Çıkış' }}</span>
            </td>
            <td>
              <input v-if="editingId === transaction.id" v-model="transaction.description" />
              <span v-else>{{ transaction.description }}</span>
            </td>
            <td>
              <input v-if="editingId === transaction.id" v-model="transaction.amount" type="number" />
              <span v-else>{{ formatCurrency(transaction.amount, transaction.currency_id) }}</span>
            </td>
            <td>
              <input v-if="editingId === transaction.id" type="date" v-model="transaction.transaction_date" />
              <span v-else>{{ formatDate(transaction.transaction_date) }}</span>
            </td>
            <td class="hide">{{ formatDateTime(transaction.created_at) }}</td>
            <td class="hide">{{ formatDateTime(transaction.updated_at) }}</td>
            <td class="btns">
              <button v-if="editingId === transaction.id" @click="saveTransaction(transaction)">Kaydet</button>
              <button v-else @click="editTransaction(transaction.id)">Güncelle</button>
              <button class="delete" @click="deleteTransaction(transaction.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div class="totals">
        <p><b style="font-weight: 400;">Toplam Giriş:</b> {{ formatCurrency(totalIncome, detectedCurrencySymbol) }}</p>
        <p><b style="font-weight: 400;">Toplam Çıkış:</b> {{ formatCurrency(totalExpense, detectedCurrencySymbol) }}</p>
        <p :style="{ color: totalDifference >= 0 ? '#106e35' : '#9b0101' }">
          <b style="font-weight: 400;">Fark:</b> {{ formatCurrency(totalDifference, detectedCurrencySymbol) }}
        </p>
      </div>
    </div> 
    </div>
  </template>
  
  
  <script>
  import axios from "axios";
  import { eventBus } from "@/utils/eventBus";  
  
  export default {
    props: ['space_id'],
    data() {
      return {
        transactions: [],
        currencies: [],
        selectedPeriod: 'daily',
        editingId: null,
        startDate: null,
        endDate: null
      };
    },
    computed: {
      totalIncome() {
        return this.transactions
          .filter(t => t.type === 'income')
          .reduce((sum, t) => sum + parseFloat(t.amount), 0);
      },
      totalExpense() {
        return this.transactions
          .filter(t => t.type === 'expense')
          .reduce((sum, t) => sum + parseFloat(t.amount), 0);
      },
      totalDifference() {
        return this.totalIncome - this.totalExpense;
      }, 
      detectedCurrencySymbol() { 
        return this.transactions.length ? this.transactions[0].currency_id : '';
      }
    },
    watch: {
      selectedPeriod(newVal) {
        if (newVal !== 'custom') {
          this.startDate = null;
          this.endDate = null;
        }
      }
    },
    methods: {
      async fetchTransactions() {
        try {
          const params = {
            period: this.selectedPeriod
          };
  
          if (this.selectedPeriod === 'custom' && this.startDate && this.endDate) {
            params.start_date = this.startDate;
            params.end_date = this.endDate;
          }
  
          const response = await axios.get(
            `/promo/hs/user/mt-transactions/${this.space_id}`,
            { params }
          );
          
          this.transactions = response.data.data;
        } catch (error) {
          console.error("İşlemler alınırken hata oluştu:", error);
        }
      },
      async deleteTransaction(id) {
        if (confirm("Bu işlemi silmek istediğinizden emin misiniz?")) {
          try {
            await axios.delete(`/promo/hs/user/mt-transactions/${id}`);
            this.fetchTransactions();
          } catch (error) {
            console.error("İşlem silinirken hata oluştu:", error);
            this.fetchTransactions();
          }
        }
      },
      handlePeriodChange() {
        if (this.selectedPeriod !== 'custom') {
          this.fetchTransactions();
        }
      },
      async fetchCurrencies() {
        try {
          const response = await axios.get('/auth/currencies');
          this.currencies = response.data;
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
      editTransaction(id) {
        this.editingId = id;
      },
      async saveTransaction(transaction) {
        try {
          const payload = {
            ...transaction,
            transaction_date: formatDateForBackend(transaction.transaction_date)
          };
          
          await axios.put(`/promo/hs/user/mt-transactions/${transaction.id}`, payload);
          this.editingId = null;
          this.fetchTransactions();
        } catch (error) {
          console.error("İşlem kaydedilirken hata oluştu:", error);
        }
      },
      getCurrencySymbol(currencyId) {
        const currency = this.currencies.find(c => c.currency_id === currencyId);
        return currency ? currency.currency_symbol : '';
      },
      formatCurrency(amount, currencyId) {
        const symbol = this.getCurrencySymbol(currencyId) || '';
        return new Intl.NumberFormat("tr-TR", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount) + ` ${symbol}`;
      },
      formatDate(date) {
        const d = new Date(date);
        return d.toLocaleDateString("tr-TR");
      },
      formatDateTime(dateTime) {
        const d = new Date(dateTime);
        return d.toLocaleString("tr-TR");
      }
    },
    mounted() {
      this.fetchTransactions();
      this.fetchCurrencies();
      eventBus.on("reloadHistory", this.fetchTransactions);
    },
    beforeUnmount() {
      eventBus.off("reloadHistory", this.fetchTransactions);
    },
  };
  
  function formatDateForBackend(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
  </script>
  
  
  
  <style scoped>
   .container{
    display: flex;
    justify-content: center;
  }
 .main{
    max-width: 1600px;
    width: 100%;
    padding: 1em;
    background-color: #050505;
  }
  .btns{
    display: flex;
    flex-wrap: wrap;
  }
  .filter {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .date-range {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
   
  .totals {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-weight: 500;
    font-size: 1em;
  }

  input[type="date"] {
    padding: 5px;
  } 
  button {
    margin-right: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .green {
    background-color: #106e35;
  }
  @media (max-width: 600px) {
    .hide {
        display: none;
    }
  }
  </style>