<template>
    <div class="wallet-stats">
      <div class="summary">
        <h3>{{ selectedPeriodLabel }} Özet</h3>
        <div class="date-range"> 
          <input type="date" v-model="startDate" /> 
          <input type="date" v-model="endDate" />
        </div>
        <select v-model="selectedPeriod" @change="updateDates">
          <option value="daily">Günlük</option>
          <option value="weekly">Haftalık</option>
          <option value="monthly">Aylık</option>
          <option value="yearly">Yıllık</option>
          <option value="custom">Özel Tarih</option>
        </select>
      </div>
  
      <div class="details">
        <p :class="differenceClass">{{ stats.difference || "0" }}</p>
        <p>Giriş: <strong>{{ stats.total_income || "0" }}</strong></p>
        <p>Çıkış: <strong>{{ stats.total_expense || "0" }}</strong></p>
      </div>
  
      <p v-if="loading">Yükleniyor...</p>
      <p v-else-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { eventBus } from "@/utils/eventBus";  

  export default {
    props: ["id"],
    data() {
      return {
        stats: {},
        selectedPeriod: "daily",
        startDate: "",
        endDate: "",
        loading: false,
        error: null,
      };
    },
    computed: {
      selectedPeriodLabel() {
        const labels = {
          daily: "Günlük",
          weekly: "Haftalık",
          monthly: "Aylık",
          yearly: "Yıllık",
          custom: "Özel Tarih",
        };
        return labels[this.selectedPeriod] || "Günlük";
      },
      differenceClass() {
        const diff = parseFloat(this.stats.difference?.replace(/[^0-9.-]+/g, "")) || 0;
        return diff >= 0 ? "positive" : "negative";
      }
    },
    watch: {
      startDate() {
        this.selectedPeriod = "custom";
        this.fetchStats();
      },
      endDate() {
        this.selectedPeriod = "custom";
        this.fetchStats();
      },
    },
    methods: {
      async fetchStats() {
        this.loading = true;
        this.error = null;
  
        let params = { period: this.selectedPeriod };
        if (this.startDate && this.endDate) {
          params.start_date = this.startDate;
          params.end_date = this.endDate;
        }
  
        try {
          const response = await axios.get(`/promo/hs/user/mt-space-stats/${this.id}`, { params });
          this.stats = response.data.data;
        } catch (error) {
          console.error("İstatistikler alınırken hata oluştu:", error);
          this.error = "İstatistikler alınamadı.";
        } finally {
          this.loading = false;
        }
      },
      updateDates() {
        const today = new Date();
        let start = new Date();
        let end = new Date();
  
        switch (this.selectedPeriod) {
          case "daily":
            start = end = today;
            break;
          case "weekly":
            start.setDate(today.getDate() - today.getDay());
            end.setDate(start.getDate() + 6);
            break;
          case "monthly":
            start = new Date(today.getFullYear(), today.getMonth(), 1);
            end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            break;
          case "yearly":
            start = new Date(today.getFullYear(), 0, 1);
            end = new Date(today.getFullYear(), 11, 31);
            break;
        }
  
        this.startDate = start.toISOString().split("T")[0];
        this.endDate = end.toISOString().split("T")[0];
  
        this.fetchStats();
      },
    },
    mounted() {
      this.updateDates();
      eventBus.on("reloadHistory", this.fetchStats);
    },
    beforeUnmount() {
      eventBus.off("reloadHistory", this.fetchStats);
    },
  };
  </script>
  
  
  <style scoped>
  .wallet-stats {
    display: flex;
    
    gap: 1em;
    flex-wrap: wrap;
  } 
  .summary {
    margin-bottom: 10px;
  }
  .date-range {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  .details {
    margin-top: 10px;
    font-size: 1.1em;
  }
  .positive {
  color: #106e35;font-size: 1.2rem;
}

.negative {
  color: #9b0101; font-size: 1.2rem; 
}
  </style>
  