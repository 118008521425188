<template>
    <div class="auth-portal-head">
        <img @click="goLogin" class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Portal Icon" /> 
        <p class="title">NOAH <b>PORTAL</b></p> 
    </div>
  </template>

<script>
export default {
  name: "AuthPortalHead",
  methods: {
    goLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
.auth-portal-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.information { 
  text-align: center;
  padding: 10px;
  color: #05ad68;
}
button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}
.icon {
  max-width: 5em;
  padding: 30px 10px 0px 10px;
  opacity: 0.8;
  animation: fadeInUp 2s;
}
.title { 
  padding: 0px 10px 30px 10px;
  font-weight: 300;
}
.icon:hover {
  animation: heartbeat 1.2s infinite;
}

@media (max-width: 768px) {
    .title { 
      padding: 0; 
    }
  }
</style> 