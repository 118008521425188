<template>
  <div style="display: flex; justify-content: center;">
 
  <div class="unsolved-questions"> 

    <div style="display: flex; align-items: center; justify-content: flex-end; gap: 8px; margin-bottom: 10px;">
      <img 
        src="./icons/hot.svg" 
        alt="Popüler" 
        style="width: 1.5em; cursor: pointer;" 
        @click="toggleSortByViews"
        :style="{ opacity: sortByViews ? 1 : 0.4 }"
      /> 
    </div>
    <span>Konular: </span>
    <div class="tag-filters">
      <button @click="resetFilter" :class="{ active: selectedTag === null }">
        #Hepsi
      </button>
      <button
        v-for="tag in uniqueTags"
        :key="tag.tag_id"
        @click="filterByTag(tag.tag_name)"
        :class="{ active: selectedTag === tag.tag_name }"
      >
        #{{ tag.tag_name }}
      </button> 
    </div>
 
    <div v-if="filteredQuestions.length > 0">
      <div v-for="question in filteredQuestions" :key="question.question_id" class="question-card" @click="openQuestionDetails(question.question_id)">
         
          
          <p class="question-title"> 
            
            <img 
               v-if="question.is_important" 
               src="@/components/forum/icons/important.svg" 
               alt="Önemli" 
               class="important-icon"
             />
             <img v-if="!question.is_important" class="title-icon" src="./icons/head.svg"> 
             {{ question.title }}</p> 
     
        <p class="tag" v-if="question.tags.length > 0"> 
          <span v-for="tag in question.tags" :key="tag.tag_id" class="tag">
            #{{ tag.tag_name }}
          </span>
        </p> 
        <p class="question-meta">
           <span>{{ question.username }}</span> |
           <span>{{ formatDateTime(question.created_at) }}</span>  |  
           <img class="btn-icon" src="./icons/eye.svg">{{ question.view_count }}  |  
          <img class="btn-icon" src="./icons/send.svg">{{ question.total_answers }} 
         </p>

      </div>
    </div> 

    <div v-else>
      <p>Çözülmemiş soru bulunamadı.</p>
    </div>
  </div>

</div>
</template>

<script>
import axios from "axios";
import { eventBus } from '@/utils/eventBus';

export default {
  name: "UnsolvedQuestions",
  data() {
    return {
      questions: [],
      selectedTag: null, 
      sortByViews: false
    };
  },
  computed: { 
    filteredQuestions() {
      let filtered = this.selectedTag
        ? this.questions.filter((q) =>
            q.tags.some((tag) => tag.tag_name === this.selectedTag)
          )
        : this.questions;

      if (this.sortByViews) {
        filtered = [...filtered].sort((a, b) => b.view_count - a.view_count);
      }

      return filtered;
    },
    
    uniqueTags() {
      const allTags = this.questions.flatMap((q) => q.tags);
      const unique = [...new Map(allTags.map((tag) => [tag.tag_id, tag])).values()];
      return unique;
    }
  },
  methods: {
    async fetchQuestions() {
      try {
        const response = await axios.get("/auth/user/questions/unsolved");
        this.questions = response.data.data || [];
      } catch (error) {
        console.error("Çözülmemiş sorular alınırken hata oluştu:", error);
      }
    },
    filterByTag(tag) {
      this.selectedTag = tag;
    },
    resetFilter() {
      this.selectedTag = null;
    },
    openQuestionDetails(questionId) {
      eventBus.emit('openQuestion', questionId);
    },
    toggleSortByViews() {
      this.sortByViews = !this.sortByViews;
    }
  },
  mounted() {
    this.fetchQuestions();
  }
};
</script>


<style scoped>
.unsolved-questions {
  display: flex;
  flex-direction: column;
  gap: 5px; 
  max-width: 1620px;
  width: 100%;
}

/* Tag Butonları */
.tag-filters {
  display: flex;
  gap: 0.1rem;
  margin-bottom: 0.1rem;
  flex-wrap: wrap;
}

.tag-filters button {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem; 
  border-radius: 0;
  border: 1px solid #022121;
  background-color: #022121; 
  cursor: pointer;
  transition: background 0.3s ease;
}

.tag-filters button:hover {
  background-color: #050505;
}

/* Seçili Olan Tag */
.tag-filters button.active {
  background-color: #050505; 
  color: white;
}

/* Soru Kartı */
.unsolved-questions {
    display: flex;
    flex-direction: column; 
    gap: 5px; 
    padding: 0.2rem; 
  } 
  .question-card {
    background-color: #050505;  
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;   
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  }
  .question-card:hover {
    background-color: black;  
    box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);  
    cursor: pointer;
  }
  .question-title{  
    font-size: 1rem; 
    font-weight: 500;
    margin: 0; 
    padding: 0;
  } 
  .tag {   
    font-size: 0.8rem;  
    margin: 0;
    color: #7c7c7c;
  }
 
.important-icon {
  width: 1rem; 
  height: 1rem;
}
.question-meta { 
  margin-bottom: 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
}
.btn-icon{
  max-width: 1.2rem;
  max-height: 1em;
  margin-right: 4px;
} 
.title-icon{
  max-width: 1rem;
  max-height: 1em; 
} 
@media (max-width: 768px) {
  .tag-filters button { 
    font-size: 0.6rem;  
  }
  .question-title{  
    font-weight: 500; 
    font-size: 1.2em;
  }
  .question-metabr{ 
    font-size: 0.6rem; 
  }
  .tag {   
    font-size: 0.6rem;  
  }
}

</style>
