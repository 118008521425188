<template>
    <div>
      <UserTests v-if="isAuthenticated" /> 
        <div v-else class="warning-container" @click="redirectToLogin">
          <img 
            src="@/assets/icon/education/warn.svg" 
            alt="Warning Icon" 
            class="warning-icon" 
          />
          <p>Lütfen giriş yapın</p> 
        </div> 
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import UserTests from './UserTests.vue';
  
  export default {
    name: 'EducationMain',
    components: {
      UserTests
    },
    setup() {
      const isAuthenticated = ref(false);
      const router = useRouter();
  
      const redirectToLogin = () => {
        router.push('/login');
      };
  
      onMounted(() => {
        const token = localStorage.getItem('token');
        if (token) {
          isAuthenticated.value = true;
        } else {
          setTimeout(() => {
            router.push('/login');
          }, 3000); 
        }
      });
  
      return {
        isAuthenticated,
        redirectToLogin
      };
    }
  };
  </script>
  
  <style scoped> 
  .warning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    border: 1px solid #065959;
    padding: 20px;
    max-width: 300px;
    margin: 150px auto; 
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .warning-container:hover {
    scale: 1.01;
    background-color: black;
  }
  .warning-icon {
    max-width: 50px;
    margin-bottom: 15px;
  } 
  </style> 