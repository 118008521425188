<template>
  <div class="chat-container">
    <div class="model-select">
      <select v-model="selectedGptId">
        <option v-for="model in gptModels" :key="model.id" :value="model.id">
          {{ model.name }}
        </option>
      </select> 
      <img
        src="@/assets/icon/new-chat-plus.svg"
        alt="Yeni Sohbet Başlat"
        class="new-chat-icon"
        @click="newChat"
        style="cursor: pointer; margin-left: 10px;"
      />
    </div>

 






     
    <div class="chat-window" ref="chatWindow">
      <div v-if="messages.length === 0" class="welcome-message">
        <b style="font-size: 1.4em; font-weight: 600;">Merhaba, ben Noah Aura!</b> <br>
        Size nasıl yardımcı olabilirim?
      </div>


      <div v-for="message in messages" :key="message.id" :class="['message', message.role]">
        <div v-html="formatText(message.text)"></div>
      
        <div v-if="message.role === 'assistant'" class="message-footer"> 
          <img 
            src="@/assets/icon/message-feedback.svg" 
            class="chat-footer-icon" 
            @click="openFeedbackModal(message.id, chatId)"
          />
          <transition name="fade">
            <img 
              v-if="!temporarilyDisabled.includes(message.id)"
              src="@/assets/icon/copy-chat.svg" 
              class="chat-footer-icon" 
              @click="handleCopy(message.id, message.text)"
            />
          </transition>
        </div>
      </div>
      
         
    </div>

    <div class="chat-input">
      <textarea
        v-model="userMessage"
        :placeholder="loggedIn ? 'Mesajınızı yazın...' : 'Lütfen Giriş Yapın.'"
        :disabled="!loggedIn"
        @keyup.enter.exact="sendMessage"
        @keydown.shift.enter.prevent="newLine"
        class="chat-textarea"
        :style="chatTextareaStyle"
      ></textarea>
      
      <button
        @mousedown.prevent="sendMessage"
        class="send-button"
        :disabled="!loggedIn"
      >
        <img src="@/assets/icon/ai-send.svg" alt="Gönder" class="send-icon" />
      </button>
    </div>
    <div class="warn"> 
      <img src="@/assets/icon/warn.svg" class="warn-icon" />
        <p>AURA hata yapabilir. Lütfen önemli bilgileri kontrol edin.</p>  
    </div>



    <MessageFeedBack
      v-if="showFeedbackModal"
      :message-id="selectedMessageId"
      :chat-id="selectedChatId"
      @close="closeFeedbackModal"
    />
    
    
    
  </div>
</template>

<script>



// <div class="history-container">
//       <div @click="toggleHistory" class="history-button" ref="historyButtonRef">
//         <img src="@/assets/icon/history.svg" class="history-icon" />
//       </div>
//     
//       <transition name="fade">
//         <div 
//           v-if="showHistory" 
//           class="history-dropdown" 
//           ref="historyDropdownRef"
//           @click.stop
//         >
//           <UserChats @chat-selected="loadChat" />
//         </div>
//       </transition>
//     </div>

 /* eslint-disable */


import axios from "axios";
import { ref, reactive, onMounted, onBeforeUnmount, nextTick, computed } from "vue";
import UserChats from './UserChats.vue';
import MessageFeedBack from './MessageFeedBack.vue';

export default {
  components: { UserChats, MessageFeedBack },
  setup() {
    // Dropdown kontrolü
    const showHistory = ref(false);
    const historyDropdownRef = ref(null);
    const historyButtonRef = ref(null);

    // Chat ve mesajlar
    const messages = ref([]);
    const userMessage = ref("");
    const chatId = ref(null);
    const selectedGptId = ref(null);
    const gptModels = ref([]);
    const chatWindow = ref(null);

    // Feedback Modal kontrolü
    const showFeedbackModal = ref(false);
    const selectedMessageId = ref(null);
    const selectedChatId = ref(null);

    // Kullanıcının giriş yapıp yapmadığını tutan değişken
    const loggedIn = ref(false);

    onMounted(() => {
      // Token kontrolü
      loggedIn.value = !!localStorage.getItem("token");
      // Diğer onMounted kodlarınız...
    });

    // AURA modellerini getir
    const fetchGPTModels = async () => {
      try {
        const response = await axios.get("/auth/user/noahai/models");
        gptModels.value = response.data.models;
        if (gptModels.value.length > 0) {
          selectedGptId.value = gptModels.value[0].id;
        }
      } catch (error) {
        console.error("Aura modelleri alınırken hata:", error);
      }
    };

    // Sohbet geçmişini aç/kapat
    const toggleHistory = () => {
      showHistory.value = !showHistory.value;
    };

    const handleClickOutside = (event) => { 
      if (
        showHistory.value &&
        !historyDropdownRef.value?.contains(event.target) &&
        !historyButtonRef.value?.contains(event.target)
      ) {
        showHistory.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      fetchGPTModels();
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    // Geçmiş sohbeti yükle
    const loadChat = async (chat) => {
      chatId.value = chat.id;
      messages.value = chat.messages;  
      scrollToBottom();
      showHistory.value = false;
    };
    

    // Scroll işlemi
    const scrollToBottom = () => {
      nextTick(() => {
        if (chatWindow.value) {
          chatWindow.value.scrollTop = chatWindow.value.scrollHeight;
        }
      });
    };

    // Mesaj gönderme
    const sendMessage = async () => {

      if (!loggedIn.value || !userMessage.value.trim()) return;

      if (!userMessage.value.trim()) return;

      if (!chatId.value) {
        const startChatResponse = await axios.post("/promo/user/noahaura/chat/start", {
          gpt_id: selectedGptId.value,
        });
        chatId.value = startChatResponse.data.chat_id;
      }

      messages.value.push({ id: Date.now(), text: userMessage.value, role: "user" });
      scrollToBottom();
      const tempMessage = userMessage.value;
      userMessage.value = "";

      const loaderMessage = reactive({ id: Date.now() + 1, text: "", role: "assistant" });
      messages.value.push(loaderMessage);
      scrollToBottom();

      let dotCount = 0;
      const loaderInterval = setInterval(() => {
        dotCount = (dotCount % 3) + 1;
        loaderMessage.text = ".".repeat(dotCount);
        scrollToBottom();
      }, 500);

      try {
        const response = await axios.post(`/promo/user/noahaura/chat/${chatId.value}/message`, {
          text: tempMessage,
        });
        clearInterval(loaderInterval);
         
        const reply = response.data.reply;
        const backendMessageId = response.data.message_id;
         
        loaderMessage.text = "";
        loaderMessage.id = backendMessageId; 
        let currentIndex = 0;
        const typingInterval = setInterval(() => {
          if (currentIndex < reply.length) {
            loaderMessage.text += reply[currentIndex];
            currentIndex++;
            scrollToBottom();
          } else {
            clearInterval(typingInterval);
          }
        }, 20);
        
      } catch (error) {
        clearInterval(loaderInterval);
        console.error("Mesaj gönderilirken hata:", error);
        loaderMessage.text = "Hata oluştu.";
      }

      nextTick(() => {
        const textarea = document.querySelector(".chat-textarea");
        if (textarea) {
          textarea.focus();
        }
      });
    };

    const newLine = () => {
      userMessage.value += "\n";
    };

    const newChat = () => {
      messages.value = [];
      chatId.value = null;
      userMessage.value = "";
    }; 
     const temporarilyDisabled = ref([]);
      
     const handleCopy = (messageId, text) => {
       copyToClipboard(text);
     
       temporarilyDisabled.value.push(messageId);
       setTimeout(() => {
         temporarilyDisabled.value = temporarilyDisabled.value.filter(id => id !== messageId);
       }, 2000); 
     };
     
    const copyToClipboard = (text) => {
      if (!text) return;
    
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = formatText(text);
      const plainText = tempDiv.innerText.trim();
    
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(plainText).catch(() => {
          fallbackCopy(plainText);
        });
      } else {
        fallbackCopy(plainText);
      }
    };
     
    const fallbackCopy = (plainText) => {
      const textarea = document.createElement('textarea');
      textarea.value = plainText;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }; 

    const openFeedbackModal = (messageId, chatIdVal) => {
      selectedMessageId.value = messageId;
      selectedChatId.value = chatIdVal;
      showFeedbackModal.value = true;
    };

    const closeFeedbackModal = () => {
      showFeedbackModal.value = false;
      selectedMessageId.value = null;
      selectedChatId.value = null;
    };

    const chatTextareaStyle = computed(() => {
      const selectedModel = gptModels.value.find(model => model.id === selectedGptId.value);
      if (selectedModel && selectedModel.name.includes("Beta")) {
        return { backgroundColor: "#160023" };
      }
      return {};
    });

    const formatText = (text) => {
      if (!text) return "";
      return text.replace(/\*\*(.*?)\*\*/g, '<span style="font-weight:600;">$1</span>');
    };

    return {
      showHistory,
      toggleHistory,
      loadChat,
      historyDropdownRef,
      historyButtonRef,
      messages,
      userMessage,
      sendMessage,
      loggedIn,
      chatId,
      selectedGptId,
      gptModels,
      newLine,
      chatWindow,
      chatTextareaStyle,
      formatText,
      newChat,
      copyToClipboard,
      openFeedbackModal,
      closeFeedbackModal,
      showFeedbackModal,
      selectedMessageId,
      selectedChatId,
      handleCopy,
      temporarilyDisabled
    };
  },
};
</script>
 
  <style scoped>
  .chat-container { 
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto; 
    justify-content: center;
    min-height: 50vh;
  }
  
  .model-select {
    margin-bottom: 10px; 
    display: flex;
    align-items: center;
  }
  select {
    max-width: 10rem;
    padding: 0.5rem; 
    border-radius: 4px;  
  } 
  .chat-window {  
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column; 
    max-height: 330px;
  }
  
  .welcome-message { 
    text-align: center;
    padding: 20px 0;
  }
  
  .message {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    max-width: 70%;
  }
  
  .user {
    align-self: flex-end;
    background-color: black;
    border-radius: 15px 0 15px 15px;
    padding: 15px
  }
  
  .noahaura {
    align-self: flex-start; 
  }
  
  .chat-input {
    display: flex;
    align-items: center;  
    margin-top: 10px;
    box-sizing: border-box;
  }
  
  .chat-textarea {
    flex-grow: 1;
    color: white;
    border: none;
    padding: 1rem 1rem;
    background-color: black;
    border-radius: 1rem;
    height: auto;
    min-height: 5rem; 
    max-height: 15rem; 
    overflow-y: auto;  
    resize: none;
    box-sizing: border-box;
  }
  
  .chat-textarea:focus {
    outline: none;
  }
  
  .send-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .send-icon { 
    width: 2rem;
    height: 2rem;
    transition: transform 0.3s ease-in-out; 
    opacity: 0.8;
  } 
  .send-icon:hover {
    transform: scale(1.2); 
    opacity: 1;
  }
  .new-chat-icon { 
    width: 1.3rem;
    height: 1.3rem;
    transition: transform 0.3s ease-in-out; 
    opacity: 0.8;
  }
  .new-chat-icon:hover {
    transform: scale(1.1); 
    opacity: 1;
  }

  .warn { 
    display: flex;
    align-items: center;  
    padding: 0 1em; 
    font-size: 0.8em;
    color: #919191;
  } 
  .warn-icon {
    max-width: 1rem;
    margin-right: 1em;
  }

  @media (max-width: 768px) {
    .chat-textarea {
    flex-grow: 1;
    color: white;
    border: none;
    padding: 1rem 1rem;
    background-color: black;
    border-radius: 1rem;
    height: auto;
    min-height: 5rem; 
    max-height: 15rem; 
    overflow-y: auto;  
    resize: none;
    box-sizing: border-box;
  }
  .warn { 
      padding: 0 1em; 
      font-size: 0.8em;
      color: #919191;
    } 
  }
  
.history-container {
  position: relative;
  display: inline-block; 
} 
.history-button {
  background: #050505;
  border:none;
  padding: 1em 0.5em ;
  max-width: 2em;
  max-height: 2em;
  border-radius: 0 10px 10px   0;
  cursor: pointer; 
}
.history-icon { 
    width: 1.3rem;
    height: 1.3rem;
    transition: transform 0.3s ease-in-out; 
    opacity: 0.8;
  }
  .history-icon:hover {
    transform: scale(1.1); 
    opacity: 1;
  }
.history-dropdown {
  position: absolute;
  top: 2.5rem;
  left: 0; 
  box-shadow: 0 2px 8px rgba(0,0,0,0.5);
  border-radius: 0 1rem 1rem 0;
  width: 15rem;
  max-height: 22rem;
  overflow-y: auto;
  z-index: 100;
  border: 1px solid #353535;
  padding: 2px;
  background: #050505; 
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
} 


.message-footer {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.chat-footer-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.2s ease-in-out;
}

.chat-footer-icon:hover {
  opacity: 1;
  transform: scale(1.2);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style> 