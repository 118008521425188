<template>
  <div id="app">  
    <MainHeader v-if="showGlobalLayout" data-no-translate />
    <TopMenu v-if="showGlobalLayout" data-no-translate />
    <SideMenu v-if="showGlobalLayout" data-no-translate /> 
     
    <div class="content" data-translate-target>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component 
            :is="Component" 
            class="router-view"
            data-translate-container
          />
        </transition>
      </router-view>
    </div>
 
    <MainFooter v-if="showGlobalLayout" data-translate-target />
    <LanguageMenu v-if="showGlobalLayout" data-no-translate />
    <MaintenanceModal v-if="isMaintenanceMode && showGlobalLayout" data-no-translate />
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
import SideMenu from './components/menus/SideMenu.vue';
import MaintenanceModal from './components/MaintenanceModal.vue';
import TopMenu from './components/menus/TopMenu.vue';
import LanguageMenu from './components/menus/LanguageMenu.vue';
import axios from 'axios';
import { eventBus } from './utils/eventBus';

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
    SideMenu,
    MaintenanceModal,
    TopMenu,
    LanguageMenu
  },
  data() {
    return {
      isMaintenanceMode: true,
      observer: null,
      translationCache: new Map(),
      debounceTimer: null,
      currentLanguage: 'tr',
      originalTexts: new Map(),
      showGlobalLayout: true,  
    };
  },
  created() {
  this.checkMaintenanceMode();
 this.startTokenCheck(); 
    eventBus.on('kasolcum-visible', this.handleKasolcumVisibility);
  },
  mounted() {
    this.captureOriginalTexts();
    this.setupLanguageListener();
    this.observeContentChanges();
  },
  beforeUnmount() {
    this.disconnectObserver();
    eventBus.off('language-changed', this.handleLanguageChange);
    eventBus.off('kasolcum-visible', this.handleKasolcumVisibility);
  },
  methods: { 
    async checkMaintenanceMode() {
      try {
        const response = await axios.get('/auth/web-status/1');
        this.isMaintenanceMode = !response.data?.is_active;
      } catch (error) {
        console.error('Maintenance check failed:', error);
        this.isMaintenanceMode = true;
      }
    },
    startTokenCheck() {
      setInterval(() => {
        if (!localStorage.getItem('token')) {
          this.$router.push('/login');
        }
      }, 200);
    },
    setupLanguageListener() {
      eventBus.on('language-changed', this.handleLanguageChange);
      const savedLang = localStorage.getItem('selectedLanguage') || 'tr';
      this.handleLanguageChange(savedLang);
    },
    handleLanguageChange(lang) {
      this.currentLanguage = lang;
      localStorage.setItem('selectedLanguage', lang);
      if (lang === 'tr') {
        this.restoreOriginalTexts(); 
        this.disconnectObserver(); 
      } else {
        this.translatePage(lang); 
        this.observeContentChanges(); 
      }
    }, 
    handleKasolcumVisibility(isKasolcumVisible) { 
      this.showGlobalLayout = !isKasolcumVisible;
    },
    captureOriginalTexts() {
      const nodes = this.prepareTextNodes(document.documentElement).textNodes;
      nodes.forEach(node => {
        this.originalTexts.set(node, node.textContent);
      });
    },
    restoreOriginalTexts() {
      this.originalTexts.forEach((value, node) => {
        node.textContent = value;
      });
    }, 
    async translatePage(targetLang) {
      const targets = document.querySelectorAll('[data-translate-target]');
      if (!targets.length) return;
    
      for (const content of targets) {
        const { textNodes, combinedText } = this.prepareTextNodes(content);
        if (!textNodes.length) continue;
    
        try {
          const translatedText = await this.fetchTranslation(combinedText, targetLang);
          this.applyTranslations(textNodes, translatedText);
        } catch (error) {
          console.error('Translation failed:', error);
        }
      }
    }, 
    prepareTextNodes(root) {
      const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, {
        acceptNode: (node) => {
          if (node.parentElement.closest('[data-no-translate]')) {
            return NodeFilter.FILTER_REJECT;
          }
          return this.filterTextNode(node);
        }
      });
      const textNodes = [];
      while (walker.nextNode()) textNodes.push(walker.currentNode);
      return {
        textNodes,
        combinedText: textNodes.map(n => n.textContent.trim()).join('\n')
      };
    },
    filterTextNode(node) {
      const forbiddenTags = ['SCRIPT', 'STYLE', 'TEXTAREA'];
      const parentTag = node.parentElement.tagName.toUpperCase();
      return node.textContent.trim() && 
             !forbiddenTags.includes(parentTag) &&
             !node.textContent.match(/\.(svg|png|jpg|jpeg)/i);
    },
    async fetchTranslation(text, targetLang) {
      const cacheKey = `${targetLang}-${text}`;
      const cachedTranslation = localStorage.getItem(cacheKey);
      if (cachedTranslation) {
        return cachedTranslation;
      }
      const response = await axios.post('/auth/translatewithai', {
        text,
        sourceLang: 'tr',
        targetLang: targetLang
      });
      if (response.data?.translatedText) {
        localStorage.setItem(cacheKey, response.data.translatedText);
        return response.data.translatedText;
      }
      throw new Error('Invalid translation response');
    },
    applyTranslations(textNodes, translatedText) {
      const translations = translatedText.split('\n');
      textNodes.forEach((node, index) => {
        if (translations[index]) {
          node.textContent = translations[index];
        }
      });
    },
    observeContentChanges() {
      const content = document.querySelector('[data-translate-target]');
      if (!content) return;
      this.observer = new MutationObserver(this.handleMutation);
      this.observer.observe(content, {
        childList: true,
        subtree: true,
        characterData: true
      });
    },
    handleMutation(mutations) {
      if (this.currentLanguage === 'tr') return;
      if (mutations.some(m => {
        const element = m.target.nodeType === Node.TEXT_NODE 
          ? m.target.parentElement 
          : m.target;
        return element?.closest?.('[data-no-translate]');
      })) return;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.translatePage(this.currentLanguage);
      }, 300);
    },
    disconnectObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    }
  }
};
</script>


<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>