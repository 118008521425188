<template>
  <div class="container">
    <div style="text-align: center;"> 
      <h1>Logo</h1>
    </div>

    <div class="brand-kit">
      <div class="logo-box" v-for="logo in logos" :key="logo.id">
        <img :src="logo.svg" class="logo" alt="Logo" />
        <div class="button-container">
          <button @click="download(logo.svg, 'svg')">SVG</button>
          <button @click="download(logo.svg, 'png')">PNG</button>
        </div>
      </div>
    </div>

    <div class="font">
      <h1>Font</h1>
      <h3>Quick brown fox jumps over the lazy dog</h3>
      <p>Quick brown fox jumps over the lazy dog</p>
      <div class="button-container">
        <button @click="redirectToGoogleFonts">Google Fonts</button>
        <button @click="downloadFile"> <img src="/assets/download.svg" alt="indir" class="icon" /> Poppins.zip</button>
      </div>
    </div>

    <div class="colors">
      <h1>Renkler</h1>
      <div class="color-row">
        <div class="color-box" v-for="color in mainColors" :key="color.name" :style="{ backgroundColor: color.code }">
          <p>{{ color.code }}</p>
        </div>
      </div>
      <div class="color-row">
        <div class="color-box" v-for="shade in shades" :key="shade.name" :style="{ backgroundColor: shade.code }">
          <p>{{ shade.code }}</p>
        </div>
      </div>
    </div> 
    
    <div class="emblems">
      <div class="emblem-box" v-for="emblem in emblems" :key="emblem.id">
        <img :src="getEmblemImage(emblem, 'transparent')" class="emblem" alt="Emblem" />
        <div class="button-container">
          <button @click="downloadEmblem(emblem, 'black')"> <img src="/assets/download.svg" alt="indir" class="icon" />Siyah Zemin</button>
          <button @click="downloadEmblem(emblem, 'white')"><img src="/assets/download.svg" alt="indir" class="icon" />Beyaz Zemin</button>
          <button @click="downloadEmblem(emblem, 'transparent')"><img src="/assets/download.svg" alt="indir" class="icon" />Transparan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        { id: 1, svg: '/assets/brand/logo-white.svg' },
        { id: 2, svg: '/assets/brand/logo-black.svg' },
        { id: 3, svg: '/assets/brand/gradyan-center.svg' },
        { id: 4, svg: '/assets/brand/gradyan-side.svg' },
      ],
      mainColors: [
        { name: 'Color 1', code: '#050505' },
        { name: 'Color 2', code: '#121212' },
        { name: 'Color 3', code: '#065959' },
        { name: 'Color 4', code: '#106e35' },
        { name: 'Color 5', code: '#4d270e' },
        { name: 'Color 6', code: '#5f0362' },
        { name: 'Color 7', code: '#9b0101' },
      ],
      shades: [
        { name: 'Shade 1', code: '#0a0a0a' },
        { name: 'Shade 2', code: '#282828' },
        { name: 'Shade 3', code: '#187774' },
        { name: 'Shade 4', code: '#1cc655' },
        { name: 'Shade 5', code: '#815328' },
        { name: 'Shade 6', code: '#b900bf' },
        { name: 'Shade 7', code: '#d00000' },
      ],
  //    emblems: [
  //      { id: 1, name: 'Elite Master', path: '/assets/masters/Elite Master' },
  //      { id: 2, name: 'Master', path: '/assets/masters/Master' },
  //      { id: 3, name: 'Expert', path: '/assets/masters/Expert' },
  //      { id: 4, name: 'Rise', path: '/assets/masters/Rise' },
  //    ],
    };
  },
  methods: {
    download(svgPath, format) {
      const fileName = svgPath.split('/').pop().replace('.svg', `.${format}`);

      let filePath = svgPath;
      if (format === 'png') {
        filePath = svgPath.replace('.svg', '.png');
      }

      const link = document.createElement('a');
      link.href = filePath;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    redirectToGoogleFonts() {
      window.open("https://fonts.google.com/specimen/Poppins?selection.family=Montserrat&query=Poppins", '_blank');
    },
    downloadFile() {
      const url = '/downloads/Poppins.zip';
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getEmblemImage(emblem, type) {
      return `${emblem.path}-${type}.png`;
    },
    downloadEmblem(emblem, type) {
      const filePath = `${emblem.path}-${type}.png`;
      const fileName = `${emblem.name}-${type}.png`;

      const link = document.createElement('a');
      link.href = filePath;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>


<style scoped> 
.container{
  padding: 150px 1em;
}
.icon{
  max-height: 0.8em; margin-right: 4px;
}
h1{
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
}
.brand-kit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
}

.logo-box {
  width: calc(25% - 10px); 
  margin-bottom: 20px; 
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8); 
  border-radius: 1em;
}
.logo-box:nth-child(2) { 
  background-color: #8d8d8d; 
  color: black;
}

.logo-box:nth-child(2) button { 
  background-color: #a0a0a0; 
  color: black;
}

.logo-box:hover { 
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6); 
}
.logo { 
  max-width: 125px;
}

.font {
  width: 100%;
  box-sizing: border-box; 
  font-size: 1.4em;
  margin: 3em 0em;
  padding: 3em;
  background-color: #050505;
  border-radius: 1em;
  text-align: center;
}

.button-container { 
  margin-top: 1em; 
  display: flex; 
  justify-content: center;
  flex-wrap: wrap;
}
button {
  margin-top: 1em;
  background: none;
  border: none;  
  cursor: pointer;
}
button:hover {  
  text-decoration: underline;
}

.colors {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0em 5em 0; 
}

.color-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1em;
  gap: 1em;
}

.color-box {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 5em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); 
}


.color-box p {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 1em;
  word-wrap: break-word;
}
@media (max-width: 600px) {
  .logo-box {
    width: calc(50% - 10px); 
  }
  .container{
  padding:120px  0.5em;
}
.color-box {
  width: 4em;
  height: 4em; 
  border-radius: 5em; 
}
.color-box p { 
  font-size: 0.5em; 
}
} 

.emblems {
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 1em;
}
.emblem-box {
  text-align: center; 
  background-color: #050505;
  padding: 20px;
  border-radius: 8px;
}

.emblem {
  max-width: 100px; 
  height: auto;
} 
</style>
  