<template>
    <div class="container">
      <h2 class="title">Kullanıcı Sözleşmeleri ve Politika Onayları</h2>
      <p class="section-description">
        Anlaşmalarımızı sizin için kategorize ettik. Daha fazla sorunuz varsa, legal@noahpmu.com adresinden ya da destek ekibimizden bizimle iletişime geçebilirsiniz.
      </p>
  
      <div class="search-container">
        <input
          type="text"
          v-model="searchText"
          placeholder="Sözleşmelerde Arayın"
          class="search"
          @input="filterAgreements"
        /> 
      </div>
  
      <div v-for="(category, cIndex) in filteredAgreementCategories" :key="cIndex" class="accordion">
        <div class="accordion-header" @click="toggleCategory(cIndex)"> 
          {{ category.name }}
        </div>
  
        <div v-show="category.expanded" class="category-content">
          <div v-for="(agreement, aIndex) in category.agreements" :key="aIndex" class="accordion">
  
            <div class="agreement-header" @click="toggleAgreement(cIndex, aIndex)">
              <span class="accordion-icon">{{ agreement.expanded ? '-' : '+' }}</span>
              {{ agreement.agreement_name }}
            </div>
  
            <div v-show="agreement.expanded" class="accordion-content">
              <p v-html="agreement.content"></p> 
              <p><strong>Durum:</strong> {{ agreement.is_active ? 'Yürürlükte' : 'Yürürlükte Değil' }} <strong>Güncelleme Tarihi:</strong> {{ formatDateTime(agreement.updated_at) }}</p> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios'; 
  
  export default {
    data() {
      return {
        agreementCategories: [],
        searchText: '',
        filteredAgreementCategories: []
      };
    },
    async mounted() {
      await this.fetchAgreements();
    },
    methods: {
      async fetchAgreements() {
        try {
          const response = await axios.get('/auth/agreements');
          const agreementsFromApi = response.data.agreements;
  
          const categories = [...new Set(agreementsFromApi.map(agreement => agreement.category))];
  
          this.agreementCategories = categories.map(category => ({
            name: category,
            expanded: true,
            agreements: agreementsFromApi
              .filter(agreement => agreement.category === category)
              .map(agreement => ({
                agreement_name: agreement.agreement_name,
                content: agreement.content,
                order_number: agreement.order_number,
                updated_at: agreement.updated_at,
                is_active: agreement.is_active,
                expanded: false
              }))
          }));
  
          this.filteredAgreementCategories = [...this.agreementCategories];
        } catch (error) {
          console.error('Anlaşmalar alınırken hata oluştu:', error);
        }
      },
      toggleCategory(index) {
        this.filteredAgreementCategories[index].expanded = !this.filteredAgreementCategories[index].expanded;
      },
      toggleAgreement(cIndex, aIndex) {
        this.filteredAgreementCategories[cIndex].agreements[aIndex].expanded = !this.filteredAgreementCategories[cIndex].agreements[aIndex].expanded;
      },
      filterAgreements() {
        if (!this.searchText.trim()) {
          this.filteredAgreementCategories = [...this.agreementCategories];
          return;
        }
  
        const searchLower = this.searchText.toLowerCase();
        this.filteredAgreementCategories = this.agreementCategories
          .map(category => {
            const filteredAgreements = category.agreements.filter(agreement =>
              agreement.agreement_name.toLowerCase().includes(searchLower) ||
              agreement.content.toLowerCase().includes(searchLower)
            );
            return { ...category, agreements: filteredAgreements };
          })
          .filter(category => category.agreements.length > 0);
      }, 
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin: 120px 5px;
    padding: 1.5em;
    background-color: #0a0a0a;
  } 
  .section-title { 
    margin-bottom: 1em;
  }
  
  .section-description { 
    margin-bottom: 2em;
  }
   
  .accordion {
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .accordion-header {
    cursor: pointer;
    display: flex;
    align-items: center; 
    margin-bottom: 1em;
    font-weight: 600;
    font-size: 1.2em;  
  } 
  
  .accordion-header:hover {
    color: #ffffff;
  }
  
  .accordion-icon {
    margin-right: 10px;
    font-size: 1.2em;
  }
  
  .category-content{
    padding-left: 2em;
    margin-bottom: 2em;
  } 
  .accordion-content {
    padding: 15px;  
    border: solid 1px #1a262b;
    border-top: 1px solid #065959;
  }
  .agreement-header {
    cursor: pointer;
    display: flex;
    align-items: center; 
    margin-bottom: 0.5em;
    font-weight: 300;
    font-size: 1em;  
  } 
  
  .agreement-header:hover {
    color: #ffffff;
  }
  .accordion-content p {
    margin-bottom: 1em;
  } 
  
  @media (max-width: 480px) { 
    .accordion-header {
      font-size: 1em;
    }
  }
  </style>
  