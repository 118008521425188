<template>
    <div class="wallet-container">
      <div class="wallet-list">
        <div 
          v-for="space in mtSpaces" 
          :key="space.id" 
          class="wallet-card" 
          @click="goToWalletDetail(space.id)"
        >
          <div class="wallet-header">
            {{ space.name }}

            <div class="settings-box">
              <img 
                src="./icons/archive.svg" 
                alt="Arşivle" 
                class="settings-icon" 
                @click.stop="archiveWallet(space.id)"
              />

              <img 
                src="./icons/settings.svg" 
                alt="Ayarlar" 
                class="settings-icon" 
                @click.stop="openSettings(space)"
              />
              
            </div>
          </div>
          <div class="wallet-desc"> 
            {{ space.description }} 
          </div>
          <div class="wallet-currency">
            {{ getCurrencyName(space.currency_id) }}
          </div>
        </div>

        <div class="wallet-card add-wallet" @click="showModal = true"> 
          <img class="icon" src="./icons/plus.svg"/>
          <p>Yeni Cüzdan</p>
        </div> 
      </div>
   
      <div v-if="showModal" class="overlay" @click="showModal = false">
        <div class="modal" @click.stop>
          <h3>Yeni Cüzdan Ekle</h3>
          <label>Cüzdan Adı <span style="color: #917e5c">(Maksimum 50 Karakter)</span>:</label><div class="emptyfive"></div>
          <input v-model="newMtSpace.name" type="text" maxlength="50" placeholder="Örn. Ofis Giderleri"/> <div class="emptytwenty"></div>
  
          <label>Açıklama <span style="color: #917e5c">(Maksimum 250 Karakter)</span>:</label><div class="emptyfive"></div>
          <textarea v-model="newMtSpace.description" maxlength="250" placeholder="Örn. Genel ofis giderleri cüzdanım"></textarea><div class="emptytwenty"></div>
  
          <label>Para Birimi:</label><div class="emptyfive"></div>
          <select v-model="newMtSpace.currency_id">
            <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
              {{ currency.currency_name }} ({{ currency.currency_symbol }})
            </option>
          </select>
  
          <div class="modal-buttons">        
            <button class="confirm"  @click="createMtSpace">Yeni Cüzdan Oluştur</button>
            <button class="delete" @click="showModal = false">İptal</button>
          </div>
        </div>
      </div> 
   
      <div v-if="settingsModal" class="overlay" @click="settingsModal = false"> 
        <div class="modal" @click.stop>
          <h3>Cüzdan Ayarları</h3>
          <label>Cüzdan Adı:</label>
          <input v-model="selectedWallet.name" type="text" maxlength="50"/>
  
          <label>Açıklama:</label>
          <textarea v-model="selectedWallet.description" maxlength="250"></textarea>
  
          <div class="modal-buttons">        
            <button class="confirm" @click="updateMtSpace">Kaydet</button>
            <button class="delete" @click="settingsModal = false">İptal</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        mtSpaces: [],
        currencies: [],
        showModal: false,
        settingsModal: false,
        selectedWallet: null,
        newMtSpace: {
          name: '',
          description: '',
          currency_id: null
        }
      };
    },
    methods: {
      async fetchMtSpaces() {
        try {
          const response = await axios.get('/promo/user/mt-spaces');
          this.mtSpaces = response.data.data;
        } catch (error) {
          console.error("MtSpaces alınırken hata oluştu:", error);
        }
      },
      async archiveWallet(mtspace_id) {
        try {
          await axios.put(`/promo/user/mt-spaces/${mtspace_id}/archive`);
          this.fetchMtSpaces(); 
        } catch (error) {
          console.error("MtSpace arşivlenirken hata oluştu:", error);
        }
      },
      async fetchCurrencies() {
        try {
          const response = await axios.get('/auth/currencies');
          this.currencies = response.data;
          if (this.currencies.length > 0) {
            this.newMtSpace.currency_id = this.currencies[0].currency_id; 
          }
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
      async createMtSpace() {
        try {
          await axios.post('/promo/user/mt-spaces', this.newMtSpace);
          this.fetchMtSpaces();
          this.showModal = false;
        } catch (error) {
          console.error("MtSpace oluşturulurken hata oluştu:", error);
        }
      },
      async updateMtSpace() {
        try {
          if (!this.selectedWallet) return;
          await axios.put(`/promo/user/mt-spaces/${this.selectedWallet.id}`, {
            name: this.selectedWallet.name,
            description: this.selectedWallet.description
          });
          this.fetchMtSpaces();
          this.settingsModal = false;
        } catch (error) {
          console.error("MtSpace güncellenirken hata oluştu:", error);
        }
      },
      getCurrencyName(currency_id) {
        const currency = this.currencies.find(c => c.currency_id === currency_id);
        return currency ? currency.currency_name : 'Bilinmeyen';
      },
      goToWalletDetail(id) {
        this.$router.push({ name: 'WalletDetail', params: { id } });
      },
      openSettings(wallet) {
        this.selectedWallet = { ...wallet };
        this.settingsModal = true;
      }
    },
    mounted() {
      this.fetchMtSpaces();
      this.fetchCurrencies();
    }
  };
  </script>
  
  <style scoped>
  .wallet-container {
    padding: 10px 0;
    display: flex; 
    justify-content: center;
  }
  
  .wallet-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;  
    max-width: 1600px;
    width: 100%;
  }
  
  .wallet-card {
    background: linear-gradient(45deg, #020202, #080808, #020202);
    max-width: 380px;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  }
  
  .wallet-card:hover {
    background: linear-gradient(45deg, #010101, #0a0a0a, #020202);  
    transform: scale(1.0);
    color: white;
    box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5); 
  }
  
  .wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 1.4em;
  }
  
  .wallet-desc {
    font-weight: 400;
    font-size: 1em;
    margin-top: 0.3rem;
  }
  
  .wallet-currency {
    font-weight: 400;
    font-size: 0.9em;
    margin-top: 1rem;
  }
  .settings-box{
    display: flex;
    gap: 15px;
    padding: 1em 0; 
  }
  .settings-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .settings-icon:hover {
    transform: scale(1.2);
  }
  
  .add-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #050505;
    font-size: 1.2em;
    font-weight: 300;
    max-width: 10rem;
  }
  
  .icon { 
    max-width: 1.5rem;  
    margin: 0.5em; 
  }
  
  .add-wallet p {   
    margin: 0.5em; 
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  
  .modal {
    background: #000;
    padding: 2rem;
    margin: 0.5rem;
    border-radius: 8px;
    border: 1px solid #022121; 
    max-width: 600px;
    width: 100%;
  } 
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
 
  </style>
  