<template>
  <div class="notes-container"> 
    <div v-if="errorMessage" class="error-box">
      {{ errorMessage }}
      <button @click="errorMessage = ''" class="error-close">X</button>
    </div>
 
    <div class="head-buttons">

      <button class="add-note-btn" @click="showCreateModal = true">
        <img style="margin-right: 0.5em;" class="btn-icon" src="./icons/new-note.svg" alt="Yeni Not"> Yeni Not Ekle
      </button>
<div>      <input 
        v-model="searchQuery" 
        placeholder="Notlarda ara..." 
        class="search"
      /></div>
    </div>
 
    <div v-if="filteredNotes.length === 0" class="no-notes">Not bulunamadı</div>
    <div class="notes-list">
      <div v-for="note in filteredNotes" :key="note.id" class="note-card">
        <div class="note-content" @click="openNoteModal(note)">
          <h3>{{ truncate(note.note_title, 50) }}</h3>
          <p>{{ truncate(note.note_text, 100) }}</p>
          <small>Oluşturuldu: {{ formatDateTime(note.created_at) }}</small>
          <small>Güncellendi: {{ formatDateTime(note.updated_at) }}</small>
          <small v-if="note.reminder" class="reminder">
            Hatırlatıcı: <span class="active-reminder">Açık</span> | {{ formatDateTime(note.reminder) }}
          </small>
        </div>
        <div class="note-actions" @click.stop>
          <button class="btn-tr" @click="openReminderModal(note)">
            <img class="btn-icon" :src="note.reminder ? require('@/components/mynotes/icons/remove-reminder.svg') : require('@/components/mynotes/icons/reminder.svg')" alt="Hatırlatıcı">
          </button>
          <button class="btn-tr" @click="toggleArchive(note)">
            <img class="btn-icon" src="./icons/archive.svg" alt="Arşivle">
          </button>
          <div v-if="confirmDelete === note.id" class="delete-confirmation">
            <button class="delete" @click="deleteNote(note.id)">Sil</button>
            <button @click="cancelDelete">Vazgeç</button>
          </div>
          <button v-else class="delete-btn btn-tr" @click="confirmDelete = note.id">
            <img class="btn-icon" src="./icons/delete.svg" alt="Sil">
          </button>
        </div>
      </div>
    </div>

 
    <div v-if="selectedNote" class="modal" @click.self="closeNoteModal">
      <div class="modal-content">
        <div class="modal-close">
          <button class="close-btn" @click="closeNoteModal"> Kapat</button>
        </div>
        <h3 contenteditable @blur="updateNote('note_title', $event.target.innerText)">
          {{ selectedNote.note_title }}
        </h3>
        <p contenteditable @blur="updateNote('note_text', $event.target.innerText)">
          {{ selectedNote.note_text }}
        </p>

        <div style="display: flex; gap: 1em;">
          <button class="archive-btn" @click="toggleArchive(selectedNote)">
            <img style="margin-right: 0.5em;" class="btn-icon" src="./icons/archive.svg" alt="Arşivle"> Arşivle
          </button>
          <div v-if="confirmDelete === selectedNote.id" class="delete-confirmation">
            <button class="delete" @click="deleteNote(selectedNote.id)">Sil</button>
            <button @click="cancelDelete">Vazgeç</button>
          </div>
          <button v-else class="delete-btn" @click="confirmDelete = selectedNote.id">
            <img style="margin-right: 0.5em;" class="btn-icon" src="./icons/delete.svg" alt="Sil"> Notu Sil
          </button>
        </div>
      </div>
    </div>
 
    <div v-if="showReminderModal" class="modal" @click.self="closeReminderModal">
      <div class="modal-content">
        <div class="modal-close">
          <button class="close-btn delete" @click="closeReminderModal">Kapat </button>
        </div>
        <div v-if="reminderNote && reminderNote.reminder && !editingReminderDate">
          <p>
            Durum: Açık, Hatırlatma zamanı:
            <span class="clickable-date" @click="enableEditingReminder">
              {{ formatDateTime(reminderNote.reminder) }}
            </span>
          </p>
          <button @click="removeReminder">Hatırlatıcıyı Kapat</button>
        </div>
        <div v-else>
          <h3>Hatırlatıcı ayarla:</h3>
          <p>Lütfen tarih saat belirleyin:</p>
          <input type="datetime-local" v-model="newReminder" /><br><br>
          <button class="confirm" @click="setReminder">Kaydet</button>
          <button v-if="editingReminderDate" @click="cancelEditingReminder">İptal</button>
        </div>
      </div>
    </div>
 
    <div v-if="showCreateModal" class="modal" @click.self="closeCreateModal">
      <div class="modal-content">
        <div class="modal-close">
          <button class="close-btn delete" @click="closeCreateModal">Kapat</button>
        </div>
        <input style="margin-bottom: 0.5em;" v-model="newNoteTitle" placeholder="Başlık" maxlength="250" />
        <textarea v-model="newNoteText" placeholder="İçerik" maxlength="5000"></textarea>
        <button class="confirm" @click="createNote">Ekle</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      notes: [],
      selectedNote: null,
      reminderNote: null,
      showCreateModal: false,
      showReminderModal: false,
      newNoteTitle: '',
      newNoteText: '',
      newReminder: '',
      confirmDelete: null,
      editingReminderDate: false,
      errorMessage: '',
      searchQuery: ''
    };
  },
  computed: {
    filteredNotes() {
      const q = this.searchQuery.trim().toLowerCase();
      if (!q) return this.notes;
      return this.notes.filter(note => {
        return (
          note.note_title.toLowerCase().includes(q) ||
          note.note_text.toLowerCase().includes(q)
        );
      });
    }
  },
  methods: {
    async fetchNotes() {
      const response = await axios.get('/promo/user/mynotes');
      this.notes = response.data;
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    openNoteModal(note) {
      this.selectedNote = { ...note };
      this.confirmDelete = null;
    },
    closeNoteModal() {
      this.selectedNote = null;
      this.confirmDelete = null;
    },
    openReminderModal(note) {
      this.selectedNote = null;
      this.reminderNote = note;
      this.showReminderModal = true;
      this.confirmDelete = null;
      this.editingReminderDate = false;
      this.newReminder = '';
    },
    async updateNote(field, value) {
      const trimmedValue = value.trim();
      if (field === 'note_title') {
        if (!trimmedValue) return this.showError('Başlık boş olamaz.');
        if (trimmedValue.length > 250) return this.showError('Başlık 250 karakteri aşamaz.');
      }
      if (field === 'note_text') {
        if (!trimmedValue) return this.showError('İçerik boş olamaz.');
        if (trimmedValue.length > 5000) return this.showError('İçerik 5000 karakteri aşamaz.');
      }
      await axios.put(`/promo/user/mynotes/${this.selectedNote.id}`, { [field]: trimmedValue });
      this.fetchNotes();
    },
    async setReminder() {
      await axios.put(`/promo/user/mynotes/${this.reminderNote.id}`, { reminder: this.newReminder });
      await this.fetchNotes();
      const updatedNote = this.notes.find(n => n.id === this.reminderNote.id);
      this.reminderNote = updatedNote;
      this.editingReminderDate = false;
      this.newReminder = '';
    },
    async removeReminder() {
      await axios.put(`/promo/user/mynotes/${this.reminderNote.id}`, { reminder: null });
      await this.fetchNotes();
      this.closeReminderModal();
    },
    async toggleArchive(note) {
      await axios.put(`/promo/user/mynotes/${note.id}`, { is_archived: !note.is_archived });
      this.fetchNotes();
      this.closeNoteModal();
    },
    cancelDelete() {
      this.confirmDelete = null;
    },
    async deleteNote(id) {
      await axios.delete(`/promo/user/mynotes/${id}`);
      this.confirmDelete = null;
      this.fetchNotes();
      if (this.selectedNote && this.selectedNote.id === id) {
        this.closeNoteModal();
      }
    },
    async createNote() {
      if (!this.newNoteTitle.trim()) return this.showError('Başlık gerekli.');
      if (!this.newNoteText.trim()) return this.showError('İçerik gerekli.');
      if (this.newNoteTitle.length > 250) return this.showError('Başlık 250 karakteri aşamaz.');
      if (this.newNoteText.length > 5000) return this.showError('İçerik 5000 karakteri aşamaz.');

      await axios.post('/promo/user/mynotes', {
        note_title: this.newNoteTitle,
        note_text: this.newNoteText
      });
      this.newNoteTitle = '';
      this.newNoteText = '';
      this.showCreateModal = false;
      this.fetchNotes();
    },
    showError(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = '';
      }, 4000);
    },
    closeCreateModal() {
      this.showCreateModal = false;
      this.newNoteTitle = '';
      this.newNoteText = '';
    },
    closeReminderModal() {
      this.showReminderModal = false;
      this.reminderNote = null;
      this.newReminder = '';
      this.editingReminderDate = false;
    },
    enableEditingReminder() {
      this.editingReminderDate = true;
      this.newReminder = new Date(this.reminderNote.reminder).toISOString().slice(0, 16);
    },
    cancelEditingReminder() {
      this.editingReminderDate = false;
      this.newReminder = '';
    }
  },
  mounted() {
    this.fetchNotes();
  }
};
</script>


<style scoped>
.notes-container {
  padding: 1em 0; 
}
.add-note-btn {
  background-color: #143300;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
}
.notes-list {
  display: flex; 
  gap: 0.5em;
  flex-wrap: wrap; 
  justify-content: center;
}
.note-card {
  background: #050505;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 728px; 
  overflow-x: auto;
}
.note-card:hover {
  background: #000; 
}
.note-content {
  flex: 1;
  display: flex; flex-direction: column; gap: 5px;
}
.note-actions button {
  margin-left: 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.modal-content {
  background: #000;
  padding: 20px;
  border-radius: 5px;
  max-width: 728px;
  border: 1px solid #282828;
  width: 100%;
}
.modal-close {
  display: flex;
  justify-content: flex-end;
}
.btn-icon {
  max-width: 1.4rem;
  max-height: 1.4rem; 
}
.no-notes {
  text-align: center;
  color: gray;
  margin-top: 20px;
}
.delete-confirmation button {
  margin-left: 5px;
}
.note_title, .note_text{
  border: 1px solid #0e0e0e;
  padding: 1rem 0;} 

.clickable-date {
  text-decoration: underline;
  cursor: pointer;
  color: #917e5c;
}
.active-reminder{
  color: #106e35;
}
.btn-tr{
  border: none; 
  background-color: transparent; 
  transition: transform 0.3s ease-in-out; }
.btn-tr:hover{
  transform: scale(1.2);}

.error-box {
  background-color: #531c1c;
  padding: 10px;
  color: #fff;
  margin: 1em auto;
  text-align: center;
  max-width: 728px;
  border: 1px solid #a12e2e;
  position: relative;
  border-radius: 5px;
  z-index: 10001;
}
.error-close {
  position: absolute;
  right: 10px;
  top: 5px;
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.head-buttons{
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  margin-bottom: 1em;
}
</style> 