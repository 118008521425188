import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global-dark.css';
import './assets/animations.css';
import { io } from 'socket.io-client';
import { eventBus } from './utils/eventBus'
 
axios.defaults.baseURL = 'https://noahpmu.com/api'; 

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
 
const socket = io("https://noahpmu.com", {
  path: "/socket",
  transports: ["websocket"]
});

const app = createApp(App);
 
app.config.globalProperties.$socket = socket;

app.use(router);

app.mixin({
  methods: { 
    formatDateTime(dateTime) {
      if (!dateTime) return '';

      const date = new Date(dateTime);
      const formattedDate = new Intl.DateTimeFormat('tr-TR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).format(date); 
      
      return formattedDate.replace(',', '');
    },
    formatDate(dateString) {
      if (!dateString) return "-";
      const date = new Date(dateString);
      return date.toLocaleDateString("tr-TR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }, 
  }
});

app.directive('no-right-click', {
  mounted(el) { 
    el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
 
    el.style.userSelect = 'none';
    el.style.webkitUserSelect = 'none';
    el.style.mozUserSelect = 'none';
    el.style.msUserSelect = 'none';
 
    el.addEventListener('dragstart', (e) => {
      e.preventDefault();
    });
 
    el.addEventListener('touchstart', (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();  
      }
    });
 
    el.style.webkitTouchCallout = 'none'; 
  }
}); 

app.mount('#app');
app.config.globalProperties.$eventBus = eventBus