<template>
  <div class="kasolcum-container" @touchmove="handleTouchMove">
    <PhotoCapture v-if="!capturedImage" @photo-captured="handlePhotoCaptured" />
    <div v-else class="editor-wrapper">
      <div class="toolbar top-toolbar">
        <div class="top-button" @click="toggleLock">
          <img
            v-if="isLocked"
            src="./icons/guides/locked-image.svg"
            class="btn-icon"
          >
          <img
            v-else
            src="./icons/guides/lock-image.svg"
            class="btn-icon"
          > 
        </div>

        <div class="top-button" @click="newPhoto">
          <img
            src="./icons/guides/re-capture.svg"
            class="btn-icon"
          >
        </div>

        <div class="top-button" @click="goFullScreen">
          <img
            src="./icons/guides/full-screen.svg"
            class="btn-icon"
          >
        </div>
      </div>

      <PhotoEditor :image="capturedImage" :locked="isLocked" /> 
      <GuideLines
        v-if="resetGuide"
        v-show="GuideVisible"
        :whiteLines="whiteLines"
        :lineAdded="LineAdded"
        :lineLocked="guideisLocked"
      />
      

      <div class="toolbar bottom-toolbar">
        <div class="bottom-button" @click="toggleWhiteLines">
          <img class="btn-icon" src="./icons/guides/line-color.svg">
        </div>

        <div class="bottom-button lock-guide" @click="toggleGuideLocked">
          <img
            v-if="guideisLocked"
            src="./icons/guides/locked-guide.svg"
            class="btn-icon"
          >
          <img
            v-else
            src="./icons/guides/lock-guide.svg"
            class="btn-icon"
          >  
        </div>
 
        <div class="bottom-button add-line" @click="toggleLineAdded">
          <img
            v-if="LineAdded"
            src="./icons/guides/remove-line.svg"
            class="btn-icon"
          >
          <img
            v-else
            src="./icons/guides/add-line.svg"
            class="btn-icon"
          >  
        </div>

        <div class="bottom-button show-guides" @click="toggleShowGuides">
          <img
            v-if="GuideVisible"
            src="./icons/guides/unvisible.svg"
            class="btn-icon"
          >
          <img
            v-else
            src="./icons/guides/visible.svg"
            class="btn-icon"
          >  
        </div>

        <div class="bottom-button">
          <img class="btn-icon" src="./icons/guides/save.svg">
        </div>

        <div class="bottom-button" @click="toggleResetGuides">
          <img class="btn-icon" src="./icons/guides/reset.svg">
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import db from './db'

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { eventBus } from '@/utils/eventBus'
import PhotoCapture from './PhotoCapture.vue'
import PhotoEditor from './PhotoEditor.vue'
import GuideLines from './GuideLines.vue'

const capturedImage = ref(null)
const isLocked = ref(false)
const whiteLines = ref(false) 
const LineAdded = ref(false)
const guideisLocked = ref(false)
const GuideVisible = ref(true)
const resetGuide = ref(true)

// function handlePhotoCaptured(imageData) {
//   capturedImage.value = imageData
//   localStorage.setItem('capturedPhoto', imageData)
// }

function handleTouchMove(e) { if (e.touches.length > 1) { e.preventDefault() } }

function handlePhotoCaptured(imageData) {
  capturedImage.value = imageData
  db.photos.add({ base64data: imageData })
}


function toggleLock() {
  isLocked.value = !isLocked.value
}

function newPhoto() {
  capturedImage.value = null
  isLocked.value = false
 // localStorage.removeItem('capturedPhoto')
}

function goFullScreen() {
  const docEl = document.documentElement
  if (docEl.requestFullscreen) {
    docEl.requestFullscreen()
  } else if (docEl.mozRequestFullScreen) {
    docEl.mozRequestFullScreen()
  } else if (docEl.webkitRequestFullscreen) {
    docEl.webkitRequestFullscreen()
  } else if (docEl.msRequestFullscreen) {
    docEl.msRequestFullscreen()
  }
}

function toggleWhiteLines() {
  whiteLines.value = !whiteLines.value
} 
function toggleLineAdded() {
  LineAdded.value = !LineAdded.value
}
function toggleGuideLocked() {
  guideisLocked.value = !guideisLocked.value
}
function toggleShowGuides() {
  GuideVisible.value = !GuideVisible.value
}
function toggleResetGuides() {
  resetGuide.value = false
  setTimeout(() => {
    resetGuide.value = true
  }, 10) 
}
 
onMounted(() => {
  eventBus.emit('kasolcum-visible', true)
})

onBeforeUnmount(() => {
  eventBus.emit('kasolcum-visible', false)
})
</script>
 
<style scoped>
.kasolcum-container {
  background-color: #0a0a0a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  user-select: none;
}

.editor-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.toolbar {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 10; 
  pointer-events: none;
}

.top-toolbar {
  top: 0;    
  gap: 1em; 
}

.bottom-toolbar {
  bottom: 10px;
  left: 0;
  justify-content: center;
}

.top-button { 
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  width: 2.4rem;
  height: 2.4rem;
  margin: 1em 0;
  padding: 0.2em;
  }
  .top-button:hover { 
  background-color: #000; 
  }

.bottom-button {
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 4px;
}
.btn-icon {
  max-width: 1.5rem;
}  

.top-button,
.bottom-button {
  pointer-events: auto;
}

@media (orientation: landscape) and (max-width: 768px) {
  .top-toolbar {
    flex-direction: column;
    top: 0;
    left: 10px;
    width: auto;
    height: 100%;
    align-items: center;
  }

  .bottom-toolbar {
    position: absolute;
    flex-direction: column;
    top: 10px;
    bottom: auto;
    right: 10px;
    left: auto;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: flex-start;
  }

  .top-button,
  .bottom-button {
    margin: 0.3em 0;
  }
}

</style> 