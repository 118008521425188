<template>
  <div v-no-right-click class="guide-lines">
    <!-- Dikey çizgiler -->
    <div class="vertical-lines">
      <!-- Outer sol (T left) [YENİ EKLENDİ] -->
      <div ref="verticalLineTLeft" class="vertical-line-container t-line left">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Outer sol (D left) -->
      <div ref="verticalLineDLeft" class="vertical-line-container d-line left">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Inner sol (A left) -->
      <div ref="verticalLineALeft" class="vertical-line-container a-line left">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Sabit orta (K) -->
      <div ref="verticalLineK" class="vertical-line-container k-line">
        <div class="vertical-line fixed"></div>
      </div>
      <!-- Inner sağ (A right) --> 
      <div ref="verticalLineARight" class="vertical-line-container a-line right">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Outer sağ (D right) -->
      <div ref="verticalLineDRight" class="vertical-line-container d-line right">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Outer sağ (T right) [YENİ EKLENDİ] -->
      <div ref="verticalLineTRight" class="vertical-line-container t-line right">
        <div :class="['vertical-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
    </div>

    <!-- Yatay çizgiler -->
    <div class="horizontal-lines">
      <!-- Yeni X çizgisi (bağımsız) [YENİ EKLENDİ] -->
      <div ref="horizontalLineX" class="horizontal-line-container x-line">
        <div :class="['horizontal-line', { 'white-line': props.whiteLines }, { 'hide-line': !props.lineAdded }]" ></div>
      </div>
      <!-- Üst C (mirror üst) -->
      <div ref="horizontalLineCTop" class="horizontal-line-container c-line top">
        <div :class="['horizontal-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Ortada H -->
      <div ref="horizontalLineH" class="horizontal-line-container h-line">
        <div :class="['horizontal-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Alt C (mirror alt) -->
      <div ref="horizontalLineCBottom" class="horizontal-line-container c-line bottom">
        <div :class="['horizontal-line', { 'white-line': props.whiteLines }]" ></div>
      </div>
      <!-- Ekstra Y (sarı) -->
      <div ref="horizontalLineY" class="horizontal-line-container y-line">
        <div class="horizontal-line"></div>
      </div>
      <!-- Yeni Z çizgisi (bağımsız) [YENİ EKLENDİ] -->
      <div ref="horizontalLineZ" class="horizontal-line-container z-line">
        <div :class="['horizontal-line', { 'white-line': props.whiteLines }, { 'hide-line': !props.lineAdded }]" ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global defineProps */
import { ref, onMounted, watch } from 'vue'
import interact from 'interactjs'

// Props destructure edilmeden, reaktif kalacak şekilde tanımlandı.
const props = defineProps({
  whiteLines: {
    type: Boolean,
    default: false
  },
  lineAdded: {
    type: Boolean,
    default: false
  },
  lineLocked: {
    type: Boolean,
    default: false
  }
})

/* --- Dikey çizgiler referansları --- */
const verticalLineDLeft = ref(null)
const verticalLineALeft = ref(null)
const verticalLineK = ref(null)
const verticalLineARight = ref(null)
const verticalLineDRight = ref(null)

/* T çizgileri referansları */
const verticalLineTLeft = ref(null)
const verticalLineTRight = ref(null)

/* --- Yatay çizgiler referansları --- */
const horizontalLineCTop = ref(null)
const horizontalLineH = ref(null)
const horizontalLineCBottom = ref(null)
const horizontalLineY = ref(null)

/* X ve Z çizgileri referansları */
const horizontalLineX = ref(null)
const horizontalLineZ = ref(null)

// Ekran boyutlarına göre dinamik offset hesaplama
const screenWidth = window.innerWidth
const screenHeight = window.innerHeight

let offsetA = screenHeight * -0.05 // -%5
let offsetD = screenHeight * -0.10 // -%10
let offsetT = screenHeight * -0.15 // -%15

let offsetH = 0
let diffC = screenWidth * 0.10 // %5
let offsetY = screenHeight * 0.15 // %10

let offsetX = screenWidth * -0.20 // -%10
let offsetZ = screenWidth * 0.20 // %15


/* --- Draggable setup fonksiyonu --- */
function setupDraggables(enabled) {
  /* --- Dikey çizgiler: A grubu --- */
  interact(verticalLineALeft.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetA += event.dx
        verticalLineALeft.value.style.transform = `translateX(${offsetA}px)`
        verticalLineALeft.value.dataset.x = offsetA
        // Sağ A yansısı
        verticalLineARight.value.style.transform = `translateX(${-offsetA}px)`
        verticalLineARight.value.dataset.x = -offsetA
      }
    }
  })
  interact(verticalLineARight.value).draggable({
    enabled,
    listeners: {
      move (event) {
        let currentRight = parseFloat(verticalLineARight.value.dataset.x) || (-offsetA)
        currentRight += event.dx
        offsetA = -currentRight
        verticalLineARight.value.style.transform = `translateX(${currentRight}px)`
        verticalLineARight.value.dataset.x = currentRight
        // Sol A yansısı
        verticalLineALeft.value.style.transform = `translateX(${offsetA}px)`
        verticalLineALeft.value.dataset.x = offsetA
      }
    }
  })

  /* --- Dikey çizgiler: D grubu --- */
  interact(verticalLineDLeft.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetD += event.dx
        verticalLineDLeft.value.style.transform = `translateX(${offsetD}px)`
        verticalLineDLeft.value.dataset.x = offsetD
        // Sağ D yansısı
        verticalLineDRight.value.style.transform = `translateX(${-offsetD}px)`
        verticalLineDRight.value.dataset.x = -offsetD
      }
    }
  })
  interact(verticalLineDRight.value).draggable({
    enabled,
    listeners: {
      move (event) {
        let currentRight = parseFloat(verticalLineDRight.value.dataset.x) || (-offsetD)
        currentRight += event.dx
        offsetD = -currentRight
        verticalLineDRight.value.style.transform = `translateX(${currentRight}px)`
        verticalLineDRight.value.dataset.x = currentRight
        verticalLineDLeft.value.style.transform = `translateX(${offsetD}px)`
        verticalLineDLeft.value.dataset.x = offsetD
      }
    }
  })

  /* --- Dikey çizgiler: T grubu --- */
  interact(verticalLineTLeft.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetT += event.dx
        verticalLineTLeft.value.style.transform = `translateX(${offsetT}px)`
        verticalLineTLeft.value.dataset.x = offsetT
        // Sağ T yansısı
        verticalLineTRight.value.style.transform = `translateX(${-offsetT}px)`
        verticalLineTRight.value.dataset.x = -offsetT
      }
    }
  })
  interact(verticalLineTRight.value).draggable({
    enabled,
    listeners: {
      move (event) {
        let currentRight = parseFloat(verticalLineTRight.value.dataset.x) || (-offsetT)
        currentRight += event.dx
        offsetT = -currentRight
        verticalLineTRight.value.style.transform = `translateX(${currentRight}px)`
        verticalLineTRight.value.dataset.x = currentRight
        // Sol T yansısı
        verticalLineTLeft.value.style.transform = `translateX(${offsetT}px)`
        verticalLineTLeft.value.dataset.x = offsetT
      }
    }
  })

  /* --- Yatay çizgiler --- */
  // Ortadaki H çizgisi
  interact(horizontalLineH.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetH += event.dy
        horizontalLineH.value.style.transform = `translateY(${offsetH}px)`
        horizontalLineH.value.dataset.y = offsetH
        // C çizgilerini H'ye göre güncelleyelim
        horizontalLineCTop.value.style.transform = `translateY(${offsetH - diffC}px)`
        horizontalLineCTop.value.dataset.y = offsetH - diffC
        horizontalLineCBottom.value.style.transform = `translateY(${offsetH + diffC}px)`
        horizontalLineCBottom.value.dataset.y = offsetH + diffC
      }
    }
  })

  // Üst C çizgisi
  interact(horizontalLineCTop.value).draggable({
    enabled,
    listeners: {
      move (event) {
        let newTop = (parseFloat(horizontalLineCTop.value.dataset.y) || (offsetH - diffC)) + event.dy
        horizontalLineCTop.value.style.transform = `translateY(${newTop}px)`
        horizontalLineCTop.value.dataset.y = newTop
        let newBottom = 2 * offsetH - newTop
        horizontalLineCBottom.value.style.transform = `translateY(${newBottom}px)`
        horizontalLineCBottom.value.dataset.y = newBottom
        diffC = offsetH - newTop
      }
    }
  })

  // Alt C çizgisi
  interact(horizontalLineCBottom.value).draggable({
    enabled,
    listeners: {
      move (event) {
        let newBottom = (parseFloat(horizontalLineCBottom.value.dataset.y) || (offsetH + diffC)) + event.dy
        horizontalLineCBottom.value.style.transform = `translateY(${newBottom}px)`
        horizontalLineCBottom.value.dataset.y = newBottom
        let newTop = 2 * offsetH - newBottom
        horizontalLineCTop.value.style.transform = `translateY(${newTop}px)`
        horizontalLineCTop.value.dataset.y = newTop
        diffC = newBottom - offsetH
      }
    }
  })

  // Y çizgisi (sarı, bağımsız)
  interact(horizontalLineY.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetY += event.dy
        horizontalLineY.value.style.transform = `translateY(${offsetY}px)`
        horizontalLineY.value.dataset.y = offsetY
      }
    }
  })

  // X çizgisi
  interact(horizontalLineX.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetX += event.dy
        horizontalLineX.value.style.transform = `translateY(${offsetX}px)`
        horizontalLineX.value.dataset.y = offsetX
      }
    }
  })

  // Z çizgisi
  interact(horizontalLineZ.value).draggable({
    enabled,
    listeners: {
      move (event) {
        offsetZ += event.dy
        horizontalLineZ.value.style.transform = `translateY(${offsetZ}px)`
        horizontalLineZ.value.dataset.y = offsetZ
      }
    }
  })
}

/* --- DOM yüklendiğinde başlat --- */
onMounted(() => {
  /* --- Başlangıç pozisyonları --- */
  if (verticalLineALeft.value) {
    verticalLineALeft.value.style.transform = `translateX(${offsetA}px)`
    verticalLineALeft.value.dataset.x = offsetA
  }
  if (verticalLineARight.value) {
    verticalLineARight.value.style.transform = `translateX(${-offsetA}px)`
    verticalLineARight.value.dataset.x = -offsetA
  }
  if (verticalLineDLeft.value) {
    verticalLineDLeft.value.style.transform = `translateX(${offsetD}px)`
    verticalLineDLeft.value.dataset.x = offsetD
  }
  if (verticalLineDRight.value) {
    verticalLineDRight.value.style.transform = `translateX(${-offsetD}px)`
    verticalLineDRight.value.dataset.x = -offsetD
  }
  if (verticalLineTLeft.value) {
    verticalLineTLeft.value.style.transform = `translateX(${offsetT}px)`
    verticalLineTLeft.value.dataset.x = offsetT
  }
  if (verticalLineTRight.value) {
    verticalLineTRight.value.style.transform = `translateX(${-offsetT}px)`
    verticalLineTRight.value.dataset.x = -offsetT
  }

  if (horizontalLineH.value) {
    horizontalLineH.value.style.transform = `translateY(${offsetH}px)`
    horizontalLineH.value.dataset.y = offsetH
  }
  if (horizontalLineCTop.value) {
    horizontalLineCTop.value.style.transform = `translateY(${offsetH - diffC}px)`
    horizontalLineCTop.value.dataset.y = offsetH - diffC
  }
  if (horizontalLineCBottom.value) {
    horizontalLineCBottom.value.style.transform = `translateY(${offsetH + diffC}px)`
    horizontalLineCBottom.value.dataset.y = offsetH + diffC
  }
  if (horizontalLineY.value) {
    horizontalLineY.value.style.transform = `translateY(${offsetY}px)`
    horizontalLineY.value.dataset.y = offsetY
  }
  if (horizontalLineX.value) {
    horizontalLineX.value.style.transform = `translateY(${offsetX}px)`
    horizontalLineX.value.dataset.y = offsetX
  }
  if (horizontalLineZ.value) {
    horizontalLineZ.value.style.transform = `translateY(${offsetZ}px)`
    horizontalLineZ.value.dataset.y = offsetZ
  }

  /* --- İlk yüklemede çizgileri başlat --- */
  setupDraggables(!props.lineLocked)
})

/* --- lineLocked değişikliklerini izle --- */
watch(() => props.lineLocked, (locked) => {
  setupDraggables(!locked)
})
</script>



<style scoped>
.guide-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Ortak container'lar */
.vertical-lines,
.horizontal-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* --- Dikey çizgiler containerları --- */
.vertical-line-container {
  pointer-events: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;  /* Geniş hit alanı */
  height: 100%;
  left: calc(50% - 10px); /* Başlangıç merkezi referans */
}

/* Sınıf eklemeleriyle sol ve sağ ayrımı */
.vertical-line-container.left { }
.vertical-line-container.right { }

/* Ortadaki sabit K çizgisi: kırmızı */
.k-line .vertical-line.fixed {
  background: red;
}

/* Diğer dikey çizgiler (A ve D) siyah */
.vertical-line {
  background: black;
  width: 1px;
  height: 100%;
}

/* --- Yatay çizgiler containerları --- */
.horizontal-line-container {
  pointer-events: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px; /* Geniş hit alanı */
  top: calc(50% - 10px);
}

/* --- Yatay çizgiler --- */
.horizontal-line {
  background: black;
  height: 1px;
  width: 100%;
}

/* Y çizgisine özel stil: sarı renk */
.y-line .horizontal-line {
  background: yellow;
}

.guide-lines { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  touch-action: none; 
} 
.vertical-line-container, 
.horizontal-line-container { 
  pointer-events: auto; 
  touch-action: none; 
}

/* white-line ile siyah çizgiler beyaza dönsün */
.white-line {
  background: white !important;
}
.hide-line{display: none;}
</style>
