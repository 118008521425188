<template>
  <div class="container">
    <router-link to="/" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/home.svg" alt="Ana Sayfa Icon" />
      </div>
    </router-link>

    <router-link to="/portal" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Portal Icon" />
      </div>
    </router-link>  

    <div class="ai-logo"><span style="font-weight: 500;">RANDEVU</span> TAKİP</div>  

  
  </div> 
</template>

<script>
export default { 
  data() {
    return { 
      isModalOpen: false
    };
  },  
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center; 
  margin: 1rem 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 4em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.box:first-child {
  min-width: 5em;
} 
.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
} 

.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.ai-logo {
  font-size: 1.4rem;
  font-weight: 200;
}

@media (max-width: 768px) {
  .container{
    justify-content: flex-start;
}
}

</style>
