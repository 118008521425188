<template> 
  <div>
    <div v-if="isLoggedIn">
      <div class="container"> 
  
        <router-link to="/portal" class="box">
          <div class="icon">
            <img class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Ana Sayfa Icon" />
          </div>
        </router-link>  
  
        <div class="box" @click="setActiveComponent('MyNotes')" :class="{ active: currentComponent === 'MyNotes' }">
          <div class="icon">
            <img class="icon" src="./icons/mynotes.svg" alt="Sorularım" />
          </div>
          <span>Notlarım</span>
        </div>
  
        <div class="box" @click="setActiveComponent('MyNotesArchive')" :class="{ active: currentComponent === 'MyNotesArchive' }">
          <div class="icon">
            <img class="icon" src="./icons/archive.svg" alt="Arşiv" />
          </div>
          <span>Arşiv</span>
        </div> 
      </div>
  
      <div class="content">
        <component :is="currentComponent"  />
      </div>
    </div>
    <div class="please-login" v-else>
      <p>Lütfen giriş yapın</p> 
      <button class="confirm" @click="redirectToLogin">Giriş Yap</button>
    </div>
  </div>
</template>

<script>
import MyNotes from './MyNotes.vue';
import MyNotesArchive from './MyNotesArchive.vue';

export default { 
  components: { 
    MyNotes,
    MyNotesArchive
  },
  data() {
    return {
      currentComponent: "MyNotes",  
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    }
  },
  methods: {
    setActiveComponent(component) {
      this.currentComponent = component;
    },
    redirectToLogin() {
      this.$router.push('/login');
    }
  } 
};
</script>


<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 120px;
}
.content{margin-bottom: 4rem;}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 12em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparent;  
}

.box:first-child {
  min-width: 5em;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}
 
.box.active {
  border-bottom: 1px solid #023032;
  color: white;
}

.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.box.active .icon {
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    padding-top: 5rem;
    flex-wrap: wrap; 
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
.please-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
</style>
