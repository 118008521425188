<template>
  <div class="main-div"> 
    <div class="content">
      <CommunityMain /> 
      <MainPageNavigation />
      <RandomQuote /> 
    </div>
  </div>
</template> 


<script>
import CommunityMain from './community/CommunityMain.vue'; 
import MainPageNavigation from './menus/MainPageNavigation.vue'; 
import RandomQuote from './RandomQuote.vue';

export default {
  components: {
    MainPageNavigation,
    CommunityMain,
    RandomQuote, 
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;  
    },
  },
};
</script>

<style scoped>
.main-div {
  margin: 0;
  padding: 200px 0 70px 0;
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55vh;
}

.video-background {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
}

.content { 
  position: relative;
  z-index: 2;
}
 
.overlay-image {
  display: none;
}
@media (max-width: 768px) {
  .video-background video { 
    margin: -75px 0 -120px 0;
    height: 80vh;
    object-fit: fill;
    min-width: 100%;
  } 
  .main-div { 
   padding: 100px 0 25px 0; 
 } 
}
  
</style>
