<template>
    <div>  
      <div class="ana-div">

        <div class="ic-div">
          <p v-if="loading">Yükleniyor...</p>
          <p v-else-if="error">{{ error }}</p>
          <div v-else>
            <p class="desciption">Cüzdan Adı:</p>
            <p class="title">{{ mtSpace.name }}</p>
            <p class="detail">{{ mtSpace.description }}</p> 
          </div>

        </div>

        <div class="ic-div">
          <WalletStats :id="id" />
        </div>

      </div>
      
      <div class="form">
        <MuhasebeForm :id="id" />
      </div>
  
      <div class="history"> 
        <WalletHistory :space_id="id" />
      </div>
  
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import MuhasebeForm from './MuhasebeForm.vue';
  import WalletHistory from "./WalletHistory.vue";
  import WalletStats from "./WalletStats.vue";
//  import WalletSettings from "./WalletSettings.vue";
  
  export default {
    props: ['id'],
    components: {
      MuhasebeForm,
      WalletHistory,
      WalletStats,
//      WalletSettings
    },
    data() {
      return {
        mtSpace: null,
        loading: true,
        error: null,
      };
    },
    methods: {
      async fetchMtSpaceDetails() {
        try {
          const response = await axios.get(`/promo/user/mt-spaces/${this.id}`);
          this.mtSpace = response.data.data;
        } catch (error) {
          console.error("MtSpace detayları alınırken hata oluştu:", error);
          this.error = "Cüzdan bilgileri alınamadı.";
        } finally {
          this.loading = false;
        }
      },
    },
    mounted() {
      this.fetchMtSpaceDetails();
    }
  };
   // V2
  // <div class="ic-div reset">
  //             <WalletSettings :id="id" />
  //  </div>
  </script>
  
  
   
<style scoped>

.ana-div {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
}
 
.ic-div {
    flex: 1 1 32%;  
    box-sizing: border-box;
    padding: 20px; 
    background-color: #050505;
}
.ic-div:last-child { 
  background: linear-gradient(45deg, #020202, #011111, #020202); 
}
 
@media (max-width: 768px) {
    .ic-div {
        flex: 1 1 100%; 
    }
}

.desciption {
 font-size: 0.8rem;
 color: #187774;
 margin: 0;
 font-weight: 500;
}
.title {
 font-size: 1.2rem;
 margin: 0;
 font-weight: 600;
}
.detail {
 font-size:  0.8rem;
 margin-top: 5px;
 color: #7c7c7c;
 font-weight: 500;
}
.history {
 display: flex;
justify-content: center;
flex-direction: column;
width: 100%;
overflow: auto;
}
.reset {
 padding: 0;
 background-color:  transparent;
}
 </style>
  