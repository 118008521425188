import { createRouter, createWebHistory } from 'vue-router'; 
 

import NoahMain from './components/NoahMain.vue';
import ProductMain from './components/products/ProductMain.vue';




 
import ShowRoom from './components/ShowRoom.vue';
import SurveyHub from './components/anket/SurveyHub.vue';
import UcretsizEgitim from './components/education/UcretsizEgitim.vue';
import EducationDetail from './components/education/EducationDetail.vue';

import KendiniTestEt from './components/education/EducationMain.vue';
import MasterClassEgitim from './components/MasterClassEgitim.vue';
import NoahMasterWorld from './components/NoahMasterWorld.vue';

import UluslararasiSertifikalar from './components/UluslararasiSertifikalar.vue';

import Portal from './components/application/AppMain.vue';
import AccountingMain from './components/muhasebe/AccountingMain.vue';
import RandevuTakip from './components/randevu/RandevuTakip.vue'; 
import NoahDuyurular from './components/NoahDuyurular.vue'; 
import NoahHakkinda from './components/pages/NoahHakkinda.vue';
import VeriGizliligi from './components/pages/VeriGizliligi.vue'; 
import KullanimKosullari from './components/pages/KullanimKosullari.vue'; 
import CerezPolitikasi from './components/pages/CerezPolitikasi.vue'; 
import UserAgreements from './components/pages/UserAgreements.vue'; 
import BrandKit from './components/contact/BrandKit.vue'; 
import SsS from './components/FaqAccordion.vue'; 

import NoahChat from './components/community/NoahChat.vue'; 

import NoahAi from './components/noahai/NoahAiHome.vue';  

import SoruCevap from './components/forum/NoahForum.vue';  
import SoruCevapArsiv from './components/forum/UnsolvedQuestions.vue'; 

import MyNotes from './components/mynotes/MyNotesMain.vue'; 

import HowToUseGallery from './components/HowToUseGallery.vue'; 
import UserProfile from './components/profile/UserProfile.vue'; 
import HesapSeviyeleri from './components/HesapSeviyeleri.vue'; 

import UserLogin from './components/UserLogin.vue'; 
import UserRegister from './components/UserRegister.vue'; 


import AuthContacts from './components/contact/AuthContacts.vue';  
import KasOlcum from './components/kasolcum/KasOlcum.vue';
import ShopWebsitesByLanguage from './components/ShopWebsitesByLanguage.vue';



const routes = [ 
  { path: '/', component: NoahMain, meta: { title: 'Noah PMU - Premium Pigments' } },
  { path: '/urunler', component: ProductMain, meta: { title: 'Ürünler - Noah PMU' } },   

  { path: '/shopwebsiteleri', component: ShopWebsitesByLanguage, meta: { title: 'Hesabım - Noah PMU' } },

  { path: '/ucretsizegitim', component: UcretsizEgitim, meta: { title: 'Noah PMU - Ücretsiz Eğitim' } },
  {
    path: '/egitimler/:educationId/:languageId',
    name: 'EducationDetail',
    component: EducationDetail,
    props: true,
    meta: { title: 'Noah PMU - Eğitim Detayı' },
  },  
  { path: '/login', component: UserLogin, meta: { title: 'Giriş yap - Noah PMU' } },
  { path: '/kayitol', component: UserRegister, meta: { title: 'Kayıt Ol - Noah PMU' } },
  { path: '/hesabim', component: UserProfile, meta: { title: 'Hesabım - Noah PMU' } },
  { path: '/hesabim/:componentname?', component: UserProfile, meta: { title: 'Hesabım - Noah PMU' } },


  { path: '/kendinitestet', component: KendiniTestEt, meta: { title: 'Kendini Test Et - Noah PMU' } }, 
  { path: '/masterclassegitim', component: MasterClassEgitim, meta: { title: 'MasterClass Eğitim - Noah PMU' } },
  { path: '/noahmasterworld', component: NoahMasterWorld, meta: { title: 'Master World - Noah PMU' } },
  { path: '/sertifikalar', component: UluslararasiSertifikalar, meta: { title: 'Sertifikalar - Noah PMU' } },
  { path: '/portal', component: Portal, meta: { title: 'Portal - Noah PMU' } },


  { path: '/portal/muhasebe', component: AccountingMain, meta: { title: 'Muhasebe - Noah PMU' } },
  { 
    path: '/portal/muhasebe/:id', 
    name: 'WalletDetail', 
    component: AccountingMain, 
    props: true,
    meta: { title: 'Cüzdan Detayı - Noah PMU' } 
  },

  { path: '/portal/randevu-takip', component: RandevuTakip, meta: { title: 'Randevu Takip - Noah PMU' } },

  { path: '/portal/kasolcum', component: KasOlcum, meta: { title: 'Kaş Ölçüm - Noah PMU' } },

  { path: '/noah-chat', component: NoahChat, meta: { title: 'Noah Chat - Noah PMU - Noah PMU' } },
  { path: '/noahai', component: NoahAi, meta: { title: 'Noah AI - Noah PMU - Noah PMU' } },

  { path: '/iletisim', component: AuthContacts, meta: { title: 'Noah PMU - İletişim' } }, 
  { path: '/showroom', component: ShowRoom, meta: { title: 'Showroom - Noah PMU' } }, 
  { path: '/sss', component: SsS, meta: { title: 'Sıkça Sorulan Sorular - Noah PMU' } },
  { path: '/anketler', component: SurveyHub, meta: { title: 'Anketler - Noah PMU' } },
  { path: '/hakkimizda', component: NoahHakkinda, meta: { title: 'Hakkımızda - Noah PMU' } },
  { path: '/veri-gizliligi', component: VeriGizliligi, meta: { title: 'Veri Gizliliği Politikası - Noah PMU' } },
  { path: '/kullanim-kosullari', component: KullanimKosullari, meta: { title: 'Kullanım Koşulları - Noah PMU' } },
  { path: '/cerez-politikasi', component: CerezPolitikasi, meta: { title: 'Çerez Politikası - Noah PMU' } },
  { path: '/sozlesmeler', component: UserAgreements, meta: { title: 'Sözleşme Merkezi - Noah PMU' } },
  { path: '/marka-materyal', component: BrandKit, meta: { title: 'Marka Materyal - Noah PMU' } },
   
  { path: '/notlarim', component: MyNotes, meta: { title: 'Notlarım - Noah PMU' } },

  { path: '/kullanim-kilavuzu', component: HowToUseGallery, meta: { title: 'Soru Cevap - Noah PMU' } },

  { path: '/soru-cevap', component: SoruCevap, meta: { title: 'Soru Cevap - Noah PMU' } },
  { path: '/soru-cevap/arsiv', component: SoruCevapArsiv, meta: { title: 'Soru Cevap - Noah PMU' } },
  { path: '/etkinlikler', component: NoahDuyurular, meta: { title: 'Duyurular - Noah PMU' } },
  { path: '/hesap-seviyeleri', component: HesapSeviyeleri, meta: { title: 'Hesap Seviyeleri - Noah PMU' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { 
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});
 
router.beforeEach((to, from, next) => {
  const defaultTitle = 'Noah PMU';
  document.title = to.meta.title || defaultTitle;
  next();
});

export default router;
