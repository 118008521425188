<template>
  <div>
    <div class="sub-category-container">
      <div class="minor-categories-wrapper">
        <div
          class="minor-category-box"
          v-for="(category, index) in minorCategories"
          :key="index"
          @click="selectCategory(index)"
          :style="getAnimationStyle(category)"
        >
          <img
            :src="category.main_image"
            class="category-image"
            alt="Minor Category Image"
            v-no-right-click
          />
          <div class="category-name">{{ category.name }}</div>
          <div class="minor-category-info">
            <div 
              class="color-circle"
              :style="{ backgroundColor: category.color }"
            ></div>
            <div class="sub-tone-text"> 
             <img
               v-if="category.sub_tone === 'Soğuk'"
               src="./icons/soguk.svg"
               alt="Soğuk icon"
             /> 
             <img
               v-else-if="category.sub_tone === 'Sıcak'"
               src="./icons/sicak.svg"
               alt="Sıcak icon"
             /> 
             <img
               v-else-if="category.sub_tone === 'Nötr'"
               src="./icons/notr.svg"
               alt="Nötr icon"
             />  
           </div>
          </div>
        </div>
      </div>
    </div>
 
    <div v-if="showModal" class="modal-overlay" @click="handleOverlayClick"> 
      <div
        ref="modalContent"
        class="modal-content"
        :style="modalStyle"
        @click.stop
      >
        <div class="close-div">
          <button class="close-button" @click="closeModal">X</button>
        </div> 
        <div class="detail-content">
          <SelectedDetail
            v-if="selectedIndex !== null"
            :minorCategoryId="minorCategories[selectedIndex]?.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick, computed, onBeforeUnmount } from "vue";
import axios from "axios";
import SelectedDetail from "./SelectedDetail.vue";

export default {
  name: "SubCategoryVideo",
  components: { SelectedDetail },
  props: {
    selectedCategory: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const minorCategories = ref([]);
    const selectedIndex = ref(null);
    const showModal = ref(false);
    const modalStyle = ref({
      transform: "translateY(100%)",
      transition: "transform 0.3s ease-in-out",
    });
     
    const screenWidth = ref(window.innerWidth);
    
    const updateScreenWidth = () => {
      screenWidth.value = window.innerWidth;
    };
 
    const isLargeScreen = computed(() => screenWidth.value > 780);
 
    const getAnimationStyle = (category) => { 
      if (!isLargeScreen.value) {
        return {
          opacity: 1,
          transform: "translateY(0)",
        };
      } 
      return {
        opacity: category.visible ? 1 : 0,
        transform: category.visible ? "translateY(0)" : "translateY(500px)",
        transition: "opacity 0.9s ease, transform 0.9s ease"
      };
    };

    const fetchMinorCategories = async () => {
      try {
        if (!props.selectedCategory) return;
        const response = await axios.get(
          `/auth/minor-categories/by-sub-category/${props.selectedCategory.name}`
        ); 
        minorCategories.value = (response.data.minorCategories || []).map(category => ({
          ...category,
          visible: false
        }));
        selectedIndex.value = null;
        nextTick(() => {
          animateCategories();  
        });
      } catch (error) {
        console.error("Minor kategoriler yüklenirken hata:", error);
      }
    };

    const animateCategories = () => { 
      if (!isLargeScreen.value) return;
      minorCategories.value.forEach((category, index) => {
        setTimeout(() => {
          category.visible = true;
        }, index * 100); 
      });
    }; 

    const selectCategory = (index) => {
      selectedIndex.value = index;
      showModal.value = true;
      nextTick(() => {
        setTimeout(() => {
          modalStyle.value.transform = "translateY(0)";
        }, 10);
      });
    };

    const closeModal = () => {
      modalStyle.value.transform = "translateY(100%)";
      setTimeout(() => {
        showModal.value = false;
      }, 500);
    };

    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };
    watch(
      () => props.selectedCategory,
      () => {
        minorCategories.value = [];  
        selectedIndex.value = null;
        showModal.value = false;
        fetchMinorCategories();
      },
      { immediate: true }
    ); 

    onMounted(() => {
      fetchMinorCategories();
      window.addEventListener("resize", updateScreenWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScreenWidth);
    });

    return {
      minorCategories,
      selectedIndex,
      showModal,
      modalStyle,
      selectCategory,
      closeModal,
      handleOverlayClick,
      getAnimationStyle,
    };
  },
};
</script> 

<style scoped>
.sub-category-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 40vh;
}

.minor-categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 1600px;
  margin: 2rem auto;
}

.minor-category-box {
  position: relative; 
  max-width: 15rem;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.5s ease, color 0.2s ease;
} 
.minor-category-box:hover {
  background-color: #050505;
  background: linear-gradient(25deg, #020202, #030b0b, #020202);
  animation: shadowColorChange 2s infinite ease-in-out;
  transform: translateY(-2%) rotate(0deg);
}

.minor-category-box:hover .category-name {
  background-color: #000;   
}

.category-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  transition: transform 0.3s ease;
}
.minor-category-box:hover .category-image {
  transform: translateY(-0%) rotate(5deg) scale(1.2);
  filter: contrast(1.1);
}
.category-name {
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  padding: 1rem 5px;
  background-color: #050505;
}
 
.minor-category-info {
  position: absolute;
  bottom: 5rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.color-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;  
}

.sub-tone-text { 
  min-width: 2rem;
  opacity: 0.5;
  transition: transform 0.4s ease;
}

.minor-category-box:hover .sub-tone-text {  
  opacity: 0.7;
  transform: translateY(-0%) rotate(0deg) scale(1.5);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;
}

.modal-content {
  margin-top: 12rem;
  width: 100%;
  max-width: 1000px;
  background-color: #050505;  background: linear-gradient(45deg, #020202, #080808, #030b0b);
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #022121;
  border-left: 1px solid #022121;
  border-right: 1px solid #022121;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.5);
  transform: translateY(100%);
  padding: 0 1rem;
}

.close-div {
  position: sticky;
  border-radius: 15px 15px 0 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  padding: 0.5rem;
}

.detail-content {
  overflow: auto;
  max-height: 80vh;
}

.close-button {
  background-color: #000000;
  color: #ffffff;
  padding: 0.8rem;
  min-width: 3rem;
  max-width: 3rem;
  border-radius: 2rem;
  opacity: 0.8;
  border: 1px solid #444444;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.close-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .minor-category-box {
    max-width: 10rem;
  }
  .category-name {
    font-size: 1em;
    padding: 1em 5px;
  }
  .category-image {
    filter: contrast(1.1);
  }
  .minor-category-info { 
    bottom: 3rem; 
  }
  .color-circle {
    width: 1.2rem;
    height: 1.2rem;  
  }
  .sub-tone-text { 
    min-width: 1.2rem; 
  }
  .detail-content { 
    max-height: 75vh;
  }
}
</style> 