<template>
  <div>
    <div v-if="isLoggedIn">
      <div class="container">
        <router-link to="/portal" class="box">
          <div class="icon">
            <img class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Ana Sayfa Icon" />
          </div>
        </router-link>  
  
        <div
          class="box"
          @click="setActiveComponent('UserDetails')"
          :class="{ active: currentComponent === 'UserDetails' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/account.svg" alt="Hesap Detayları Icon" />
          </div>
          <span>Hesap Detayları</span>
        </div>
  
        <div
          class="box"
          @click="setActiveComponent('MyEducations')"
          :class="{ active: currentComponent === 'MyEducations' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/education.svg" alt="Eğitimlerim Icon" />
          </div>
          <span>Eğitimlerim</span>
        </div> 
        
        <div
          class="box"
          @click="setActiveComponent('MyNotifications')"
          :class="{ active: currentComponent === 'MyNotifications' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/mynotify.svg" alt="Bildirimler Icon" />
          </div>
          <span>Bildirimler</span>
        </div>
  
        <div
          class="box"
          @click="setActiveComponent('MySecurity')"
          :class="{ active: currentComponent === 'MySecurity' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/security.svg" alt="Güvenlik Icon" />
          </div>
          <span>Güvenlik</span>
        </div>
  
        <div
          class="box"
          @click="setActiveComponent('MyActivities')"
          :class="{ active: currentComponent === 'MyActivities' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/myactivity.svg" alt="Aktiviteler Icon" />
          </div>
          <span>Hesap Aktiviteleri</span>
        </div>
  
        <div
          class="box"
          @click="setActiveComponent('EmailPreferences')"
          :class="{ active: currentComponent === 'EmailPreferences' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/myactivity.svg" alt="Eposta  Icon" />
          </div>
          <span>E-posta Bildirimleri</span>
        </div>
  
        <div
          class="box"
          @click="setActiveComponent('SupportRecords')"
          :class="{ active: currentComponent === 'SupportRecords' }"
        >
          <div class="icon">
            <img class="icon" src="@/assets/icon/profile/support.svg" alt="Destek Kayıtlarım Icon" />
          </div>
          <span>Destek Kayıtlarım</span>
        </div>
      </div>
  
      <div class="content">
        <component :is="currentComponent" />
      </div> 
    </div>
    <div class="please-login" v-else>
      <p>Lütfen giriş yapın</p> 
      <button class="confirm" @click="redirectToLogin">Giriş Yap</button>
    </div>
  </div>
</template>

<script>
import UserDetails from "./UserDetails.vue";
import MyEducations from "./MyEducations.vue";
import MyOrders from "./MyOrders.vue";
import MyNotifications from "./MyNotifications.vue";
import MySecurity from "./MySecurity.vue";
import MyActivities from "./MyActivities.vue";
import SupportRecords from "./SupportRecords.vue";
import EmailPreferences from "./EmailPreferences.vue";

export default {
  components: {
    UserDetails,
    MyEducations,
    MyOrders,
    MyNotifications,
    MySecurity,
    MyActivities,
    SupportRecords,
    EmailPreferences
  },
  data() {
    return {
      currentComponent: "UserDetails", 
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    }
  },
  methods: {
    setActiveComponent(component) {
      this.currentComponent = component;
      this.$router.push(`/hesabim/${this.getComponentRouteName(component)}`);
    },
    getComponentRouteName(component) {
      const mapping = {
        UserDetails: "",
        MyEducations: "egitimler",
        MyOrders: "siparisler",
        MyNotifications: "bildirimler",
        MySecurity: "guvenlik",
        MyActivities: "aktiviteler",
        SupportRecords: "destek",
        EmailPreferences: "epostabildirimleri",
      };
      return mapping[component] || "";
    },
    updateComponentFromRoute() {
      const routeMap = {
        egitimler: "MyEducations",
        siparisler: "MyOrders",
        bildirimler: "MyNotifications",
        guvenlik: "MySecurity",
        aktiviteler: "MyActivities",
        destek: "SupportRecords",
        epostabildirimleri: "EmailPreferences",
      };
      const componentName = this.$route.params.componentname;
      this.currentComponent = routeMap[componentName] || "UserDetails";
    },
    redirectToLogin() {
      this.$router.push('/login');
    }
  },
  created() {
    this.updateComponentFromRoute();
  },
  watch: {
    "$route.params.componentname": "updateComponentFromRoute",
  },
};
</script>

 

<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 150px;
  flex-wrap: wrap;
}

.content {
  margin-bottom: 4rem;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em; 
  padding: 0 1em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  background: linear-gradient(45deg, #020202, #080808, #020202);
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  cursor: pointer;
  border-bottom: 3px solid transparen
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}

.box.active {
  border-bottom: 1px solid #023032;
  color: white;
}
.box:first-child {
  min-width: 5em;
}
.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.box.active .icon {
  opacity: 1;
}
.please-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
@media (max-width: 768px) {
  .container {
    padding-top: 5rem;
    flex-wrap: wrap;
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
</style>