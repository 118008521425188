<template>
    <div class="top-container"> 
    </div>
  </template>
  
<script>  

  export default {
    data() {
      return { 
      };
    }, 
  };
  </script>
    
  <style scoped> 
  .top-container{
    padding-top: 150px;
  }
  @media (max-width: 768px) {
    .top-container{
    padding-top: 30px;
  }
}
  </style>
    