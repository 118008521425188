<template>
  <div class="outside"> 
  <div class="inside">
    <p class="header"><img :src="require('./icons/e-mail.svg')" class="accounticon" alt="Icon" />E-posta Tercihleri</p>
    <p style="padding: 5px;">E-posta bildirim ayarlarınızı buradan düzenleyebilirsiniz. Genellikle, güncellemeler veya hizmet bildirimleri almak için verilen izinlerdir.</p>
    <div v-if="Object.keys(groupedPreferences).length" class="services">
      <div v-for="(preferences, serviceName) in groupedPreferences" :key="serviceName" class="service-group">
        <h5 class="service-name">{{ serviceName }}</h5>
        <ul class="preference-list">
          <li v-for="preference in preferences" :key="preference.preference_id" class="preference-item">
            <label class="checkbox-label">
              <input
                type="checkbox"
                :checked="preference.is_subscribed"
                @change="updatePreference(preference.preference_id, $event.target.checked)"
              />
              {{ preference.category.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <p class="message">Görüntülenecek herhangi bir e-posta tercihi yok.</p>
    </div>
    <div class="actions">
      <button @click="toggleAll(true)">Tümünü Etkinleştir</button>
      <button @click="toggleAll(false)">Tümünü Devre Dışı Bırak</button>
    </div>
  </div >
</div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    groupedPreferences: {},
  };
},
async mounted() {
  await this.fetchPreferences();
},
methods: {
  async fetchPreferences() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/promo/emailpreferences', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.groupedPreferences = response.data;
    } catch (error) {
      console.error('E-posta tercihlerinin alınmasında hata oluştu:', error);
    }
  },
  async updatePreference(preferenceId, isSubscribed) {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `/promo/emailpreferences/${preferenceId}`,
        { isSubscribed },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      await this.fetchPreferences();
    } catch (error) {
      console.error('Tercih güncellenirken hata oluştu:', error);
    }
  },
  async toggleAll(isSubscribed) {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        '/promo/emailpreferences/toggleall',
        { isSubscribed: !!isSubscribed },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      await this.fetchPreferences();
    } catch (error) {
      console.error('Tüm tercihler değiştirilirken hata oluştu:', error);
    }
  },
},
};
</script>

  
  <style scoped>
    .outside { 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inside { 
    width: 100%;
    max-width: 1600px;
  }

  .header {
    background-color: #050505;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 1em;
    font-weight: 600; 
  }
  .accounticon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.6em;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .services {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .service-group {
    flex: 1 1 calc(49% - 2em);   
    background-color: #050505;
    padding: 15px;
    border-radius: 5px;
  }
  .service-name { 
    margin-bottom: 10px;
    font-size: 1em;
  }
  .preference-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .preference-item {
    margin-bottom: 10px;
  }
  .checkbox-label {
    display: flex;
    align-items: center; 
    font-size: 0.95em;
  }
  .checkbox-label input {
    margin-right: 10px;
  }
  .actions {
    margin-top: 20px;
    display: flex; 
    justify-content: flex-end;
  } 
  .message {
    padding: 10px;
    color: #953342;
    font-size: 0.9em;
  }
  @media (max-width: 768px) {
    .service-group {
      flex: 1 1 100%;  
    }
  }
  </style>
  