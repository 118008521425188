<template>
    <div class="level-list"> 
      <NoahLoader :isLoading="isLoading" /> 

      <template v-if="!isLoading">
        <p class="head">Hesap Seviyeleri</p>
        <p class="head-desc">Aşağıda, kullanıcı profilllerinde yer alan hesap seviye ikonlarının anlamlarını bulabilirsiniz:</p>
        <div 
          v-for="level in levels" 
          :key="level.id" 
          class="level-item"
        >
          <img 
            :src="getVerifiedIcon(level.seviye_no)" 
            :alt="`Seviye ${level.seviye_no}`" 
            class="level-icon" 
          />
          {{ level.details }}  
        </div>
      </template>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import NoahLoader from './NoahLoader.vue';
  
  export default {
    components: {
      NoahLoader
    },
    data() {
      return {
        levels: [],
        isLoading: true
      };
    },
    methods: {
      async fetchLevels() {
        try {
          const response = await axios.get('/auth/hesap-seviyeleri'); 
  
          if (!response.data || !Array.isArray(response.data.data)) {
            throw new Error("API'den beklenen formatta veri alınamadı.");
          }
  
          this.levels = response.data.data.sort((a, b) => a.id - b.id);
        } catch (error) {
          console.error('Hesap seviyeleri alınırken hata oluştu:', error);
        } finally {
          this.isLoading = false;
        }
      },
  
      getVerifiedIcon(level) { 
        if (!level || level === 0) {
          return ""; 
        }
  
        let iconPath = "";
        try {
          iconPath = require(`@/assets/icon/profile/level/${level}.svg`);
        } catch (e) {
          try {
            iconPath = require(`@/assets/icon/profile/level/${level}.png`);
          } catch (err) {
            console.warn(`Seviye ${level} için ikon bulunamadı.`);
          }
        }
        return iconPath;
      }
    },
  
    mounted() {
      this.fetchLevels();
    }
  };
  </script>
  
  <style scoped>
  .level-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 150px 20px;
    min-height: 50vh;
  }
  
  .level-item {
    background-color: #050505;
    flex: 1 1 calc(25% - 20px);
    max-width: 500px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; 
    padding: 7px;
    font-weight: 300;
    border-radius: 10px;   
  }
  
  .level-item img {
    opacity: 1;
  } 
  .level-icon {
    max-height: 3em;
    margin: 10px;
  }
  
  @media (max-width: 1024px) {
    .level-item {
      flex: 1 1 calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .level-item {
      flex: 1 1 calc(50% - 22px);
      box-shadow: 0 8px 18px rgba(2, 48, 50, 0.2);
      font-size: 1em;
    }
    .level-icon {
      max-height: 2em;
    }
  }
  
  @media (max-width: 359px) {
    .level-item {
      flex: 1 1 calc(100% - 20px);
    }
  }
  .head{
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
  }
  .head-desc{
    width: 100%;
    text-align: center; 
  }
  </style>
  