<template>

<div style="display: flex; justify-content: center;">
  <div class="question-archive"> 

    <div class="tag-filters">
      <button @click="resetFilter" :class="{ active: selectedTag === null }">
        #Hepsi
      </button>
      <button
        v-for="tag in uniqueTags"
        :key="tag.tag_id"
        @click="filterByTag(tag.tag_name)"
        :class="{ active: selectedTag === tag.tag_name }"
      >
        #{{ tag.tag_name }}
      </button>
    </div>
 
    <div class="filters">
      <input class="search"
        v-model="searchQuery"
        @input="searchQuestions"
        type="text"
        placeholder="Sorularda ara..."
      />
    </div> 
    <div v-if="filteredQuestions.length > 0">
      <div v-for="question in filteredQuestions" :key="question.question_id" class="question-card" @click="openQuestionDetails(question.question_id)">
        <p class="question-title"> 
          <img 
            v-if="question.is_important" 
            src="@/components/forum/icons/important.svg" 
            alt="Önemli" 
            class="important-icon"
          />
          {{ question.title }}
        </p>
         
        <p class="tag" v-if="question.tags.length > 0"> 
          <span v-for="tag in question.tags" :key="tag.tag_id" class="tag">
            #{{ tag.tag_name }}
          </span>
        </p>
     
        <div class="question-details"> 
          <p>Soran: {{ question.username || "Anonim" }}</p>
          <p>
            {{ formatDateTime(question.updated_at) }} 
          </p>
          <p>Görüntüleme: {{ question.view_count }}</p>
          <p>Toplam yanıt: {{ question.total_answers }}</p>
        </div>
      </div>
    </div>

    <div v-else>
      <p>Soru bulunamadı.</p>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import { eventBus } from '@/utils/eventBus';

export default {
  name: "QuestionArchive",
  data() {
    return {
      questions: [],
      selectedTag: null,
      searchQuery: ""
    };
  },
  computed: {
    filteredQuestions() {
      let result = this.questions;
      if (this.selectedTag) {
        result = result.filter(q => q.tags.some(tag => tag.tag_name === this.selectedTag));
      }
      if (this.searchQuery.trim()) {
        result = result.filter(q => q.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      return result;
    },
    uniqueTags() {
      const allTags = this.questions.flatMap(q => q.tags);
      const unique = [...new Map(allTags.map(tag => [tag.tag_id, tag])).values()];
      return unique;
    }
  },
  methods: {
    async fetchQuestions() {
      try {
        const response = await axios.get("/auth/user/questions/archive");
        this.questions = response.data.data || [];
      } catch (error) {
        console.error("Sorular alınırken hata oluştu:", error);
      }
    },
    openQuestionDetails(questionId) {
      eventBus.emit('openQuestion', questionId);
    },
    async searchQuestions() {
      if (!this.searchQuery.trim()) {
        this.fetchQuestions();
        return;
      }
      try {
        const response = await axios.get("/auth/user/questions/search", {
          params: { search: this.searchQuery },
        });
        this.questions = response.data.data;
      } catch (error) {
        console.error("Arama sırasında hata oluştu:", error);
      }
    },
    filterByTag(tag) {
      this.selectedTag = tag;
    },
    resetFilter() {
      this.selectedTag = null;
    }
  },
  mounted() {
    this.fetchQuestions();
  }
};
</script>

<style scoped>
.question-archive {
  display: flex;
  flex-direction: column;
  gap: 5px; 
  max-width: 1620px;
  width: 100%;
}

/* Tag Butonları */
.tag-filters {
  display: flex;
  gap: 0.1rem;
  margin-bottom: 0.1rem;
  flex-wrap: wrap;
}

.tag-filters button {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem; 
  border-radius: 0;
  border: 1px solid #022121;
  background-color: #022121; 
  cursor: pointer;
  transition: background 0.3s ease;
}

.tag-filters button:hover {
  background-color: #050505;
}

/* Seçili Olan Tag */
.tag-filters button.active {
  background-color: #050505; 
  color: white;
}

/* Soru Kartı */
.question-archive {
    display: flex;
    flex-direction: column; 
    gap: 5px; 
    padding: 0.2rem; 
  } 
  .question-card {
    background-color: #050505;  
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;   
    transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease;
  }
  .question-card:hover {
    background-color: black;  
    box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);  
    cursor: pointer;
  }
  .question-title{  
    font-size: 1rem; 
    font-weight: 500;
    margin: 0px;
    padding: 0;
  }
  .question-details{ 
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 5px;
    padding: 0;
  }
 
  .question-details p {  
    margin-top: 5px;
    margin-bottom: 0px;
    padding: 0;
  } 
  .tag {   
    font-size: 0.8rem;  
    margin: 0;
    color: #7c7c7c;
  }
 
.important-icon {
  width: 1rem; 
  height: 1rem;
}

@media (max-width: 768px) {
  .tag-filters button { 
    font-size: 0.6rem;  
  }
  .question-title{ 
    
    font-weight: 500; 
  }
  .question-details{ 
    font-size: 0.6rem; 
  }
  .tag {   
    font-size: 0.6rem;  
  }
}

</style>
