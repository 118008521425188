<template>
  <div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div>
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import MyAnswers from './MyAnswers.vue';
import MyQuestions from './MyQuestions.vue';
 
export default {
  data() {
    return {
      currentComponent: 'MyQuestions', 
      components: [ 
        { name: 'Sorularım', component: 'MyQuestions' },
        { name: 'Yanıtlarım', component: 'MyAnswers' }, 
      ],
    };
  },
  components: { 
    MyQuestions,
    MyAnswers
  },
};
</script>
  
<style scoped>
.button-container { 
display: flex; 
flex-wrap: wrap; 
align-items: center;
justify-content: center;
margin: 1em 0rem; 
} 

.button-container button { 
border-radius: 0.2em;
display: inline-block;
padding: 0.4rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: none;
background-color: #050505;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
} 
.button-container button.active {
  background: linear-gradient(45deg, #020202, #022121, #020202); 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  