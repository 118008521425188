<template>
  <div class="container">
    <div @click="openFeedbackModal" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/feed.svg" alt="Geri Bildirim Icon" />
      </div>
      <span>Geri Bildirim</span>
    </div>
    <router-link to="/anketler" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/anket.svg" alt="NOAHCHAT Icon" />
      </div>
      <span>Anket</span>
    </router-link>
  </div>
   
  <div v-if="showModal" class="modal-overlay" @click="handleOverlayClick">
    <div ref="modalContent" class="modal-content" :style="modalStyle" @click.stop>
      <div class="close-div">
        <button class="close-button" @click="closeModal">X</button>
      </div>
      <template v-if="!formSubmitted">
        <h2>Görüşleriniz bizim için değerli!</h2>
        <p>
          Bu form aracılığı ile bize geri bildirimde bulunabilirsiniz.<br />
          - Hatalar, Fikirleriniz, İyileştirme tavsiyeleriniz.
        </p>
        <p class="note">
          Lütfen dikkat! Bu form bilgisi "Destek ekibine iletilmemektedir. Geliştirme ekibine iletilmektedir".
        </p>
        <form @submit.prevent="submitFeedback">
          <input v-model="feedback.title" type="text" placeholder="Başlık" required /> <div class="emptyfive"></div>
          <textarea v-model="feedback.details" placeholder="Bildirim detayları" required></textarea>
          <button type="submit">Gönder</button>
        </form>
      </template>
      <template v-else>
        <div class="modal-success">
          <p class="success-message">Bildiriminiz bize ulaştı, bildiriminiz için teşekkürler!</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showModal: false, 
      modalStyle: {
        transform: 'translateY(100%)',
        transition: 'transform 0.3s ease-in-out'
      },
      feedback: {
        title: '',
        details: ''
      },
      formSubmitted: false,
    };
  },
  methods: {
    openFeedbackModal() {
      this.showModal = true;
      this.$nextTick(() => { 
        setTimeout(() => {
          this.modalStyle.transform = 'translateY(0)';
        }, 10);
      });
    },
    handleOverlayClick(e) { 
      if (e.target === e.currentTarget) {
        this.closeModal();
      }
    },
    closeModal() { 
      this.modalStyle.transform = 'translateY(100%)';
      setTimeout(() => {
        this.showModal = false;
        this.resetForm();
      }, 400); 
    },
    resetForm() {
      this.feedback = { title: '', details: '' };
      this.formSubmitted = false; 
      this.modalStyle.transform = 'translateY(100%)';
    },
    async submitFeedback() {
      const formData = {
        title: this.feedback.title,
        details: this.feedback.details,
        link: window.location.href
      };
      try {
        const url = localStorage.getItem('token') ? '/promo/feedback' : '/auth/feedback';
        await axios.post(url, formData);
        this.formSubmitted = true;
        setTimeout(() => {
          this.closeModal();
        }, 3000);
      } catch (error) {
        console.error('Feedback submission error:', error);
        alert('Bir hata oluştu, lütfen tekrar deneyin.');
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1006;
}

.modal-content {
  background-color: #0e0f0f;
  width: 100%;
  max-width: 500px;
  padding: 20px 20px 100px 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.2);
}

.modal-success {
  text-align: center;
  padding: 20px;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
}

p {
  margin-bottom: 1em;
}

.note {
  font-style: italic;
  color: #888;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #45a049;
}

.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 1rem;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 8em;
  border-radius: 8px 8px 0 0;
  text-decoration: none;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 0.9em;
  font-weight: 300;
  padding: 1em;
  background: linear-gradient(to bottom, #141414, #000000);
  cursor: pointer;
}

.box:hover {
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
  background: linear-gradient(to bottom, #061f1f, #000000);
}

.icon {
  min-width: 2em;
  max-height: 2em;
}

@media (max-width: 768px) {
  .container {
    padding-top: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    scale: 0.8;
  }
  .box {
    color: #b5b5b5;
    font-size: 1em;
    font-weight: 300;
  }
}
.success-message {
  margin-top: 20px;
  color: green;
  font-size: 1.2em;
  text-align: center;
}
.close-div {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background-color: #000000;
  padding: 0.8rem;
  min-width: 3rem;
  max-width: 3rem;
  border-radius: 2rem;
  transition: transform 0.3s ease;
  opacity: 0.8;
  border: 1px solid #444444;
}

.close-button:hover {
  opacity: 1;
  background-color: #000000;
  transform: scale(1.1);
}
</style>
