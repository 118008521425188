<template>
  <div class="user-tests">
    <ProfileCard /> 
    <div>
     <UserStatistics />
   </div>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; margin: 1rem;">
      <div class="head">  
        <img
          src="./icons/mytests.svg" 
          class="icon" 
        /> 
        <p>Testlerim</p>
      </div>
      <button class="create" @click="openCreateTestModal"><img src="./icons/plus.svg" class="icon" /> Yeni Test Başlat</button>
    </div> 
    <div style="text-align: center;" v-if="tests.length === 0">
      <p>Henüz bir testiniz yok.</p>
    </div>

    <div v-else class="test-list">
      <div 
        class="test-item" 
        v-for="test in tests" 
        :key="test.test_id"
      >
        <h4 class="test-title">{{ test.test_name }}</h4>
        <div class="test-info">
          <div class="test-row">
            <span class="label">Eğitimler:</span>
            <span class="value">{{ test.educations.map(e => e.name).join(', ') }}</span>
          </div>
          <div class="test-row">
            <span class="label">Zorluk:</span>
            <span class="value">{{ difficultyText(test.difficulty_filter) }}</span>
          </div>
          <div class="test-row">
            <span class="label">Süre:</span>
            <span class="value">{{ test.duration_minutes > 0 ? test.duration_minutes + ' dk' : 'Süresiz' }}</span>
          </div>
          <div class="test-row">
            <span class="label">Durum:</span>
            <span class="value">
              <button 
                v-if="getTestButtonLabel(test) === 'Başla' || getTestButtonLabel(test) === 'Devam Et'" 
                @click="onStartTestClick(test)">
                {{ getTestButtonLabel(test) }}
              </button>
              <span v-else>{{ getTestButtonLabel(test) }}</span>
            </span>
          </div>
          <div class="test-row">
            <span class="label">Skor:</span>
            <span class="value">
              <span v-if="test.attempt">
                <span v-if="test.attempt.status === 'completed'">
                  {{ test.attempt.score }} / 100
                  <button class="view-results" @click="viewTestResults(test)">Sonuçları Gör</button>
                </span>
                <span v-else-if="test.attempt.status === 'in_progress'">Devam ediyor</span>
                <span v-else-if="test.attempt.status === 'timeout'">Süre Doldu</span>
              </span>
              <span v-else>Henüz başlanmadı</span>
            </span>
          </div>
          
        </div>
      </div>
    </div>
   
          <div v-if="showResultsModal" class="modal-overlay">
            <div class="modal results-modal">
              <button class="close-button" @click="closeResultsModal">×</button>
              <h3>Test Sonuçları</h3>
              <p><strong>Toplam Soru:</strong> {{ testResults.length }}</p>
              <p><strong>Doğru Cevap:</strong> {{ correctAnswersCount }}</p>
              <p><strong>Hatalı Cevap:</strong> {{ testResults.length - correctAnswersCount }}</p>
              <p><strong>Puan:</strong> {{ score }}/100</p>
              
              <ul>
                <li v-for="result in testResults" :key="result.question_id">
                  <p>
                    <strong :style="{'color': result.selected_answer === result.correct_answer ? '#106e35' : '#9b0101'}">Soru:</strong> {{ result.question_text }}
                  </p>
                  <p><strong>Verilen Cevap:</strong> {{ result.selected_answer }}</p>
                  <p><strong>Doğru Cevap:</strong> {{ result.correct_answer }}</p>
                </li>
              </ul>
            </div>
          </div>
           
     <div v-if="showTestSolverModal" class="modal-overlay">
        <div class="modal solver-modal">
          <TestSolver 
            :testId="selectedTestId" 
            :attemptId="selectedAttemptId" 
            @close="closeTestSolverModal"
          />
        </div>
      </div>
 
    <CreateTest 
        ref="createTest" 
        :showCreateTestWizard="showCreateTestWizard" 
        :educations="educations"
        @close="closeCreateTestModal" 
        @testCreated="onTestCreated"
    />
  </div>
</template>

<script>
import axios from 'axios';
import TestSolver from './TestSolver.vue';
import CreateTest from './CreateTest.vue';
import UserStatistics from './UserStatistics.vue';
import ProfileCard from '../profile/ProfileCard.vue';

export default {
  components: { TestSolver, CreateTest, UserStatistics, ProfileCard },
  data() {
    return {
      tests: [],
      educations: [],
      testResults: [],
      showResultsModal: false,
      showTestSolverModal: false,
      selectedTestId: null,
      selectedAttemptId: null,
      showCreateTestWizard: false
    };
  },
  methods: {
    async fetchTests() {
      try {
        const response = await axios.get('/education/my-tests');
        this.tests = response.data.tests;
      } catch (error) {
        console.error('Testler alınırken hata:', error);
      }
    },
    async fetchEducations() {
      try {
        const response = await axios.get('/auth/educations');
        this.educations = response.data.educations;
      } catch (error) {
        console.error('Eğitimler alınırken hata:', error);
      }
    },
    difficultyText(level) {
      return ['Bilinmiyor', 'Kolay', 'Orta', 'Zor'][level] || 'Bilinmiyor';
    },
    getTestButtonLabel(test) {
      if (!test.attempt) {
        return "Başla";
      }

      const { status, start_time } = test.attempt;
      const duration = test.duration_minutes;

      if (status === 'completed') return "Tamamlandı";
      if (status === 'timeout') return "Süre Doldu";
      if (status === 'in_progress') {
        if (duration === 0) {
          return "Devam Et";
        } else {
          const now = new Date();
          const started = new Date(start_time);
          const diffMinutes = Math.floor((now - started) / (1000 * 60));
          if (diffMinutes < duration) {
            return "Devam Et";
          } else {
            return "Süre Doldu";
          }
        }
      }

      return "Başla";
    },
    async onStartTestClick(test) {
      try {
        const response = await axios.post(`/education/start-test/${test.test_id}`);
        this.selectedTestId = test.test_id;
        this.selectedAttemptId = response.data.attempt_id;
        this.showTestSolverModal = true;
      } catch (error) {
        console.error('Test başlatılırken hata:', error);
      }
    },
    async viewTestResults(test) {
      try {
        const response = await axios.get(`/education/my-test-results/${test.test_id}`);
        this.testResults = response.data.results;
        this.showResultsModal = true;
      } catch (error) {
        console.error('Sonuçlar alınırken hata:', error);
      }
    },
    closeResultsModal() {
      this.showResultsModal = false;
      this.testResults = [];
    },
    closeTestSolverModal() {
      this.showTestSolverModal = false;
      this.selectedTestId = null;
      this.selectedAttemptId = null;
      this.fetchTests();
    },
    openCreateTestModal() {
      this.$refs.createTest.resetForm();
      this.showCreateTestWizard = true;
    },
    closeCreateTestModal() {
      this.showCreateTestWizard = false;
    },
    onTestCreated() {
      this.fetchTests();
    }
  },
  computed: {
    correctAnswersCount() {
      return this.testResults.filter(result => result.selected_answer === result.correct_answer).length;
    },
    score() {
      return Math.round((this.correctAnswersCount / this.testResults.length) * 100);
    }
  },
  async mounted() {
    await this.fetchEducations();
    await this.fetchTests();
  }
};
</script>
  
  <style scoped>

.head {  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 12px;
    font-size: 1.5em;
    font-weight: 300;  }
  .user-tests {
    margin: 120px 0;
  } 
  .test-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center; 
  }
  .test-item {
    background: #050505;
    border: 1px solid #022121;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 360px;
  }
  .test-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .test-info .test-row {
    display: flex;
    margin-bottom: 10px;
  }
  .test-info .label {
    width: 100px;
    font-weight: 500;
  }
  .test-info .value {
    flex: 1;
  }
  .create {
    background-color: #106e35;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  .icon {
    max-width: 1rem; margin-right: 3px;
  }
  .create:hover {
    background-color: #0f522a;
  }
  .modal-overlay {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1005;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    background: #000000;
    padding: 20px;
    margin: 50px auto;
    width: 100%;
    max-width: 600px; 
    border: 1px solid #022121;
    border-radius: 10px;
  }
  .results-modal {
    overflow: auto;
    max-height: 80vh;
  }
 
  .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 32px; 
  cursor: pointer;
  color: #e6e6e6;
}

.close-button:hover {
  color: #ffffff;
}
.icon{max-height: 1.4em;}
  </style>
  