<template>
  <div class="quote-container">
    
    <div class="quote-wrapper"> 

      <div @click="fetchRandomQuote"  class="quote-card">

        <div class="quote-header">
          <h4>{{ quote.name }}</h4>
        </div>

        <p class="quote-content">{{ quote.content }}</p>

      </div>
 
      <button @click="fetchRandomQuote" class="refresh-button">
        <img src="../assets/icon/refresh.svg" alt="Yenile" />
      </button>

    </div>

  </div>
</template>

<style scoped>
.quote-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 0.5em; 
}

.quote-wrapper {
  display: flex;
  align-items: center; 
  max-width: 36rem;
} 
.quote-card {
  max-width: 36rem;
  min-width: 30rem;
  max-height: 10rem;
  padding: 1.5rem;
  background-color: #050505; 


  background: linear-gradient(25deg, #020202, #030b0b, #020202);

  
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  cursor: pointer;
}
.quote-card:hover { 
  background-color: #000; 
}

.quote-header h4 {
  font-size: 1.2em;
  font-weight: 500; 
  margin: 0;
}

.quote-content {
  font-size: 1em; 
  margin: 0;
}

.refresh-button {
  width: 120px;
  height: 120px;
  margin-left: -3rem; 
  background: none;
  border: none;
  cursor: pointer;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.refresh-button img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}
.refresh-button img:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .quote-container { 
    padding: 2em; 
  }
  .refresh-button { rotate: 90deg;   margin-left: 0; 
    margin-top: -2.5rem;    width: 6rem;
    height: 6rem; 
  }
  .quote-wrapper {
    flex-direction: column; 
  } 
  .quote-card {
     width: 100% - 1em; 
     min-width: 10rem;
  }
}
</style>


<script>
import axios from "axios";

export default {
  data() {
    return {
      quote: {
        name: "",
        content: "",
      },
    };
  },
  methods: {
    async fetchRandomQuote() {
      try {
        const response = await axios.get("/auth/random-quote-promo");
        this.quote = response.data.data;
      } catch (error) {
        console.error("Rastgele alıntı alınırken hata oluştu:", error);
      }
    },
  },
  mounted() {
    this.fetchRandomQuote();
  },
};
</script> 