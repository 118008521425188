<template>
  <div class="container">
    <div class="survey-header">
      <p class="title">Görüşleriniz bizim için değerli!</p>
      <p>
        Bu bölümde, bazıları herkese açık, bazıları ise yalnızca kayıtlı kullanıcılarımıza özel olan çeşitli anketleri bulacaksınız.  <br>  
        Bu anketler, hizmetlerimizi geliştirme, platform güncellemeleri ve yeni teklifler konusunda bize rehberlik etmede önemli bir rol oynar. <br>   
      </p>
    </div>
    <div v-if="surveys.length">
      <ul>
        <li v-for="survey in surveys" :key="survey.survey_id">
          <h3 style=" font-size: 1.3em; font-weight: 500; margin-bottom: 0;">{{ survey.title }}</h3>
          <p class="duration">Süre: {{ survey.duration }}dk</p> 
          <p>{{ survey.description }}</p>
          <p v-if="survey.amount != null && survey.currency_name != null">
           <b>Ödül:</b> {{ parseFloat(survey.amount).toFixed(2) }} {{ survey.currency_name }}
         </p> 
          <button class="take-survey-button confirm" v-if="!isSurveyCompleted(survey.survey_id) && (survey.survey_type === 'public' || isLoggedIn)" @click="openSurveyModal(survey.survey_id)">Ankete Katıl</button>
  
          <p style="color: #127750;" v-else-if="isSurveyCompleted(survey.survey_id)"><img style="max-width: 1em;" :src="require(`@/assets/discover/joined.svg`)"/> <br>Bu ankete zaten katıldınız.</p> 
          <p style="color: #187774;" v-else>Bu anket platform kullanıcılarına özeldir. Katılmak için lütfen giriş yapın.</p>
 
          <button class="view-button" v-if="survey.result_type === 'public'" @click="openResultsModal(survey.survey_id)">- Sonuçları Gör -</button>

        </li>
      </ul>
    </div>
    <div v-else>
      <p style="text-align: center;">Mevcut anket bulunmamaktadır.</p>
    </div>
    <SurveyModal v-if="showModal" :surveyId="selectedSurveyId" @close="closeModal" />
    <SurveyResultsModal v-if="showResultsModal" :surveyId="selectedSurveyIdForResults" @close="closeResultsModal" />
  </div>
</template>


<script>
import axios from 'axios';
import SurveyModal from './SurveyModal.vue';
import SurveyResultsModal from './SurveyResultsModal.vue';

export default {
  components: {
    SurveyModal,
    SurveyResultsModal
  },
  data() {
    return {
      surveys: [],
      showModal: false,
      selectedSurveyId: null,
      isLoggedIn: false,
      showResultsModal: false,
      selectedSurveyIdForResults: null
    };
  },
  async mounted() {
    await this.checkLoginStatus();
    await this.fetchSurveys();
  },
  methods: {
    checkLoginStatus() {
      this.isLoggedIn = !!localStorage.getItem('token');
    },
    async fetchSurveys() {
      try {
        const response = await axios.get('/auth/surveys/active-surveys');
        this.surveys = response.data; 
      } catch (error) {
        console.error('Anketler alınırken hata oluştu:', error);
      }
    },
    isSurveyCompleted(surveyId) {
      return !!localStorage.getItem(`surveyCompleted_${surveyId}`);
    },
    openSurveyModal(surveyId) {
      this.selectedSurveyId = surveyId;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedSurveyId = null;
    },
    openResultsModal(surveyId) {
      this.selectedSurveyIdForResults = surveyId;
      this.showResultsModal = true;
    },
    closeResultsModal() {
      this.showResultsModal = false;
      this.selectedSurveyIdForResults = null;
    }
  }
};
</script>


<style scoped>
.container { 
  padding-top: 6rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.survey-header { 
  padding: 2em;
  text-align: center;
}  

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 1em; 
  justify-content: center;
  flex-wrap: wrap;
}

li { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  background-color: #050505; 
  padding: 1em;
  border-radius: 1em; 
  max-width: 400px;
  width: 100%;
} 

li:hover { 
  background-color: #000; 
}
.duration {
  background-color: #070e11; 
  padding: 0.4em 2em;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
}

.take-survey-button {  
  border: 1px solid #1a262b; 
  padding: 0.5em 2em;
  border-radius: 2em;
  margin-right: 0.5em;
  cursor: pointer; 
} 
 

.view-button {
  background: none; 
  border: none; 
  margin-top: 1em;
  cursor: pointer; 
}
h3 {
  font-size: 1.5em;
  font-weight: 500;
  color: #187774;
}
 
</style>
