<template>
    <div class="photo-editor">
      <img  v-no-right-click ref="photo" :src="props.image" alt="Düzenlenecek Fotoğraf" />
    </div>
  </template>
  
  <script setup>
  /* global defineProps */
  import { ref, onMounted, watch } from 'vue'
  import interact from 'interactjs'
  
  const props = defineProps({
    image: {
      type: String,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  })
  
  const photo = ref(null)
  
  onMounted(() => {
    // Başlangıç değerlerini dataset'te saklıyoruz
    photo.value.dataset.x = 0
    photo.value.dataset.y = 0
    photo.value.dataset.scale = 1
    photo.value.dataset.rotation = 0
  
    let startScale = 1
    let startRotation = 0
    let initialGestureAngle = 0
  
    const interactable = interact(photo.value)
      .draggable({
        enabled: !props.locked,
        listeners: {
          move(event) {
            const x = parseFloat(photo.value.dataset.x) + event.dx
            const y = parseFloat(photo.value.dataset.y) + event.dy
            photo.value.dataset.x = x
            photo.value.dataset.y = y
            updateTransform()
          }
        }
      })
      .gesturable({
        enabled: !props.locked,
        listeners: {
          start(event) {
            startScale = parseFloat(photo.value.dataset.scale)
            startRotation = parseFloat(photo.value.dataset.rotation)
            initialGestureAngle = event.angle
          },
          move(event) {
            const newScale = startScale * event.scale
            const newRotation = startRotation + (event.angle - initialGestureAngle)
            photo.value.dataset.scale = newScale
            photo.value.dataset.rotation = newRotation
            updateTransform()
          }
        }
      })
  
    function updateTransform() {
      const x = photo.value.dataset.x
      const y = photo.value.dataset.y
      const scale = photo.value.dataset.scale
      const rotation = photo.value.dataset.rotation
      photo.value.style.transform = `translate(${x}px, ${y}px) scale(${scale}) rotate(${rotation}deg)`
    }
   
    watch(() => props.locked, (newVal) => {
      interactable.draggable({ enabled: !newVal })
      interactable.gesturable({ enabled: !newVal })
    })
  })
  </script>
  
  <style scoped>
  .photo-editor {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .photo-editor img {
    max-width: 100%;
    display: block;
    touch-action: none;
    user-select: none;
  }
  </style>
  