<template>
  <div class="photo-capture">
    <img class="noah-logo" src="./icons/logo.svg">
    <img class="top-icon" src="./icons/kasicon.svg">  

    <div class="button-container">
      <button @click="triggerInput(false)">
        <img class="btn-icon" src="./icons/gallery.svg">
      </button>
      <button @click="triggerInput(true)">
        <img class="btn-icon" src="./icons/camera.svg">
      </button>
    </div> 

    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      :capture="useCamera ? 'environment' : null"
      style="display: none;"
      @change="handleFileChange"
    />
  </div>
</template>

<script setup>
/* global defineEmits */
import { ref, nextTick } from 'vue'

const emit = defineEmits(['photo-captured'])

const fileInput = ref(null)
const useCamera = ref(false)
 
const triggerInput = (cameraMode) => {
  useCamera.value = cameraMode
  nextTick(() => {
    fileInput.value?.click()
  })
}
 
const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = (e) => {
      emit('photo-captured', e.target.result)
    }
    reader.readAsDataURL(file)
  }
  event.target.value = ''
}
</script>


<style scoped>
.photo-capture {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  gap: 10px;
  min-height: 70vh;
}

.button-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2rem;
}
.photo-capture button {
  padding: 8px 16px;
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  border: none;
  background: linear-gradient(45deg, #020202, #0a0a0a, #020202); 
}
.top-icon {
  max-width: 5rem;
} 
.noah-logo {
  max-width: 10rem;
  margin-bottom: 2rem;
}
</style>
