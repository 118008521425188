<template>
  <div class="container">
    <router-link to="/" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/home.svg" alt="Ana Sayfa Icon" />
      </div>
    </router-link>

    <router-link to="/portal" class="box">
      <div class="icon">
        <img class="icon" src="@/assets/icon/navigation/mobiluygulamalar.svg" alt="Portal Icon" />
      </div>
    </router-link>

    <div class="ai-logo">NOAH<span style="font-weight: 400; animation: colorChangeTwo 5s ease infinite;">AURA</span> </div>

    <img class="icon-question" src="@/assets/icon/ai-question.svg" alt="Sorularım" @click="openModal" />
 
    <div v-if="showModal" class="modal-overlay" @click="handleOverlayClick">
      <div ref="modalContent" class="modal-content" :style="modalStyle" @click.stop>
        <div class="close-div">
          <button class="close-button" @click="closeModal">X</button>
        </div> 
        <NoahAuraInfo />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import NoahAuraInfo from "./NoahAuraInfo.vue"; 

export default {
  components: {
    NoahAuraInfo,
  },
  setup() {
    const showModal = ref(false);
    const modalStyle = ref({
      transform: "translateY(100%)",
      transition: "transform 0.3s ease-in-out",
    });

    const openModal = () => {
      showModal.value = true;
      nextTick(() => {
        setTimeout(() => {
          modalStyle.value.transform = "translateY(0)";
        }, 10);
      });
    };

    const closeModal = () => {
      modalStyle.value.transform = "translateY(100%)";
      setTimeout(() => {
        showModal.value = false;
      }, 400);
    };

    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };

    return {
      showModal,
      modalStyle,
      openModal,
      closeModal,
      handleOverlayClick,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 4em;
  min-height: 4em;
  border-radius: 8px;
  text-decoration: none;
  background-color: #050505;
  transition: transform 0.2s;
  color: #b5b5b5;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.box:hover {
  transform: scale(1.05);
  color: white;
  box-shadow: 0 5px 12px rgba(0, 104, 42, 0.2);
}

.icon {
  min-width: 2em;
  max-height: 1.5em;
  opacity: 0.8;
}

.box:hover .icon {
  opacity: 1;
}

.ai-logo {
  font-size: 1.2rem;
  font-weight: 200;
}

.icon-question {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  margin-left: 0.6em;
}

.icon-question:hover {
  transform: scale(1.2);
}

/* Modal yapısı */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;
}

.modal-content {
  margin-top: 12rem;
  width: 100%;
  max-width: 1080px;
  background: linear-gradient(45deg, #020202, #080808, #030b0b);
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #022121;
  border-left: 1px solid #022121;
  border-right: 1px solid #022121;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  overflow-y: auto;
  max-height: 80vh;
}

.close-div {
  position: sticky;
  border-radius: 15px 15px 0 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  padding: 0.5rem;
}

.close-button {
  background-color: #000000;
  color: #ffffff;
  padding: 0.8rem;
  min-width: 3rem;
  max-width: 3rem;
  border-radius: 2rem;
  opacity: 0.8;
  border: 1px solid #444444;
  cursor: pointer;
  transition: transform 0.3s ease;
}
 
.close-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

h2 {
  margin-bottom: 10px;
  text-align: center;
}

.modal-info {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.info-column {
  width: 45%;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

.info-link {
  display: block;
  margin-top: 10px;
  color: #187774;
  text-decoration: none;
}

.info-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .box {
    min-width: 4em;
    min-height: 3em;
  }
  .ai-logo {
    font-size: 1.4rem;
    font-weight: 200;
  }
  .modal-content {
    max-width: 90%;
  }
}
</style>
