<template>
  <div>
    <NoahMasterLevels />
    <div class="buttoncontainer">
      <div class="join-now-button" @click="redirectToWhatsApp">
        <img src="@/assets/icon/whatsapp.svg" alt="WhatsApp" class="whatsapp-icon" />
        <span>Şimdi Katıl</span>
      </div>
    </div>

  </div>
</template>

<script>
import NoahMasterLevels from './NoahMasterLevelsforEdu.vue'; 

export default {
  components: {
    NoahMasterLevels,
  },
  methods: {
    redirectToWhatsApp() {
      window.open('https://wa.me/905304118237', '_blank');
    },
  },
};
</script>

<style scoped>
.buttoncontainer{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}
.join-now-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
  padding: 10px 20px;  
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #282828;
  max-width: 10em;
}

.join-now-button:hover {
  scale: 1.1;
  
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
</style>
